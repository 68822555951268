import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';

i18n
  .use(LngDetector)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          common: {
            accept: 'Accept',
            save: 'Save',
            yes: 'yes',
            no: 'no',
            or: 'or',
            currency: 'EUR',
            buy: 'BUY',
            and: 'and',
            content: 'content',
            next: 'next',
            close: 'Close',
            join: 'join',
            pickDay: 'Pick day',
            send: 'Send',
            search: 'Search...',
            copied: 'Copied',
            signOut: 'Sign out',
            showMore: 'Show more',
            tryItNow: 'Try it now',
            tryItForFree: 'Try it for free',
            createPresentation: 'Create presentation now',
            processing: 'processing...',
            showPsd: "Show presentations without PSD",
            showNotPsd: "Show presentations with PSD",
            evryplaceVirtualTour: "Evryplace Virtual Tour",
          },
          cookieMsg: 'This site uses cookies to store information on your computer. We use cookies to provide the highest level of service, including tailor-made solutions. Using the site without changing cookies settings means that they will be set in your end device. You can change the settings for cookies anytime. More details in our Privacy Policy.',
          navBar: {
            product: 'Product',
            howToStart: 'How to start',
            benefits: 'Benefits',
            functions: 'Functions',
            business: 'Business',
            blog: 'Blog',
            pricing: 'Pricing',
            explore: 'Explore',
            dashboard: 'My Dashboard',
            register: ' Register',
            login: 'Log in',
            editPresentation: 'Edit presentation',
            profile: 'Settings',
            payments: 'Billing',
            realEstate: 'Real Estate',
            thetaLink: "Theta",
            changePSD: 'Change PSD',
            hospitalityAndTourism: 'Hospitaliy and\xa0Tourism',
            myPresentations: 'My Presentations',
            startCoviewing: 'Start Co-viewing',
            resendInvite: 'Re-send Invite',
            trainings: 'Trainings',
          },
          registration: {
            header: 'Registration',
            socialLabel: 'Register with:',
            registerBtn: 'Create free account',
            termsNotification: 'By\xa0creating the\xa0account, you\xa0agree to\xa0our ',
            termsLink: 'Terms\xa0of\xa0Service',
            privacyLink: 'Privacy\xa0Policy',
            emailPH: 'E-mail address',
            passwordPH: 'Password',
            passwordConfirmPH: 'Confirm password',
            displayNamePH: 'User name',
            successMsg: 'Registration successful. We\'ve sent an account activation link to the e-mail address you\'ve provided.',
            notification: 'Activation email has been sent. Please check your mailbox.',
            google: 'Sign up with Google',
            linkedin: 'Sign up with LinkedIn',
            facebook: 'Sign up with Facebook',
            emailAlreadyRegistered: 'Email already registered.',
          },
          login: {
            header: 'Log in',
            orLabel: 'Or Log in with:',
            withLabel: 'Log in with',
            emailLabel: 'e-mail adress',
            loginBtn: 'Log in',
            emailPH: 'E-mail address',
            passwordPH: 'Password',
            successMsg: 'Signed in!',
            cannotLogin: 'Can\'t log in?',
            google: 'Sign in with Google',
            linkedin: 'Sign in with LinkedIn',
            facebook: 'Sign in with Facebook',
            createAccount: 'Do you want to create an account?',
            registration: 'Registration',
          },
          resetPassword: {
            header: 'Reset password',
            subheader: 'Enter\xa0your email\xa0address and\xa0we\xa0will send\xa0you the\xa0needful instructions.',
            emailPH: 'E-mail address',
            sendBtn: 'SEND',
            successMsg: 'If the provided e-mail address exists, the message has been sent.'
          },
          setPassword: {
            header: 'Enter new password',
            password: 'Password',
            passwordConfirmation: 'Confirm password',
            send: 'SEND',
            successMsg: 'Password changed.'
          },
          footer: {
            //evryplace
            evryplace: 'EVRYPLACE',
            trainings: 'Trainings',
            product: 'Product',
            functions: 'Functions',
            pricing: 'Pricing',
            realEstate: 'Real estate',
            tourism: 'Tourism & Hospitality',
            signIn: 'Sign In',
            copy: '© 2018 - EVRYPLACE',
            terms: 'Terms of Service',
            privacy: 'Privacy Policy',
            //need help?
            needHelp: 'Need Help?',
            helpCenter: 'Help Center',
            faq: 'FAQ',
            knowledgeBase: 'Knowledge base',
            support: 'Support',
            //more
            more: 'More',
            explore: 'Explore',
            portfolio: 'Portfolio',
            blog: 'EVRYPLACE Blog',
            newsletter: 'Newsletter',
            facebook: 'Facebook',
            linkedIn: 'LinkedIn',
            gototop: 'Back to top',
            title1: 'Virtual tour software',
            title2: 'Creating virtual tour - Benefits',
            title3: 'Virtual tours - Functions',
            title4: 'Virtual tour - Interactive presentation',
            description1: 'What is Evryplace? It is an intuitive software dedicated to creating and organising virtual tours. It gives you the opportunity to create a terrific virtual tour from scratch and edit it whenever you want to.',
            description2: 'Evryplace is a tool which you can access with every search engine (Chrome, Firefox, Opera, Edge and more) wherever and whenever you wish to without the necessity of downloading the programme. All the virtual tours created are stored safely on your account, which means that you can log in from any computer and work on your presentation without worrying which device you used beforehand.',
            description3: 'Thanks to our virtual tour editor Evryplace, you can link 360° photos and videos together and enrich it by adding an informative content layer of your liking in the form of photos, videos, audio and text.  All this is achievable with just a few clicks.',
            description4: 'Creating virtual tours means many benefits for you and your client. It is an innovative form of property presentation, which allows you to truly discover every detail of the presented space and feel the atmosphere of the place without getting up from your couch.',
            description5: 'By using our software, you can show yourself from the most professional side. Moreover, we present you with the possibility of saving money and time as you can put up a whole presentation only by yourself and with a great result. You don’t have to rely on fortune consuming services of others anymore. Start today and create presentations in an easy, user-friendly way.',
            description6: 'Virtual presentations enable you to create scenes composed of 360° photos and videos. Furthermore, they allow you to create links between your content in order to create a virtual tour. You can add quickly and easily an informative content layer to your presentations in the form of videos, photos, sound, and text.',
            description7: 'You can upload your presentation and post it on the Internet or send a link to your work which would be accessible on every device from computers through tablets to smartphones. Everyplace has another astonishingly useful function which is co-viewing (an online, remote meeting) by use of which you can walk your client through the presentation or let him explore the presented place by himself.',
            description8: 'In Evryplace you can quickly and easily create any virtual tour which would be tailored to your needs. Evryplace editor was made in a way that is intuitive from the first time you try it. Despite its simplicity and intuitiveness, it incorporates every functionality you might need to impress recipients of your interactive presentation. Present rooms, places, tours, events, training courses in VR using goggles – Virtual Reality has never been so easily accessible.',
          },
          home: {
            accountDeleteInfo: "Evryplace account deleted successfully",
            headerTitle: "Every\xa0moment. Every\xa0place.",
            headerSubtitle: "The easiest 360° VR presentations editor for presenting real estate space and performing live remote tours",
            tagline: "*registration in\xa03\xa0minutes, no credit\xa0card required",
            videoTitle: "Check out what EVRYPLACE can do!",
            videoDescription: "Let us show you an intuitive tool that makes you stand out in the real estate market in a quick and easy way. Find out how much you can benefit from using our editor. Build virtual walks, present interiors, get time and arrange online meetings.",
            videoUrl: 'https://www.youtube.com/embed/kjLAPqrQc8s?rel=0&amp;controls=0&amp;showinfo=0',
            carouselItemsOne: "Przemysław Latus,\xa0RICOH THETA Sales\xa0Manager RICOH Polska",
            carouselContentOne: "Evryplace uses the potential of 360° technology in a very interesting way. Thanks to this platform and without the need for high costs, the user can prepare a professional presentation created from photospheres. Considering the growing interest in 360° cameras, Evryplace has a chance to grow quickly and reach a wide range of users.",
            carouselItemsTwo: "SIEMACHA Spot Magnolia Park",
            carouselContentTwo: "We are happy with the cooperation with EVRYPLACE. A virtual walk, like a teleport, allows people to move to the other side of the screen, to the Siemacha's reality. With one click, we can look into our workspaces, and the description of classes reflects their climate. This is a great and fast way to promote places like Siemacha and encourage potential students to visit us in person.",
            carouselItemsThree: "Michał Ostromęcki, MISHA PHOTO",
            carouselContentThree: "I've been using EVRYPLACE since 2016. Things which made them stand out from the competition, were high-end features like full support for stereoscopic imagery or straightforward multi-platform delivery by a smart link to a client. You get addicted to how fast one can scramble 3D-virtual tour, all drag&drop with easy customization. Evrplace has grown massively over a last year, it's flawless mature product which I don't hesitate to recommend to my clients.",
            virtualTourTitle: "We'll\xa0design a\xa0virtual\xa0walk for\xa0you!",
            virtualTourText: "We\xa0will help you\xa0find a\xa0photographer, get\xa0the\xa0right pictures or\xa0arrange a\xa0photo\xa0session. Or we will make VR presentation according to your expectations.",
            virtualTourBtn: "CONTACT US",
            createAccountBtn: "Try it now",
            usedBy: "Used\xa0by:",
            productHeading: "All in One 360° solution",
            editorHeading: "Edit in 360°",
            playerHeading: "Publish online",
            coviewHeading: "Co-view live",
            brandingHeading: "Add branding",
            editor: "Simple & intuitive",
            player: "Watch it anywhere",
            coview: "Run live tours",
            branding: "Market better",
            editorParagraph: "EVRYPLACE web editor is easy to use. Upload your 360° images using your mobile phone and automatically turn them into a virtual tour or a 3D presentation. ‘Drag & drop’ image, text or video to enhance your presentation.",
            playerParagraph: "Allow your clients to watch your tours from anyplace in the world, and on any modern device. Your presentations can be viewed via a web link, mobile device or any mobile VR headset.",
            coviewParagraph: " Live property show rounds are now possible online. Show your clients multiple properties in the area with in one online call. Resolve their concerns and give them valuable insight before their visit the area.",
            brandingParagraph: " Add investment or brand imagery to your 360° presentations. Use it to promote your properties online or during tradeshows and make your brand stand out. Integrate 360° tours into your MLS campaigns.",
            benefits: "Benefits",
            benefitsSubhead: "A flexible solution that adapts to your workflow.",
            benefitsBox1heading: "Can you catch up with your sales? ",
            benefitsBox1description: "Your clients now have the chance to review multiple properties online, before moving an inch. Help them save their time and money by showing them the better option to choose from.",
            benefitsBox2heading: "Win client’s trust.",
            benefitsBox2description: "Your clients don’t need another  slideshow session. Give them a full picture. Allow them to look around, “walk the space” and understand the space better. As if being there themselves.",
            benefitsBox3heading: "All locations at your client’s reach.",
            benefitsBox3description: "Transport your clients to a remote location. Show them round distant locations regardless of where they are. Save their time and money, and allow your sales teams to reach larger group of clients.",
            ctaHeading: "Implement an innovative sale of Real Estate that your clients expect",
            functions: "Functions",
            featuresItem1Heading: "360° Images to tours",
            featuresItem1Content: "Upload 360° images using your mobile device and turn them into a ready to tour 360° presentation in minutes.",
            featuresItem2Heading: "Informative layer",
            featuresItem2Content: "Add interactive widgets to your presentations. Highlight details and create more descriptive tours by using banners, links and graphics.",
            featuresItem3Heading: "Branding",
            featuresItem3Content: "Customize the 360° presentations to match the style of your brand. Add your logo or brand imagery and build a branded portfolio.",
            featuresItem4Heading: "Co-Viewing",
            featuresItem4Content: "Live share your presentation and consult it with your team and allow your clients to book a viewing with you.",
            featuresItem5Heading: "Auto presentation",
            featuresItem5Content: "Allow your clients to “play out” a 360° presentation, with scenes changing one after another.",
            featuresItem6Heading: "MLS Integration",
            featuresItem6Content: "Simple and intuitive API allows you to add the 360° tours to your existing sales channels.",
            howCanHelpHeading: "How these features help your…",
            howCanHelpBox1Title: "Workflow?",
            howCanHelpBox2Title: "Company?",
            howCanHelpBox3Title: "Clients?",
            howCanHelpBox1ListItem1: "Add a simple solution that allows you to reinvent the way you pitch to your clients.",
            howCanHelpBox1ListItem2: "Make quick changes to presentations, without depending on external teams.",
            howCanHelpBox1ListItem3: "Share your projects with your team, and allow for early consultation to get the presentation right on early stages.",
            howCanHelpBox1ListItem4: "Make the most of all your promotional materials in - add 2D photos, and information to the presentation.",
            howCanHelpBox2ListItem1: "Connect your Sales Team as a dedicated contact to the prepared presentations.",
            howCanHelpBox2ListItem2: "Meet market trends by offering clients a modern way of showing round properties.",
            howCanHelpBox2ListItem3: "Present your company as a market leader using innovative solutions.",
            howCanHelpBox2ListItem4: "Eliminate location restrictions - present, show or train online, as if being there yourself.",
            howCanHelpBox3ListItem1: "Thanks to EVRYPLACE, your customers have the freedom to enjoy your presentation, on any device, anytime, anywhere.",
            howCanHelpBox3ListItem2: "Create shareable 360° presentations and let your customers rewatch them multiple times and share it with their friends.",
            howCanHelpBox3ListItem3: "Offer your customers the opportunity to contact the sales department freely or book remote co-viewings directly from the presentation.",
            howCanHelp30days: "Try EVRYPLACE now for 30 days for free.",
            howToStart: "How to start",
            howToStartBox1Text1: "1. Create an Evryplace account",
            howToStartBox1Text2: "Register",
            howToStartBox1Text3: " and use Evryplace with all its functionalities completely for free for a full month. After the free trial period, ",
            howToStartBox1Text4: "pick a subscription plan",
            howToStartBox1Text5: " or use the ",
            howToStartBox1Text6: "Evryplace Free",
            howToStartBox1Text7: " without any charges",
            howToStartBox2Text1: "2. Upload the 360° photographs",
            howToStartBox2Text2: "Take them yourself with a 360° camera or with your smartphone. Alternatively, you can hire a professional photograph or use a design studio for space visualisations. Upload the 360° photographs to Evryplace and create scenes for your tour.",
            howToStartBox3Text1: "3. Edit and share your virtual tour",
            howToStartBox3Text2: "Add text, graphics, video and audio files as well as www links to your presentation. Share your tour by sending a link to it, publishing it on your website or by sharing the QR code.",
            howToStartInfoText1: "We recommend ",
            howToStartInfoText2: "Evryplace Knowledgebase",
            howToStartInfoText3: ", where you will find full information on how to create virtual tours and use all the functionalities of the editor.",
            testimoialsHead: "Testimonials",
            testimonialsSubHead: "What our clients say:",
            mediaHeader: "Available on all modern devices.",
            mediaSubhead1: "Try out EVRYPLACE applications",
            mediaSubhead2: "Download Android or iOS app to view presentations in VR",
            sloganHeaderPRE: "Grow your ",
            slogan1: "business",
            slogan2: "sales",
            slogan3: "brand",
            slogan4: "marketing",
            sloganHeaderPOST: " with EVRYPLACE.",
            metaDescription: 'Evryplace - Showcase every place in virtual reality. Create interactive VR presentatnions online and share them with your clients. In minutes.',
            metaKeywords: 'Virtual tour, virtual tours, virtual tour creator, vr, interactive tour',
            metaTitle: 'Virtual Tour - Create interactive presentations - Evryplace'
          },
          pricing: {
            benefits: {
              text1: 'Creating and publishing 360° presentations with an intuitive online editor',
              text2: 'Importing photos directly from Ricoh Theta cameras using the Evryplace mobile app',
              text3: 'Viewing your presentations on the internet browser and mobile applications (iOS, Android)',
              text4: 'Sharing your presentations directly via link or email and publishing them on Evryplace Explore',
              text5: 'Putting your presentations on your own website (without the Evryplace logo visible)',
              text6: 'Security of presentations you share with a password',
              text7: 'Publishing on mobile VR',
              text8: 'Access to the Evryplace Knowledgebase articles',
              monthly: 'Monthly',
              annual: 'Annualy',
              evryPricing: 'Evryplace pricing',
              evryBenefits: 'With Evryplace you will get:'
            },
            businessOffer: {
              text1: 'Are you interested in something more?',
              text2: 'Do you want to integrate a 360° solution into current work organization in your company? Manage your private cloud with presentations, dedicated training and priority support. Contact us to discuss an individual price for you.',
              text3: 'Subscriptions for large companies and corporations start from',
              text4: 'Contact us to discuss an individual price for you.',
              text5: 'Subscriptions for large companies and corporations start from ',
              text6: '625',
              text7: ' net / month',
              text7_1: ' net / year',
              text8: 'Unlimited',
              text9: ' published presentations',
              text10: 'Number of users ',
              text11: 'for individual negotiations',
              text12: 'Number of users for individual negotiation',
              text13: '8k',
              text14: ' - maximum resolution of the 360° photos displayed by the Evryplace Player ',
              text15: '50 MB',
              text16: ' - maximum size of a 360° photo or rendering ',
              text17: 'Viewing the presentations with a VR headset ',
              text17_1: '(requires Oculus GO, Quest, or other headset supporting WebXR)',
              text18: 'Coviewing',
              text18_1: ' - remote presentations',
              text19: 'Automatic photo rectidfication ',
              text19_1: 'from Ricoh Theta cameras',
              text20: 'Possibility to add video and 360° video to the presentation',
              text21: '200 MB',
              text21_1: ' - maximum video size',
              text22: 'Teams',
              text22_1: ' - managing users permissions in your organization',
              text23: 'Branding',
              text23_1: ' - personalized logo for each presentation, branding elements for presentations and email invitations',
              text24: 'Dedicated trainings and support',
              text25: 'Dedicated SLA',
              text26: 'Possibility to deploy a private cloiud soulition ',
              text26_1: ' and integrate with clients systems',
              text27: 'Contact us',
              text28: 'We will adjust the offer to the needs of your company.',
              text30: 'Password protected',
              text30_1: ' presentations'
            },
            offers: {
              currency: ' net / month',
              currency_2: ' net / year',
              noLimit: 'Unlimited ',
              publishedPresentation: ' published presentations ',
              user: ' user ',
              users: ' users ',
              virtualStorage: ' of cloud space for your media files and presentations ',
              sixK: '6k ',
              maxDimension: ' - maximum resolution of the 360° photos displayed by the Evryplace Player ',
              maxFileSize: ' - maximum size of a 360° photo or rendering',
              vrPresentation: 'Viewing a presentation with a VR headset ',
              vrPresentation2: ' (requires Oculus GO, Quest, or other WebXR headset)',
              coviewing: 'Coviewing',
              coviewing2: ' - remote presentations',
              autoResize: 'Automatic horizon leveling ',
              autoResize2: ' from Ricoh Theta cameras',
              videoAllowed: ' from Ricoh Theta cameras',
              videoSize: '200 MB',
              videoSize2: ' - max video size',
              tryForFree: 'Try for free',
              allowVideoUpload: '360° and flat videos in presentations',
              imageCorrector: 'Image correction',
              noEvryLogo: 'Debranded presentations (no Evryplace logo)',
              video360: 'Video 360° upload',
              protectedPresentation2: 'Password protected',
              protectedPresentation3: ' presentations',
              currencyDropdown: 'Currency',
              advancedWidgetsEnabled: 'Advanced information layer',
              advancedWidgetsEnabled2: ' in presentations - Gallery widget'
            },
            currencyInfo: 'You can browse all available currencies here, but your payment currency will be based on your tax residence',
            selectPaymentType: 'Select payment type',
            heading: 'Simple and transparent pricing',
            subheading: 'Plans fit for teams and businesses on every stage of their 360° project',
            monthly: 'Monthly  illing',
            annual: 'Annual billing',
            perMonth: 'per<br>month',
            arrow: 'Save up to 20%',
            changePlan: 'Go to payments',
            askForPlan: 'Contact sales',
            currentPlan: 'Current Plan',
            featuresHeader: 'EVRYPLACE features:',
            column1: {
              heading: 'Basic',
              button: 'Start free trial',
              description: 'For professionals looking for a quick and easy way to build and present their 360° portfolio or kick off their VR/360° business.',
              destiny: '1 user<br>1 GB Cloud Storage',
              feature1: 'create and publish 360° presentations with an intuitive online presentation editor',
              feature2: 'view or co-view presentation in the web browser or in EVRYPLACE mobile apps (iOS, Android)',
              feature3: 'share presentations by email',
              feature4: 'protect shared presentation with an access code',
              feature5: 'perform live co-viewing sessions with your clients',
              feature6: 'access to EVRYPLACE knowledge base online',
              feature7: 'embed debranded presentations on your own website (no EVRYPLACE logotype displayed)',
              feature8: 'import photos directly from Ricoh Theta cameras with EVRYPLACE mobile app',
            },
            column2: {
              heading: 'Business',
              button: 'Start free trial',
              description: 'For real estate agents, small developers, photographers and architects. Also to those who need interactions while presenting online.',
              destiny: '5 users<br>10 GB Cloud Storage',
              feature1: 'create and publish 360° presentations with an intuitive online presentation editor',
              feature2: 'view or co-view presentation in the web browser or in EVRYPLACE mobile apps (iOS, Android)',
              feature3: 'share presentations by email',
              feature4: 'protect shared presentation with an access code',
              feature5: 'perform live co-viewing sessions with your clients',
              feature6: 'access to EVRYPLACE knowledge base online',
              feature7: 'embed debranded presentations on your own website (no EVRYPLACE logotype displayed)',
              feature8: 'import photos directly from Ricoh Theta cameras with EVRYPLACE mobile app',
              feature9: 'manage users in your organisation',
            },
            column3: {
              heading: 'Premium',
              button: 'Start free trial',
              description: 'For hotel owners, developers of large investments, looking for a long term marketing & sales solution.',
              destiny: '10 users<br>50 GB Cloud Storage',
              feature1: 'create and publish 360° presentations with an intuitive online presentation editor',
              feature2: 'view or co-view presentation in the web browser or in EVRYPLACE mobile apps (iOS, Android)',
              feature3: 'share presentations by email',
              feature4: 'protect shared presentation with an access code',
              feature5: 'perform live co-viewing sessions with your clients',
              feature6: 'access to EVRYPLACE knowledge base online',
              feature7: 'embed debranded presentations on your own website (no EVRYPLACE logotype displayed)',
              feature8: 'import photos directly from Ricoh Theta cameras with EVRYPLACE mobile app',
              feature9: 'manage users in your organisation',
              feature10: 'add custom logotype for each presentation',
              feature11: 'add 360° video scenes and video widgets to your presentations',
              feature12: 'view or co-view presentations in high-quality VR (requires Oculus Go or Samsung Gear VR)',
              feature13: 'add branding to presentations loader and invitation emails',
              feature14: 'export to debranded HTML package',
              feature15: 'dedicated phone and email customer support',
              feature16: 'order custom on-premise deployment and/or integrations',
            },
            businessModelHeading: 'Different Business Model?',
            businessModelSubHeading: 'Are you looking to integrate a 360° solution into your existing business workflows? Get on premise installation, managed private cloud instance, dedicated training and priority support. Get in touch to discuss custom pricing.',
            businessModelButton: 'Contact Sales',
            includedIn: 'Included in each EVRYPLACE Plan:',
            includedInBox1: 'Full presentation customizability and augmentation',
            includedInBox2: 'Built in synchronization with Ricoh Theta 360° cameras',
            includedInBox3: 'Standalone web page for each presentation',
            includedInBox4: 'Support for 360° stereoscopic images',
            includedInBox5: 'Publishing to EVRYPLACE Explore website',
            includedInBox6: 'Publishing to mobile VR apps for Android and iOS',
            functionalitiesHeading: 'Why EVRYPLACE?',
            functionalitiesBox1: 'Two years of experience and improvement on the best 360° editor on the market.',
            functionalitiesBox2: 'Designed in partnership with the largest real estate companies in the world.',
            functionalitiesBox3: 'Tailored to multiple sales channels',
            faqHeading: 'Najczęściej zadawane pytania',
            faq1question: 'Lorem ipsum dolor sit amet, consectetuer ?',
            faq1answer: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo ',
            faq2question: 'Lorem ipsum dolor sit amet, consectetuer ?',
            faq2answer: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo ',
            faq3question: 'Lorem ipsum dolor sit amet, consectetuer ?',
            faq3answer: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo ',
            faq4question: 'Lorem ipsum dolor sit amet, consectetuer ?',
            faq4answer: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo ',
            faq5question: 'Lorem ipsum dolor sit amet, consectetuer ?',
            faq5answer: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo ',
            faq6question: 'Lorem ipsum dolor sit amet, consectetuer ?',
            faq6answer: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo ',
            moreQuestionsHeading: 'More questions?',
            moreQuestionsButton1top: 'Contact Support Team',
            moreQuestionsButton1bottom: 'Reach out to us',
            moreQuestionsButton2top: 'Go to FAQ',
            moreQuestionsButton2bottom: 'Find quick answers',
            metaDescription: 'Prosta i przejrzysta wycena. Plany pasujące do zespołów i firm na każdym etapie ich projektów 360°',
            metaKeywords: 'price, cost',
            metaTitle: 'Pricing - Evryplace',
          },
          contactModal: {
            title: 'Contact our Sales Team',
            subheader: 'Are you interested in a dedicated offer or custom solution? Let’s talk about it.',
            emailPlaceholder: 'E-mail',
            messagePlaceholder: 'Your message',
            submit: 'Send a message',
            usedCharacters: 'Used Characters: ',
            minCharacters: '(min. 20)',
          },
          coronaVirusInfoModal: {
            title:'Virtual property tour as a solution to the crisis caused by the Coronavirus outbreak.',
            body1:'In response to growing anxiety, EVRYPLACE, a platform created to present property space using 3D spherical photos, launched the new function "Remote Property Tour"',
            body2:'Thanks to that, both the real estate agent and clients can participate in the presentation of the property without the need for face-to-face contact.',
            body3:'To meet your expectations and to help you go through these hard times, we decided to provide you with the functionality of remote presentation without extra charges, accessible in all pricing plans',
            buttonText:'Learn more',
          },
          confirmationDialog: {
            message: 'E-mail has been sent.'
          },
          confirmation: {
            title: 'Thank you for choosing Evryplace!',
            p1: 'Thanks for confirming e-mail address. Your account is now active.',
            p2: 'Meet with your Customer Support Specialist for a quick intro. He will guide you through first steps in Evryplace and answer your questions.',
            p3: 'Or just explore on your own:',
            h1: 'Book an intro',
            b1: 'Create your tour',
            b2: 'See the tutorial',
          },
          notfound: {
            title: 'Page not found.',
            subheader: ''
          },
          explore: {
            picked: 'Picked',
            popular: 'Popular',
            newest: 'Latest',
            button: 'View presentation',
            searchPlaceholder: 'Search...',
            madeByMe: 'Made by me'
          },
          faq: {
            metaDescription: 'FAQ - miejsce z odpowiedziami na wszystkie najważniejsze pytania naszych klientów',
            metaKeywords: 'FAQ',
            metaTitle: 'FAQ - Evryplace',
            title: 'FAQ',
            q1: 'What is a "Virtual Tour"?',
            a1: 'To put it simply - Virtual Tour is series of panoramic images linked together to create a realistic tour of property, space or area.',
            q2: 'I’m not a 360° / VR / tech expert. Will I be able to use the EVRYPLACE Editor?',
            a2: 'New technology can seem intimidating, but EVRYPLACE was designed to be used by anyone. If you can use a smartphone, you can use our Editor. You can also follow EVRYPLACE Editor Tutorial or find answers to more questions in our Support Center.',
            q3: 'Can I afford EVRYPLACE?',
            a3: 'You can create a single tour for free. Once you try it out, you can look into different subscription options available, to find the solution fitting your requirements, and your budget.',
            q4: 'Do I need a VR headset to view Virtual Tours or participate in VR Trainings & Workshops?',
            a4: 'EVRYPLACE presentations can be viewed on VR headsets but are also compatible with mobile devices, laptops and desktop PCs.',
            q5: 'What type of camera do you need to take a panorama?',
            a5: 'Panoramas can be captured with any number of cameras, like the Ricoh Theta, that has the capability built-in, including some camera phones. To be uploaded to the platform, the panoramic photo simply needs to meet the file specifications.',
            q6: 'I don’t have access to 360° media. Can I order 360° Photographs or renders with you?',
            a6: 'I’m afraid we don’t offer 360° rendering of photography service.',
            q7: 'Some of my projects are private and sensitive. Who will have access to my presentations?',
            a7: 'There are two ways to publish a tour. You can share your tour publicly. This way it will be available in the evry.place/explore gallery for everyone to see. If you\'re worried about visibility and accessibility of your projects you can set its visibility to private. This way it will be accessible only to people with the direct URL.',
            q8: 'Can my presentation be viewed on a mobile phone?',
            a8: 'Virtual Tours and Presentations are fully responsive and can be shared and viewed on mobile devices.',
            q9: 'Can I view the presentation on a mobile VR headset?',
            a9: 'Right now, you\'ll need a Gear VR or Google Cardboard headset, with a compatible mobile device. To view your EVRYPLACE Presentation on a mobile VR headset, click on the goggles icon in the EVRYPLACE Editor and follow the instructions for your device.',
            q10: 'Can I share created Tour or Presentation on Social Media',
            a10: 'Yes you can share your Tour or Presentation on the social media platform of your choice. Open any EVRYPLACE Presentation and then select the share button.',
            q11: 'Can I share the tour with other people live?',
            a11: 'You can share the tour live, through the Online Viewing functionality. Online Viewing is a collaborate feature that allows you to live stream, in real-time, the presentation into client’s headset or mobile device. You can moderate your client\'s experience, pick Presentations they are viewing, share the control of the tour or leave annotations live. You can do this on any browser, on any device.',
          },
          ctablock: {
            header: 'Create\xa0your first VR\xa0presentation <br>- it’s\xa0free!',
            subheader: 'Don’t have your own materials? <br>No worries, take advantage of the trial shots in EVRYPLACE.',
            trybtn: 'TRY NOW'
          },
          VRModal: {
            title: 'View the presentation in VR',
            openOnVR: 'Just open the presentation link in Oculus Quest Internet browser',
            more: 'More about Evryplace VR',
          },
          privatePresentationModal: {
            title: "This presentation is protected by password",
            label: "ENTER PASSWORD",
            tip: "Enter password to view presentation",
            error: "The password entered is incorrect. Please try again",
          },
          terms: {
            title: 'Terms and conditions',
            block1: {
              header: 'User – EVRYPLACE relations',
              li1: 'Using the EVRYPLACE website (Website) and all products, software, data and services, including the EVRYPLACE built-in player (“EVRYPLACE Player") provided to the user by EVRYPLACE via the Website or originating from it or made available via it (herein jointly referred to as "Services") shall be governed by the regulations included in the agreement between the user and EVRYPLACE.',
              p: 'The owner of EVRYPLACE is FREAM S.A. Powstańców Śląskich 28-30 50-333 Wrocław,\n' +
                'KRS 0000395554  REGON 021551235  NIP 8982192139',
              li2: 'The agreement between the user and EVRYPLACE consists of (A) terms and conditions presented herein, (B) Privacy Policy followed by EVRYPLACE. ',
              li3: 'The terms and conditions shall constitute a legally binding agreement between the user and EVRYPLACE concerning the use of the Services by the user.  The User shall familiarise themselves with them carefully.',
              li4: 'The terms and conditions shall apply to all users of Services, including those who post Contents using the Services.  The “Contents” shall mean all texts, software, scripts, graphics, photos, audio, music, films, audio-visual materials, interactive and other materials, which the user may review and access or publish using the Services.',
            },
            block2: {
              header: 'Accepting the Terms and Conditions',
              li1: 'The User shall accept the Terms and Conditions prior to using the Services.  In the case of refusal to accept the terms and conditions, the user will not be able to use Services.',
              li2: 'The User may accept the Terms and Conditions by starting to use the Services.  The User acknowledges and accepts the fact that by starting to use the EVRYPLACE Services they understand and accept of the Terms and Conditions.',
              li3: 'The User must not use the Services and accept the Terms and Conditions if (a) the User is under the age which allows to enter legally into a binding agreement with EVRYPLACE or (b) the User is a person with a pending ban on receiving or using the Services pursuant to the laws of the country of their permanent residence or of the country where such access and use of Services would take place. ',
              li4: 'The User shall print and save a copy of the Terms and Conditions valid in their location. ',
            },
            block3: {
              header: 'Change of the Terms and Conditions ',
              li1: 'EVRYPLACE reserves the right to make changes in the Terms and Conditions, e.g. in order to reflect changes in laws and legal regulations or changes to the functions offered as a part of the Services.  For this reason the user shall check regularly whether the Terms and Conditions have changed.   Such a changed version of the Terms and Conditions (“Changed Terms and Conditions”) shall be made available as a part of the Services (in the case of changes of additional terms and conditions).   The User who refuses to accept the Changed Terms and Conditions shall stop using the Services.  Using the Services after the publication of the Changed Terms and Conditions shall be understood as acceptance of the Changed Terms and Conditions. ',
            },
            block4: {
              header: 'General limitations of use',
              li1: 'EVRYPLACE hereby grants the user a permission to access the Services and use them subject to the following express terms, and the user hereby acknowledges that failure to comply with any of these terms shall constitute a breach of these Terms and Conditions.',
              subLi1: 'User agrees not to distribute any parts of the Website or Services, including, however not limited to, Contents on any media without prior written permission from EVRYPLACE unless EVRYPLACE provides means making such distribution possible through features offered under the Services (e.g. “EVRYPLACE Player”);',
              subLi2: 'User agrees not to make changes to any part of the Website or Services (including the EVRYPLACE Player and technologies connected therewith);',
              subLi3: 'User agrees not to access Contents using technologies of means other than those used for the purpose of viewing the presentations on the Website, EVRYPLACE Player or  other means provided clearly for this purpose by EVRYPLACE;',
              subLi4: 'User agrees not to by-pass, block or disrupt in any other way (and not to attempt any of these) functioning of elements securing the Services or elements which',
              subLi4a: 'render using or copying of Contents impossible or',
              subLi4b: 'introduce limitations in the scope of using the Services or contents available via the Services;',
              subLi5: 'User agrees not to use the Services (including the EVRYPLACE Player) for commercial purposes described herein below, without a prior written authorisation provided by EVRYPLACE:',
              subLi5a: 'resale of the access to the Services;',
              subLi5b: 'resale of advertising, sponsoring or promotional materials published in the Services and in the Content;',
              subLi5c: 'resale advertising, sponsoring or promotional materials published on any other blog or website activated by an advertisement, which contains Contents originating from the Services, unless other material received from a different source than EVRYPLACE appears on the same site and has sufficient value to become the basis for such resale;',
              subLi6: 'User agrees not to use or implement any automated system including but not limited to robots, spiders, or offline readers that access the Services in the way that they send more requests to EVRYPLACE servers in a given period than a person would at the same time using a publicly available standard (i.e. unmodified) website.',
              subLi7: 'User agrees not to collect any identifiable personal information about the Website or Services (and accepts that it shall also include EVRYPLACE accounts);',
              subLi8: 'User agrees not to acquire users of this website for commercial purposes selected in connection with the Content they are interested in; and',
              subLi9: 'User agrees to access Content only for the intended purposes resulting from the standard functions of the Services.',
              subLi10: 'User agrees not to copy, duplicate, distribute, forward, transmit, broadcast, display, sell, license or otherwise use the Content in any way without the prior written consent of EVRYPLACE or the respective Content licensors.',
              li2: 'User agrees to comply with all other provisions of the Terms and Conditions of the EVRYPLACE Community at all times during the use of the Services.',
              li3: 'EVRYPLACE allows operators of publicly accessible search engines to use spiders to copy materials from the website only to create publicly accessible, searchable censuses, however not copies (cache) or archives of such materials.  EVRYPLACE reserves the right to revoke such a permit in general or in individual cases.',
              li4: 'EVRYPLACE continually introduces innovations to give users the best possible experience.  The user acknowledges and accepts that the form and nature of the Services provided by EVRYPLACE may be changed without prior notice to the user.',
              li5: 'The user acknowledges, that as a part of the implementation of innovations, EVRYPLACE may stop (permanently or temporarily) providing Services (or any elements thereof) to the user or all users at EVRYPLACE’s sole discretion and without prior notice.  The user may stop using the Services at any time. The user does not need to notify EVRYPLACE of stopping to use Services.',
              li6: 'User bears sole responsibility for any breach of the user’s obligations resulting from the Terms and Conditions and for any consequences of such a breach (including any damage or damage sustained by EVRYPLACE) and at the same time EVRYPLACE shall not be liable to the user or any third party for the same.',
            },
            block5: {
              header: 'Contents',
              li1: 'As an EVRYPLACE account holder, the user may post Contents.  The user acknowledges, that regardless whether Content is published, EVRYPLACE does not guarantee confidentiality of the Content.',
              li2: 'Changing the status of a user\'s material can take up to 10 minutes.',
              li3: 'User retains all property rights to their own Content, however some limited rights shall be granted to EVRYPLACE and other users of the Services.  The scope thereof has been listed in art. 8 herein (Rights to which the user grants the license).',
              li4: 'User acknowledges and accepts that they shall bear sole responsibility for their own Content and consequences of their posting and publishing.  EVRYPLACE does not endorse any Content or any opinions, recommendations or advice expressed therein, and declines expressly accepting any responsibility in connection with the Content.',
              li5: 'User declares and assures that they own (and when using the Services they shall still own) any relevant licenses, rights, permissions and consents required for EVRYPLACE to use the Content in order to provide Services and in order to use the Content in line with the Terms and Conditions of the Services.',
              li6: 'User agrees not to post or transmit any Content, if the Content contains information that may be illegal to the user in the country of their residence, or information whose use or possession is illegal for EVRYPLACE in connection with the Services they provide.',
              li7: 'User agrees that Content submitted by the user in the Services shall not contain any material that is a subject of a third party’s property rights (including privacy rights and image control rights), unless the user holds an official license or permission from the rightful owner or the user holds another entitlement to posting the material in question and granting the license referred to in art. 8.1 below to EVRYPLACE.',
              li8: 'EVRYPLACE reserves the right (but not obligation) to determine freely and without prior notice, whether Content meets the requirements set out in these Terms and Conditions and to remove such Content and/ or prevent the user from posting Content violating these Terms and Conditions at any time, whenever EVRYPLACE becomes aware of any potential breach of these Terms and Conditions.',
              li9: 'Also, the User acknowledges and agrees that when using the Services, the user may be exposed to Content that may be inaccurate, abusive, indecent or otherwise objectionable to the user for whatever reasons.  The user hereby agrees to waive and hereby waives the rights and legal remedies available to them against EVRYPLACE under the laws and fairness rules with respect to such Content.',
            },
            block6: {
              header: 'EVRYPLACE accounts',
              li1: 'In order to access certain parts of the Website or other components of the Services, the user needs to set up an EVRYPLACE account.  When setting up an account, the user should provide accurate and complete information. It is important for the user to protect their EVRYPLACE account password and to keep it confidential.',
              li2: 'User should immediately notify EVRYPLACE of any security breach or unauthorized use of their EVRYPLACE account as soon as they realise it may have happened.',
              li3: 'User understands that he/ she is solely responsible (to EVRYPLACE and others) for any actions within their own EVRYPLACE account.',
            },
            block7: {
              header: 'Rights to which the user grants the license',
              li1: 'By uploading or posting Contents on EVRYPLACE sites, the user grants:',
              li2: 'to EVRYPLACE - territorially unrestricted, non-exclusive, free of charge, transferable license (with the right to sub-license) to use the Content, reproduce and distribute it, develop derivative works from it, exhibit or perform in connection with the provision of the Services and EVRYPLACE business, including – among others - to promote and distribute the Services in whole or in part (including derivative works) irrespective of the format of the medium and the manner in which the material is distributed;',
              li3: 'to each User - territorially unrestricted, non-exclusive, free of charge license to access the Content via the Services and to use the Content, reproduce and distribute it, develop derivative works from it, exhibit or perform within the scope of the functionality offered by the Services and within the limits permitted by these Terms and Conditions.',
              li4: 'The above user licenses for the Content expire when the user deletes or erases them from the Website. The above licenses granted by the user in respect of texts posted as Content are unlimited in time and irrevocable, however, in other respects  they shall be irrelevant as to the substance of the proprietary rights set forth in art. 7.2 herein above.',
              li5: 'EVRYPLACE Content on the Website',
              li6: 'Apart from the Content posted by the user in the Services, any other Content available from the Services shall belong to or shall be licensed by EVRYPLACE and the Content is subject to copyright, EVRYPLACE\'s trademark rights and other intellectual property rights of EVRYPLACE or EVRYPLACE\'s licensors. Third parties’ trademarks or service marks available from the Content that have not been uploaded or posted by the user shall be treated as trademarks or service marks of their respective owners. Such Content may not be downloaded, copied, reproduced, distributed, transferred, transmitted, displayed, sold, licensed or otherwise exploited for any purpose without a prior written consent of EVRYPLACE or, where applicable, of EVRYPLACE’s licensors. EVRYPLACE and EVRYPLACE’s licensors reserve all rights to their Contents to which rights have not been expressly granted by them.',
            },
            block8: {
              header: 'EVRYPLACE links',
              li1: 'Presentations created using EVRYPLACE may have several statuses set by the user:\n' +
                '[xxxxxxxxxxxx1] – access to this presentation is for a logged in user only\n' +
                '[xxxxxxxxxxxx2] - access to this presentation is for a logged in user as well as any other user who received a link to the presentation; this presentation cannot be found via  EVRYPLACE search engine\n' +
                '[xxxxxxxxxxxx3] - access to this presentation is for a logged in user as well as any other user who received a link to the presentation; this presentation can also be found via  EVRYPLACE or other search engine  \n' +
                'Users may change the status as well as they may change links to the presentations.  EVRYPLACE shall not be responsible for listing presentations in third-parties’ search results, however, after changing the presentation status,  access thereto shall be blocked on its servers, subject to stipulations of section 5.2.\n',
              li2: 'Services may contain hyperlinks to other sites which are not owned or controlled by EVRYPLACE. EVRYPLACE has no control over the content, privacy practices or practices of third-parties’ sites, and shall not be held responsible for them.',
              li3: 'User understands and agrees that EVRYPLACE is not responsible for the availability of external sites or resources and does not endorse any advertising, products or other materials available through such sites or resources.',
              li4: 'User understands and agrees that EVRYPLACE is not responsible for losses or damages which the user may sustain in connection with availability of external websites or resources or in connection with the user’s relying on completeness, accuracy or existence of advertisements, products or other materials available through such sites or resources.',
              li5: 'EVRYPLACE encourages users to actively monitor all circumstances when leaving the Services and to review the terms and privacy policy of each website they visit.',
            },
            block9: {
              header: 'Termination of relation with EVRYPLACE',
              li1: 'Terms and Conditions shall remain valid until terminated by the user or by EVRYPLACE in the manner provided herein below. ',
              li2: 'Shall the user wish to terminate the agreement with EVRYPLACE, they shall be able to do that',
              li3: 'at any time by sending a termination notice to EVRYPLACE and ',
              li4: 'by closing their own EVRYPLACE account.  The termination notice shall be sent in writing to EVRYPLACE’s address provided in the introduction to the Terms and Conditions. ',
              li5: 'EVRYPLACE may terminate the agreement with the user at any time with immediate effect if:',
              li6: 'User breaches any of the Terms and Conditions (or the user takes action that clearly indicates that he or she does not intend or is unable to comply with the Terms and Conditions); or',
              li7: 'EVRYPLACE shall be obliged to terminate the contract in accordance with applicable law (for example, when the provision of the Services to the user is or becomes unlawful); or',
              li8: 'EVRYPLACE may terminate a binding agreement with the user if:',
              li9: 'EVRYPLACE ceases to provide Services for residents of the user’s country or in the country where the user uses the Services; or',
              li10: 'providing Services to the User is no longer financially feasible for EVRYPLACE, and in both the cases described in points A and B of this art. 11.4, EVRYPLACE shall notify the user in writing of the termination with a 7-days’ notice.',
              li11: 'In the case of the user’s or EVRYPLACE’s rights, obligations and responsibilities that the User and EVRYPLACE were subject to (or which accrued at the time when the Terms and Conditions were valid) or which were expressly stated to remain valid indefinitely, the expiration of the Terms and Conditions shall be irrelevant and the provisions of article 14.6 shall apply to such rights, obligations and liability indefinitely, even after the termination of these Terms and Conditions.',
            },
            block10: {
              header: 'Exclusion of implied warranties',
              li1: 'Neither of these Terms and Conditions shall affect user’s statutory rights as a consumer, and the user may not alter or waive such rights by way of any contractual means.',
              li2: 'Services are provided on an “as is” basis therefore EVRYPLACE does not grant the user any warranties and makes no representations in this scope.',
              li3: 'In particular EVRYPLACE does not make any declarations and representations that:',
              li4: 'the Services will satisfy fully the User’s expectations,',
              li5: 'using the Services by the User will always be uninterrupted, timely, safe or error-free,',
              li6: 'the information the user obtains when using the Services is accurate and reliable, and that',
              li7: 'the operation or functionality defects of the software provided to the user under the Services will be remedied.',
              li8: 'Terms, conditions or other representations (such as implied terms of satisfactory quality, conformity to the intended purpose or description) which have not been expressly included in these Terms and Conditions do not apply to the Services.',
            },
            block11: {
              header: 'Limitation of liability',
              li1: 'None of the stipulations of these Terms and Conditions shall exclude or limit EVRYPLACE\'s liability for loss which, under applicable law, is not subject to exclusion or limitation.',
              li2: 'Subject to the general meaning of article 13.1 herein above, EVRYPLACE shall not be liable to the user for any:',
              li3: 'indirect or resulting loss which the user may sustain.  The term “loss” shall mean a (direct or indirect) loss of profit, loss of reputation or goodwill of the company, and loss of data by the user;',
              li4: 'losses and damages sustained by the user as a result of: ',
              li5: 'the user’s relying on the completeness, accurateness or existence of an advertisement, or user’s commencing a relation or transaction with the advertisement provider or sponsor, whose advertisements are published as a part of the Services;',
              li6: 'any changes that EVRYPLACE may make to the Services or the temporary or permanent termination of the Services (or any part of the Services);',
              li7: 'removing, damaging or failing to save any Content or other communication data which is maintained or transmitted as part of providing the Services to the user;',
              li8: 'User’s failure to provide EVRYPLACE with truthful and correct data of the account user;',
              li9: 'User’s failure to observe safety and confidentiality rules concerning their password or other EVRYPLACE account data;',
              li10: 'The limitation of EVRYPLACE’s liability to the user expressed in the above article 11.2 applies regardless of any prior notification to EVRYPLACE of the possibility of loss or damages and regardless if EVRYPLACE should have been aware of these circumstances.',
            },
            block12: {
              header: 'General terms of legal relationship',
              li1: 'The Terms and Conditions contain the entire agreement between the user and EVRYPLACE and govern the use of the Services by the user; they replace any and all prior agreements between the parties in the scope of the Services.',
              li2: 'User agrees that EVRYPLACE may send them notifications of changes to the Terms and Conditions by e-mail, traditional post or by posting relevant information in the Services sites. ',
              li3: 'User understands that even if EVRYPLACE does not request satisfaction or claim a right or remedy provided in the Terms and Conditions (or resulting from the law) this shall not be understood as EVRYPLACE’s formal waiving of the rights and all rights and legal measures shall remain available for EVRYPLACE. ',
              li4: 'If any court having jurisdiction in the matter declares invalidity of any provision of these Terms and Conditions, the provision shall be removed from the Terms and Conditions in such a manner as not to affect the integrity of the remaining provisions. The remaining provisions of the Terms and Conditions shall remain valid and enforceable. ',
              li5: 'The Terms and Conditions and relations between the user and EVRYPLACE under the Terms and Conditions shall be governed by the laws of Poland. The User and EVRYPLACE agree to submit to the exclusive jurisdiction of the Polish courts in all legal matters arising under the Terms and Conditions. Regardless of the above, the user agrees that EVRYPLACE has the right to apply for interim relief (or other equivalent remedies) in any jurisdiction.',
            },
          },
          viewing: {
            enterHashModal: {
              title: 'Welcome to EVRYPLACE online co-viewing',
              titleBrand: 'Welcome to ',
              titleBrand2: ' online co-viewing',
              label: 'ENTER HASH',
              tip: 'Enter the co-viewing ID to connect.',
              validation: 'Co-viewing ID must contain 6 characters.',
            },
            enterNameModal: {
              label: 'Enter name',
              placeholder: 'YOUR NAME',
            },
            welcomeModal: {
              title: 'Welcome to EVRYPLACE online co-viewing',
              titleBrand: 'Welcome to ',
              titleBrand2: ' online co-viewing',
              content1: 'You are now connected.',
              content2: ' Click “START CO-VIEWING” to begin.',
              cta: 'START COVIEWING',
            },
            errorModal: {
              title: 'Unable to join co-viewing.',
              content1: 'This co-viewing ID is invalid or has expired.',
              content2: 'Please check your invitation and try again.',
            },
            waitingModal: {
              title: 'Welcome to EVRYPLACE online co-viewing.',
              titleBrand: 'Welcome to ',
              titleBrand2: ' online co-viewing',
              content1: 'You are now connected.',
              content2: 'Please wait for the host to join.</br>You will connect automatically once the host joins the co-viewing.',
            },
            connectionStatusModal: {
              connectedAwaiting: 'Connected. Please wait for the host to join.',
              hostConnected: 'Host is now connected.',
              voiceConnectionUnaviable: 'Voice connection unaviable.',
              contactWithHost: 'Host is late? Contact him with above data.',
              hostCanStartVoiceConnection: 'Host can start voice connection.',
              voiceConnectionOn1: 'Voice connection is on.',
              voiceConnectionOn2: 'Use buttons on controls bar to mute microphone or speaker.',
            },
            messages: {
              youAreInControl: 'You are in control of the presentation',
              hostIsInControl: 'Host is in control of the presentation',
              voiceCallStarted: 'A voice call has started',
              voiceCallCompleted: 'Voice call completed',
              clientDisconnected: ' has left the co-viewing',
              hostDisconnected: 'Host has left the co-viewing',
              micAccessFailed: 'It looks like you’ve blocked permissions to access your microphone. Please check your browser settings',
              updateRequired: 'You need to upgrade your account to use this feature'
            }
          },
          viewingHost: {
            anonymousClient: 'Anonymous Client',
            participants: 'Participants',
            notes: 'Notes',
            presentation: 'Presentation',
            presenting: 'Presenting',
            audio: 'Audio',
            guide: 'Guide',
            follow: 'Follow',
            waiting: 'waiting...',
            showOtherPresentation: 'Show other presentation',
            cannotLoad: 'Cannot load presentation',
            myVirtualTours: 'My virtual tours',
            empty: 'No more published presentations'
          },
          rodoModal: {
            title: 'Dear customers,',
            copy1: 'Privacy laws in the European Union are changed as of May 25, 2018, on which date the General Data Protection Regulation is effective. In an effort to strengthen your privacy rights, we are updating our. For this reason, as of May 25, 2018, the EVRYPLACE Privacy Policy, which is available on the website <a href="/privacy">https://evryplace.com/privacy</a> is updated.',
            copy2: 'I declare that I have read and I accept ',
            copy3: 'Terms of Service',
            copy4: 'These updates will automatically come into effect for all existing users on May 25, 2018. Your continued use of EVRYPLACE product and services from that day on will be subject to these updated Terms.',
            cta: 'I UNDERSTAND',
          },
          validation: {
            emailTooLong: 'Max character limit reached (128 characters)',
            emailNotValid: 'E-mail address is not valid.',
            passwordTooShort: 'Password must contains at least 8 characters',
            passwordsNotEqual: 'Passwords do not match.',
            displayNameTooShort: 'Your name is too short.',
            messageTooShort: 'Provided message is too short.',
            contactModalMessage: 'Your message is too short. Please include more details about your project, so that the we better understand what you’re looking for.',
          },
          embed: {
            splashPageText: 'Click to start 360° walk',
            dragAndDropAction: 'Drag to look around',
            loading: 'Loading...'
          },
          lgExplore: {
            filters: {
              title: 'Filters',
              shopAttributes: 'Shop attributes',
              pdsShop: 'Shop PSD',
              nameShop: 'Shop name',
              idAgent: 'ID Agent',
              nameAgent: 'Agent full name',
              addressShop: 'Shop address',
              area: 'Area',
              manager: 'Regional Manager',
              rks: 'RKS',
              flyingCoach: 'Flying Coach',
              city: 'City',
              citySearchPlaceholder: 'Find city',
              brand: 'Brand',
              location: 'Location',
              prformat: 'Preformat',
              presentationAttributes: 'Presentation attributes',
              dateOfAddition: 'Date of addition',
              startDay: 'Start day',
              endDay: 'End day',
            },
            searchPlaceholder: 'Search for presentation',
            newPresentation: 'New presentation',
            createDate: 'Creation date',
            addPhotosphere: 'Add photoshperes',
            onDrag: 'or drag and drop them into this window',
            addPsd: 'Pick PSD and Date',
            others: 'Others',
            fileUploadError: 'Could not upload file',
            retry: 'Retry',
          },
          shopPresentation: {
            author: 'Author',
            day: 'Date added',
            psd: 'PSD',
            locationName: 'Location',
            rksName: 'Regional Manager',
            fcName: 'Flygin coach',
            prFormat: 'Prformat',
            rksId: 'RKS',
            address: 'Address',
            city: 'City',
            rmId: 'Agent ID',
            rmName: 'Agent name',
            presentationHistory: 'Presentation history',
            newPsd: 'Change shop\'s PSD',
          },
          tourism: {
            heroHeading: 'Pozwól odbiorcom poczuć<br>się jak goście Twojego hotelu',
            heroSubHeading: 'Ugość oglądających w wirtualnych wnętrzach swojego hotelu i spraw, że przez chwilę poczują się jak na wakacjach.',
            whyEpHeading: 'Dlaczego EVRYPLACE?',
            item1Heading: 'Udoskonalisz proces rezerwacji.',
            item1Desc: 'Spraw, że rezerwacja przebiegnie szybciej i przyjemniej. Pokaż przyszłym gościom Twojego hotelu, że prezentowane przestrzenie, wyglądają dokładnie tak samo poza wirtualnym spacerem. Ułatw im proces decyzyjny uwzględniając niezbędne detale.',
            item2Heading: 'Zaprezentujesz atuty swojej oferty… i jej okolicy.',
            item2Desc: 'Lokalizacja hotelu jest tak samo ważna jak jego standardy, a informację na ich temat zawrzesz w jednej prezentacji. Opiszesz szczegóły wyposażenia pokoju, ale też pokażesz widok z okna czy dołączysz dźwięki z pobliskiego parku.',
            item3Heading: 'Dostarczysz wrażenia jeszcze przed przybyciem gości.',
            item3Desc: 'Uczyń z doświadczania w 360° swoje kluczowe narzędzie marketingowe. Usprawnij kontakt z odbiorcami, pozwól im oglądać wnętrza Twojego hotelu bez względu na czas i odległość. Zdobądź ich zaufanie zanim pojawią się na miejscu.',
            carousel1: {
              heading1: 'EVRYPLACE dla Hotelarstwa',
              desc1: 'Spraw, że rezerwacja przebiegnie szybciej i przyjemniej. Pokaż przyszłym gościom Twojego hotelu, że prezentowane przestrzenie, wyglądają dokładnie tak samo poza wirtualnym spacerem. Ułatw im proces decyzyjny uwzględniając niezbędne detale.',
              heading2: 'EVRYPLACE dla Hotelarstwa',
              desc2: 'Spraw, że rezerwacja przebiegnie szybciej i przyjemniej. Pokaż przyszłym gościom Twojego hotelu, że prezentowane przestrzenie, wyglądają dokładnie tak samo poza wirtualnym spacerem. Ułatw im proces decyzyjny uwzględniając niezbędne detale.',
              heading3: 'EVRYPLACE dla Hotelarstwa',
              desc3: 'Spraw, że rezerwacja przebiegnie szybciej i przyjemniej. Pokaż przyszłym gościom Twojego hotelu, że prezentowane przestrzenie, wyglądają dokładnie tak samo poza wirtualnym spacerem. Ułatw im proces decyzyjny uwzględniając niezbędne detale.',
            },
            carousel2: {
              heading1: 'EVRYPLACE dla Hotelarstwa',
              desc1: 'Spraw, że rezerwacja przebiegnie szybciej i przyjemniej. Pokaż przyszłym gościom Twojego hotelu, że prezentowane przestrzenie, wyglądają dokładnie tak samo poza wirtualnym spacerem. Ułatw im proces decyzyjny uwzględniając niezbędne detale.',
              heading2: 'EVRYPLACE dla Hotelarstwa',
              desc2: 'Spraw, że rezerwacja przebiegnie szybciej i przyjemniej. Pokaż przyszłym gościom Twojego hotelu, że prezentowane przestrzenie, wyglądają dokładnie tak samo poza wirtualnym spacerem. Ułatw im proces decyzyjny uwzględniając niezbędne detale.',
              heading3: 'EVRYPLACE dla Hotelarstwa',
              desc3: 'Spraw, że rezerwacja przebiegnie szybciej i przyjemniej. Pokaż przyszłym gościom Twojego hotelu, że prezentowane przestrzenie, wyglądają dokładnie tak samo poza wirtualnym spacerem. Ułatw im proces decyzyjny uwzględniając niezbędne detale.',
            },
            info: 'Find out more about the pricing plans for Hotels and Tourism',
            exampleHeading: 'Przykładowa prezentacja',
            exampleTitle: 'Title 01',
            exampleDescription: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate',
          },
          realEstate: {
            heading: 'Get the best marketing<br>tool for Real Estate',
            subheading: 'Win clients,\xa0empower your brand<br>while saving time and money.',
            whyUse: 'Why use EVRYPLACE',
            whyUseSubhead1: 'Reach more audience',
            whyUseDesc1: 'Get only the engaged buyer,\xa0and save time by allowing your clients to\xa0preview a property in an always-open house that’s always show-ready.',
            whyUseSubhead2: 'Present your listings better',
            whyUseDesc2: 'Bring your listings to life. Make 360° experiences the key visual marketing asset that will help you connect with your audience and win more clients.',
            whyUseSubhead3: 'Sell faster',
            whyUseDesc3: 'Allow engaged audience to connect with your sales team instantly,\xa0and schedule or run live a remote 360° / VR viewings using a single click Co-Viewing feature.',
            forWhoHeading1: 'EVRYPLACE for Real Estate Agents',
            forWhoHeading2: 'EVRYPLACE for Residential Developers',
            forWhoHeading3: 'EVRYPLACE for Commercial Real Estate',
            forWhoBox1heading1: 'Simple and Intuitive',
            forWhoBox1description1: 'Synchronize your 360° camera with EVRYPLACE. Upload images directly from device to your account and generate a 360° slideshow to turn your 360° photos into property tours in minutes.',
            forWhoBox1heading2: 'Bring the property to life',
            forWhoBox1description2: 'Mimic a showaround session with the Auto Tour feature - allow the presentation to automatically present rooms, one by one. Allow your clients to re-play the different areas of the property as if participating in a live showaround.',
            forWhoBox1heading3: 'Compatible with MLS sales channels',
            forWhoBox1description3: 'Embed your tour into your sales website or use the embedding functions to create a non-branded version of your tours, that your can quickly add to your MLS listings.',
            forWhoBox2heading1: 'Start selling early',
            forWhoBox2description1: 'Use quality 360° renderings to showcase your property to buyers before the construction starts. Invite clients to your sales offices for a VR tour or add 360° tour to your online sales campaigns.',
            forWhoBox2heading2: 'Build your brand ',
            forWhoBox2description2: 'Gain the tech advantage over your competitors with VR tours. Help buyers connect the visual experience with your brand - customize branding of your tour fit your brand or your investment branding.',
            forWhoBox2heading3: 'Run live remote property tours',
            forWhoBox2description3: 'Allow your clients to book a live 360°/VR tour. Navigate around the Tour with your clients, move and look around the Tour - with all participants able to hear each other. Show round a property without having to leave the office.',
            forWhoBox3heading1: 'Invite brands to fill out your Commercial space',
            forWhoBox3description1: 'Present to your clients how a commercial space might work for them. Quickly change client branding to increase the attractiveness of your offer in the eyes of potential prospects.',
            forWhoBox3heading2: 'Show different fit-out options',
            forWhoBox3description2: 'Give your clients the possibility to preview the space in different fit out options. Reduce their uncertainty by allowing them to pick the option they like the most.',
            forWhoBox3heading3: 'More Intuitive Technical information',
            forWhoBox3description3: 'Use 360° presentations to show both, the function and the atmosphere of an office space. Presenting technical standards, and function areas from a human perspective, rather than 2D slides increases client engagement and trust.',
            cta1Info: 'Find out more about the pricing<br>plans for Real Estate Agents',
            cta1Button: 'Try it for free',
            cta2Info: 'Learn how SKANSKA is using<br>EVRYPLACE for their residential sales',
            cta2Button: 'Join EVRYPLACE now',
            cta3Info: 'Learn how SKANSKA is using<br>EVRYPLACE for their residential sales',
            cta3Button: 'Join EVRYPLACE now',
            useCase: 'Use case',
            useCaseHeader1: 'More Intuitive Technical information',
            useCaseDescription1: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magnion ullamcorper suscipit lobortis nisl ut aliquip ex ea lor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis ',
            useCaseHeader2: 'More Intuitive Technical information',
            useCaseDescription2: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magnion ullamcorper suscipit lobortis nisl ut aliquip ex ea lor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis ',
            useCaseHeader3: 'More Intuitive Technical information',
            useCaseDescription3: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magnion ullamcorper suscipit lobortis nisl ut aliquip ex ea lor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis ',
            tryItHeading: 'Convinced? Try it now!',
            investmentHeading1: 'Investment',
            investmentDesc1: '<li>Present full overview of the investment in 360°<br>rendering and photography</li><li>Perform remote tours over long distances</li><li>Add information layer to give detailed information<br>on your investment</li>',
            investmentHeading2: 'Pre-Construction',
            investmentDesc2: '<li>Reduce the number of iterations and get better feedback on the designs with 360° live conferencing</li><li>Use live annotations and commenting to highlight areas for amendments</li><li>Use 360° renderings to better visualize the function of the designed property</li>',
            investmentHeading3: 'Construction',
            investmentDesc3: '<li>Eliminate communication gaps by sharing 360° documentation to multiple teams</li><li>Add related files - space plans, and 2D archival pictures to keep everything in one place</li><li>Perform quick and safe H&S presentations without while the construction takes place</li>',
            investmentHeading4: 'Sales & Marketing',
            investmentDesc4: '<li>Create branded 360°/VR apps. Add 360° to\xa0your existing marketing channels to\xa0increase attractiveness\xa0&\xa0engagement</li><li> Sync with VR goggles with large screens and steal the show during trade shows</li><li>Use 360° Remote Viewing to show property online and invite convinced buyers to your office</li>',
            investmentHeading5: 'Delivery',
            investmentDesc5: '<li>Use 360° Photography to complete Delivery and Acceptance protocols</li>',
            investmentHeading: 'Designed to fit on every stage of a property investment.',
            metaDescription: 'Zdobądź tylko najbardziej zainteresowanych klientów. Oszczędź czas podczas wstępnego prezentowania nieruchomości. Pozwól klientowi samodzielnie obejrzeć mieszkanie',
            metaKeywords: 'Real estate sale, rent',
            metaTitle: 'Real Estate - Evryplace',
          },
          trainings: {
            metaTitle: 'Trainings',
            heading: 'Virtual reality training - Evryplace',
            subheading1: 'Evryplace Trainings will help you develop your workers skills using virtual reality technology through training programs which until now were never so affordable and immersive.',
            subheading2: 'Check out if VR training is the right employee training for your company.',
            corporateUse: 'Corporate use of virtual reality training is the future.',
            corporateUseDesc1: 'To enable companies <b>to train workers safely</b>, take training and development to new heights and create a safe and engaging learning environment, <b>we created Evryplace</b> - intuitive virtual <b>training software</b> for Corporate and Industry use.',
            corporateUseDesc2: 'Training made with Evryplace can be <b>custom-made to fit every employers’ work environment</b>. 360° and VR are perfect for industrial spaces and allow workers to learn <b>much faster and more efficiently</b> than any other kind of training.',
            corporateUseDesc3: 'Use VR to create <b>scalable</b> training with <b>great engagement</b> and the bonus of <b>spatial orientation</b> - so yes, VR training program is the solution to your real-world needs.',
            forWhoHeading1: 'How to make VR training?',
            forWhoBox1heading1: 'Combine 360° photos and videos into training steps.',
            forWhoBox1heading2: 'Share with your trainees',
            forWhoBox1description1: 'Take <b>360° photos</b> or <b>360° videos</b> of your workplace. Upload them to Evryplace, create the training flow and add the information on knowledge and skills you need your workers to have with <b>texts, graphics and videos</b> through interactive widgets.',
            forWhoBox1description2: 'Publish the ready training into the public cloud with password protection or your server (for on-premise clients). Share with your trainees however you want: <b>Oculus Quest 2 headsets, desktop or any mobile device</b>.*',
            forWhoBox1description3: '*Or any headset using WebXR - Oculus Quest is just one of many examples.',
            howMuch: 'How much does it cost?',
            howMuchSubhead1: 'More affordable than ever',
            howMuchDesc1: 'Virtual training <b>isn\'t as expensive as it used to be</b>. Simulations made with 360° media like photos and videos <b>cost a fraction of the virtual world made with 3D graphics</b> engines like Unity or Unreal.',
            howMuchSubhead2: 'You just need two things',
            howMuchDesc2: 'Be it a factory, a laboratory or an office building. With Evryplace all you need to start is a 360° camera or a hired professional to capture <b>the 360° footage</b> and <b>Evryplace subscription</b>.',
            pricing: 'See our pricing',
            benefitsHeading: 'Does virtual training work?',
            benefitsBox3description1: '40% of the v-learners saw an <b>improvement in confidence</b> compared to classroom learners and 35% improvement over e-learners to act on what they learned after training in VR.',
            benefitsBox3description2: 'V-learners <b>completed training 4 times faster</b> than classroom training.',
            benefitsBox3description3: 'V-learners felt <b>3.75 times more emotionally connected</b> to the content than classroom learners and 2.3 times more connected than e-learners.',
            benefitsBox3description4: 'V-learners were <b>4 times more focused</b> during training than their e-learning peers and 1.5 times more focused than their classroom colleagues.',
            benefitsBox3description5: '*Seeing is believing: How virtual reality and augmented reality will transform business and the economy',
            findOutHeading: 'Find out how 360° trainings can help your business',
            investmentHeading: 'What kind of trainings can be made in VR?',
            investmentHeading1: 'Job training',
            investmentHeading2: 'Onboarding',
            investmentHeading3: 'Section of assembly line',
            investmentHeading4: 'Preparing for work',
            investmentHeading5: 'Safety trainings',
            investmentHeading6: 'Tutorials',
            investmentDesc1: 'Show your workers how to perform their tasks correctly and minimize the amount of mistakes through an engaging virtual journey. VR is a <b>perfect medium for teaching both hard and soft skills</b>.',
            investmentDesc12: 'Evryplace gets you covered on all your employees’ training needs. <b>The key to a successful company is it\'s people</b> and learning and development should be constant to ensure the highest level of professionalism among your team.',
            investmentDesc2: 'Integrate new employees with a company and its culture, as well as getting a new hire the <b>tools and information needed to become a productive member</b> of the team.',
            investmentDesc22: 'Especially effective for corporate training where once created VR training experience <b>can be used multiple times</b> without any extra costs.',
            investmentDesc3: 'Vivid illustration with all the details of the assembly line exposed with a <b>clear narrative through a vr headset</b>.',
            investmentDesc32: 'Use the fact that virtual training can record and show every aspect and state of the assembly line without having it to be stopped more than one time. <b>Spatial orientation</b> during training achieved in VR is <b>quick, easy and extremely efficient</b> with Evryplace.',
            investmentDesc4: 'Efficiently teach your employees skills in areas of your interest in <b>virtual education-based environment</b> before they have <b>hands-on experience</b> in your company.',
            investmentDesc42: 'Utilize the technology to <b>leverage your spendings on preparation</b> of your employees compared to traditional training.',
            investmentDesc5: 'Provide your employees with an extensive range of information on <b>how to avoid hazards</b> which might otherwise impact your production.',
            investmentDesc52: 'More and more companies use virtual training solutions as the paradigm <b>shifts away from one on one</b> and <b>in-classroom teaching</b> on health and safety, which are and always be relevant. <b>Safety hazards cost money</b> and Evryplace will let you avoid them.',
            investmentDesc6: 'Teach your employees in an interactive and specific way by walking them through a <b>process in realistic environment</b>. Harness the power of immersive learning to your advantage and <b>get an edge over the competition</b>. VR experience is <b>undoubtedly the future of all kinds of job tutorials</b>.',
            investmentDesc62: 'Traditional training methods are becoming obsolete as they do not engage workers on high level and certainly do not help your employer branding be associated with being modern.',
          },
          presentation: {
            shareCoviewingNotification: 'Email invite has been sent.',
            viewingModal: {
              title: 'Welcome to Evryplace online co-viewing',
              titleBrand: 'Welcome to ',
              titleBrand2: ' online co-viewing',
              hash: 'COVIEWING HASH',
              start: 'START COVIEWING',
              link: 'Coviewing Link',
              emailPlaceholder: 'enter email',
              copyLink: 'COPY LINK',
              sendInvite: 'SEND EMAIL INVITE',
              email: 'E-mail'
            },
            connectionStatusModal: {
              audioAviable: 'Voice connection aviable.',
              audioUnaviable: 'Voice connection unaviable.',
              audioOff: 'Voice connection OFF.',
              audioOn: 'Voice connection ON.',
              connectAudio: 'Connect audio',
              connectedAwaiting: 'Connected. Waiting for your client to join.',
              conectedInfo1: 'Client',
              conectedInfo2: 'connected.',
              connecting: 'Connecting...',
              connectingInfo1: 'If you see this for too long, check your Internet firewall settings. ',
              connectingInfo2: 'Your firewall must allow for outgoing connection over port 3000',
              hash: 'COVIEWING HASH',
              refresh: 'Refresh presentation',
              start: 'Start',
              title: 'Informations about presentation',
              startVRMode: 'Start VR mode',
              stayInBrowser: 'Stay in browser mode',
              skip: 'Skip',
              welcome: 'Welcome to EVRYPLACE VR',
            },
          },
          privacy: {
            title: "Privacy",
            block1: "FREAM S.A. is an Administrator (Administrator) of personal data collected and processed as a part of Evryplace.com Service.  Administrator collects and processes the personal data in accordance with legal regulations, namely with the Personal Data Protection Act of 29 August 1997 (consolidated text: Journal of laws of 2002, no. 101 item 926, with subsequent amendments; herein after referred to as PDPA). The Administrator is aware of dangers resulting from personal data processing in the Internet therefore the Administrator hereby declares that they apply due diligence in order to provide an adequate level of User’s privacy and protection.  The applied tools have been selected to ensure adequate protection of processed personal data in accordance with legal regulations.",
            block2: {
              header: "Scope and application",
              li1: "The Privacy Policy (“Privacy Policy”) applies to all entities using all products, " +
              "software, data and services, including the eVRyPlace built-in presentation player " +
              "(„eVRyPlace Player”) which are provided to the user via the eVRyPlace website " +
              "or originating from it or provided via this service (herein referred to as " +
              "“Services”)."
            },
            block3: {
              header: "Data colleting",
              li1: "Information provided by users:",
              li2: "Evryplace collects information fed directly by the users at the registration " +
              "stage, when making changes therein, during contact with the customer " +
              "service and in any other forms of communication with Evryplace. The " +
              "information may include the name, e-mail address, phone number, age, " +
              "residence/ workplace/ business address, photos sent to the service, " +
              "specification of objects made available in the service and methods of " +
              "payment.",
              li3: "Evryplace collects data from transactions made in the Services, including " +
              "among others: type of requested service, date and time of completion, date " +
              "and time of placing the order, collected charges.",
              li4: "Whenever the User uses the Services, Evryplace collects information " +
              "relating to the login server, which may include the IP address, the date and " +
              "time of logging in, the functions used and sites visited, the app crashes and " +
              "other system activities, the web browser type and information used by the " +
              "User in the sites and services of third parties prior to using our Service.",
              li5: "Information collected from other sources.",
              li6: "Evryplace may obtain information from the employer using the services " +
              "concerning their employees delegated to work with Evryplace.",
            },
            block4: {
              header: "Evryplace uses the information:",
              li1: "to provide, maintain and improve the Services, including for example to process " +
              "payments, provide bank account details, deliver products and services, develop " +
              "new functions, provide customer help desk services, develop security measures, " +
              "confirm users’ identities and also to send product updates and messages relating " +
              "to administration matters.",
              li2: "to take internal actions including for example actions to prevent the Services " +
              "fraud or abuse; to troubleshoot the software and operation problems; to analyse, " +
              "test and research data; and also in order to monitor and analyse trends in the " +
              "scope of the use of the Internet and websites;",
              li3: "to relay information which Evryplace believes the User may be interested in, " +
              "including information of products, services, special offers, news and events " +
              "organised by Evryplace and other companies wherever it shall be acceptable and " +
              "in compliance with the applicable law; to process data in contests, bets or other " +
              "promotion actions and then on order to transfer the related prizes;",
              li4: "to personalise and improve the Services, including delivering and recommending " +
              "functions, contents, social media contacts, pieces of advice and advertisements.",
            },
            block5: {
              header: "Using Cookies",
              li1: "Evryplace may use cookies – namely small packets of data including information " +
              "recorded by the Operator’s server in the User’s computer. Using cookies enables " +
              "us to increase quality of our services by retaining User’s preferences. They are " +
              "also used in statistics development concerning the use of our services by Users. " +
              "However, using cookies does not enable extracting any personal or address data " +
              "from the User or any confidential information from the User’s computer. Using " +
              "cookies is not possible if the cookies enabling option in the User’s web browser " +
              "is switched off. In principle, it does not make it impossible to use the services, " +
              "however the User may experience some obstacles.",
            },
            block6: {
              header: "Purpose and methods of personal data processing",
              li1: "Perform obligations resulting from the agreement on rendering electronic " +
              "services, including accounting purposes,",
              li2: "Sending information bulletins,",
              li3: "Making the user-made presentations available to entities which the User has " +
              "selected as authorised.",
              li4: "Disclosing information to relevant authorities upon their request, if Evryplace " +
              "believes that the disclosure complies with current laws, regulations or legal " +
              "procedures or if the information is required thereby;",
              li5: "Evryplace may disclose relevant data to law enforcement officers, state " +
              "authorities or other third parties if Evryplace believes that the User’s actions " +
              "breach stipulations of their agreement with the User, or the Terms of Service, or " +
              "the company policy or that the actions jeopardise effective protection of rights, " +
              "property or safety of Fream company or other entities’;",
              li6: "in conjunction with or during negotiations concerning fusion, sale of the " +
              "company assets, consolidation or reorganisation, financing or acquisition or " +
              "detachment of a part of Evryplace or the entire company by a different entity;",
              li7: "Under any other circumstances, Evryplace shall notify the users and the users " +
              "shall grant their consent to share such information and",
              li8: "in the form of general or anonymous data, which renders the user identification " +
              "impossible in any rational manner.",
            },
            block7: {
              header: "Information sharing functions in social media",
              li1: "The Service may work with the information sharing function in social media or " +
              "other tools connected therewith, which let the user share information of their " +
              "actions in the Evryplace Service via other apps, browsers, media and the other " +
              "way around. Utilising these functions enables the User to share information with " +
              "friends or with the public depending on the settings which the User selected in " +
              "the social media profile. In order to obtain information concerning processing " +
              "data provided or shared by users via those sources, users need to familiarise " +
              "themselves with the relevant social media privacy policies.",
              li2: "When providing registration data to create a User’s Profile, the User gives the " +
              "consent to create searching parameters on the basis thereof. They are used in " +
              "analyses of User’s behaviours and collecting data about the User in order to " +
              "personalise content of their sites. In this scope, they are also used to personalise " +
              "advertisements and related links, messages content and other information " +
              "connected with using Evryplace services. Data is collected automatically. The " +
              "data is the basis for studies and analyses with the purpose to maintain and " +
              "continually improve the level of protection and services as well as to develop " +
              "new Evryplace products.",
              li3: "Contact data shall be used to send unannounced information to Users. Such " +
              "information may also be provided in the form of messages sent via the internal " +
              "mailing Service. The term information shall be understood here as all commercial (e.g. newsletter, etc.) and non-commercial (e.g. notifications of " +
              "functional changes, services, system-generated messages, etc.) communication. " +
              "Any advertising content related to the Operator’s and clients’ commercial " +
              "activities may be sent to Users only upon the User’s specific consent, pursuant to " +
              "art. 10 of the Act of 10 July 2002 on rendering electronic services (Journal of " +
              "laws of 2002, no. 144, item 1204, as amended).",
              li4: "The User may at any time opt out of receiving commercial information by " +
              "sending an e-mail to the following address: optout@evryplace.com. The request to be " +
              "excluded from personal data processing shall be applied immediately.",
            },
            block8: {
              header: "Change of personal data",
              li1: "The User has the right to change their own personal data, update it, correct any " +
              "mistakes in the information and remove it with an exception in the case when the " +
              "data retaining is required by law or if the data is collected automatically – in such " +
              "cases deleting data or changes thereto is not possible.",
              li2: "Changing or deleting data is possible after logging into the Service or providing a " +
              "written request in this scope.",
              li3: "In the case of failure to update personal data after it was changed, the Operator " +
              "may cease providing services/ suspend the User Account / block the User " +
              "Account until the data is updated.",
              li4: "The Operator may refuse to remove the User data if the User had failed to settle " +
              "their payments with the Operator or in the case if there is a suspicion that the " +
              "User may have breached the company Bylaws or legal requirements and the " +
              "information may be useful in establishing the User’s scope of responsibility.",
            },
            block9: {
              header: "Exclusion of liability",
              li1: "The privacy policy does not apply to entities and persons whose personal data is " +
              "available generally on the service sites.",
              li2: "The Operator shall not be liable for data provided by the Users.",
            },
          },
          server: {
            'ACCOUNT_NOT_ACTIVE': 'To login activate your account. The activation email has been sent. Please check your mailbox.',
            'RESET_PASSWORD_EXPIRED': 'Time period expired. Links are valid for 24h.',
            'RESET_PASSWORD_ERROR': 'Link expired or already used.',
            'DEFAULT': 'Error. Please try again.',
            'WRONG_EMAIL_OR_PASSWORD': "Wrong email or password",
            'SHOPPRESENTATION_ALREADY_EXISTS': 'Presentation bound with given PSD and with given date already exists.'
          },
          supportModal: {
            title: 'How can we help?'
          },
          presentationOverlay: {
            tooltip: 'VR view is avaliable only for published presentations.'
          },
          madeByMe: {
            page: {
              title: 'My virtual tours',
              categories: 'Categories',
              categoriesUngrouped: 'Categories without group',
              search: 'Search...',
              sortBy: 'Sort by',
              noSearchResults: 'We can’t find any presentation matching this search',
            },
            emailModal: {
              messageSend: 'Send successfully',
              messageError: 'Error',
              sendPresentation: 'Send presentation',
              shareOnFacebook: 'Share on facebook',
              addressEmail: 'Address email',
              content: 'Your message',
              send: 'Send',
              invite: 'You have been invited to view a presentation',
              mail1: 'from',
              mail2: 'has shared the presentation: "',
              mail3: '" in Evryplace.',
            },
            embedModal: {
              sharePresentation: 'Share virtual tour',
              text1: 'Modern real estate marketplaces support 360° virtual tours. Copy the Link below and insert it into the portal you are using to add your virtual tour.',
              text2: 'If you have access to your websites source code - copy the code below and insert it into HTML code to embed the virtual tour on your website.',
              copyLink: 'Copy link',
              width: 'Width',
              height: 'Height',
              noStartScreen: 'No start screen',
            },
            filter: {
              publicationDateAsc: 'Oldest first',
              publicationDateDesc: 'Newest first',
              nameAsc: 'Name a-z',
              nameDesc: 'Name z-a',
              viewCountDesc: 'View count descending',
              viewCountAsc: 'View count ascending',
            },
            presentation: {
              edit: 'Edit presentation',
              password: 'Password',
              protected: 'Protected',
              public: 'Public',
              sendEmail: 'Send email',
              share: 'Share',
              coviewing: 'Coviewing',
            },
            emptyState: {
              empty: 'No tours on your account',
              create: 'Create your first tour'
            }
          },
        },
      },
      pl: {
        translations: {
          common: {
            accept: 'Akceptuję',
            yes: 'tak',
            no: 'nie',
            currency: ' zł',
            save: 'Zapisz',
            or: 'lub',
            buy: 'KUP',
            and: 'oraz',
            content: 'treść',
            next: 'dalej',
            close: 'Zamknij',
            join: 'dołącz',
            pickDay: 'Wybierz dzień',
            send: 'Wyślij',
            search: 'Szukaj...',
            copied: 'Skopiowano',
            processing: 'przetwarzanie...',
            signOut: 'Wyloguj',
            showMore: 'Więcej',
            tryItNow: 'Załóż darmowe konto',
            createPresentation: 'Stwórz prezentację',
            tryItForFree: 'Try it for free',
            showPsd: "Pokaż prezentacje bez PSD",
            showNotPsd: "Pokaż prezentacje z PSD",
            evryplaceVirtualTour: "Wirtualny spacer Evryplace",
          },
          cookieMsg: 'Ta witryna wykorzystuje pliki cookies do przechowywania informacji na Twoim komputerze. Pliki cookies stosujemy w celu świadczenia usług na najwyższym poziomie, w tym w sposób dostosowany do indywidualnych potrzeb. Korzystanie z witryny bez zmiany ustawień dotyczących cookies oznacza, że będą one zamieszczane w Twoim urządzeniu końcowym. W każdym momencie możesz dokonać zmiany ustawień dotyczących cookies. Więcej szczegółów w naszej Polityce Prywatności.',
          navBar: {
            product: 'Produkt',
            howToStart: 'Jak zacząć',
            benefits: 'Korzyści',
            functions: 'Funkcje',
            business: 'Biznes',
            blog: 'Blog',
            pricing: 'Cennik',
            explore: 'Explore',
            dashboard: 'Mój Panel',
            register: 'Zarejestruj się',
            login: 'Zaloguj się',
            theta: 'theta360',
            editPresentation: 'Edytuj prezentację',
            profile: 'Ustawienia',
            payments: 'Płatności',
            realEstate: 'Nieruchomości',
            thetaLink: "Theta",
            changePSD: 'Zmień PSD',
            myPresentations: 'Moje Prezentacje',
            startCoviewing: 'Start Co-viewing',
            resendInvite: 'Wyślij Zaproszenie',
            trainings: 'Szkolenia',
          },
          registration: {
            header: 'Rejestracja',
            socialLabel: 'Zarejestruj się przy użyciu:',
            registerBtn: 'Załóż darmowe konto',
            termsNotification: 'Tworząc\xa0konto, zgadzasz\xa0się\xa0na ',
            termsLink: 'Warunki\xa0Użytkowania',
            privacyLink: 'Politykę\xa0Prywatności',
            emailPH: 'Adres e-mail',
            passwordPH: 'Hasło',
            passwordConfirmPH: 'Potwierdź hasło',
            displayNamePH: 'Wyświetlane imię',
            successMsg: 'Rejestracja zakończona sukcesem! Na podany adres e-mail wysłaliśmy link do aktywacji konta.',
            notification: 'Link aktywacyjny został wysłany na mail. Aktywuj konto by móc korzystać z EVRYPLACE.',
            google: 'Zarejestruj się z Google',
            linkedin: 'Zarejestruj się z LinkedIn',
            facebook: 'Zarejestruj się z Facebook',
            emailAlreadyRegistered: 'Podany email został już zarejestrowany.',
          },
          login: {
            header: 'Logowanie',
            orLabel: 'Lub zaloguj się z:',
            emailLabel: 'adresem e-mail',
            loginBtn: 'Zaloguj się',
            emailPH: 'Adres e-mail',
            passwordPH: 'Hasło',
            successMsg: 'Zalogowany!',
            cannotLogin: 'Nie pamiętam hasła',
            google: 'Zaloguj się z Google',
            linkedin: 'Zaloguj się z LinkedIn',
            facebook: 'Zaloguj się z Facebook',
            createAccount: 'Chcesz założyć konto?',
            registration: 'Rejestracja',
          },
          resetPassword: {
            header: 'Resetowanie hasła',
            subheader: 'Wpisz\xa0adres\xa0e-mail, na\xa0który wyślemy do\xa0Ciebie wiadomość, a\xa0następnie postępuj\xa0zgodnie z\xa0podanymi w\xa0niej\xa0wskazówkami.',
            emailPH: 'Adres e-mail',
            sendBtn: 'WYŚLIJ',
            successMsg: 'Jeżeli podany email istnieje, wiadomość została wysłana.'
          },
          setPassword: {
            header: 'Wpisz nowe hasło',
            password: 'Hasło',
            passwordConfirmation: 'Potwierdź hasło',
            successMsg: 'Nowe hasło zostało wysłane.',
            send: 'WYŚLIJ'
          },
          footer: {
            //evryplace
            evryplace: 'EVRYPLACE',
            trainings: 'Szkolenia',
            product: 'Produkt',
            functions: 'Funkcje',
            pricing: 'Cennik',
            realEstate: 'Nieruchomości',
            tourism: 'Turystyka i Hotelarstwo',
            signIn: 'Zaloguj się',
            copy: '© 2018 - EVRYPLACE',
            terms: 'Warunki użytkowania',
            privacy: 'Polityka Prywatności',
            //need help?
            needHelp: 'Potrzebujesz pomocy?',
            helpCenter: 'Help Center',
            faq: 'FAQ',
            knowledgeBase: 'Baza wiedzy',
            support: 'Support',
            //more
            more: 'Więcej',
            explore: 'Explore',
            portfolio: 'Portfolio',
            blog: 'EVRYPLACE Blog',
            newsletter: 'Newsletter',
            facebook: 'Facebook',
            linkedIn: 'LinkedIn',
            gototop: 'Wróć na górę',
            title1: 'Program do Wirtualnego Spaceru',
            title2: 'Tworzenie Wirtualnych Spacerów - Korzyści',
            title3: 'Wirtualne Prezentacje - Funkcje',
            title4: 'Wirtualny Spacer - Interaktywne prezentacje',
            description1: 'Czym jest Evryplace? To program do wirtualnego spaceru, którego potrzebujesz. Stworzysz w nim od podstaw każdy wirtualny spacer, który później możesz w ciągu kilku sekund edytować dowolnie według zmian, które uznasz za stosowne.',
            description2: 'Program Evryplace jest narzędziem, do którego dostęp uzyskujesz przy użyciu dowolnej przeglądarki internetowej (Chrome, Firefox, Opera, Edge i każdej innej) w każdym miejscu na świecie - bez konieczności instalacji. Wszystkie stworzone spacery są przechowywane bezpiecznie na Twoim koncie, w związku z czym możesz zalogować się z dowolnego komputera stacjonarnego, czy też laptopa i pracować nad swoim prezentacjami, nie martwiąc się o to, na którym urządzeniu stworzyłeś spacer po raz pierwszy.',
            description3: 'Dzięki programowi do wirtualnych spacerów Evryplace połączysz zdjęcia i wideo 360° oraz naniesiesz bogatą warstwę informacyjną w postaci grafik, płaskich zdjęć oraz filmów i tekstu. Wszystko przy pomocy kilku kliknięć.',
            description4: 'Tworzenie wirtualnych spacerów oznacza wiele korzyści dla Ciebie i Twojego klienta. Jest to innowacyjna forma prezentowania przestrzeni, pozwalająca na lepsze zorientowanie się i w prezentowanej nieruchomości.',
            description5: 'Pokażesz w ten sposób swój profesjonalizm, a z racji tego, że przy pomocy Evryplace wirtualny spacer przygotujesz samodzielnie od początku, aż do udostępnienia i oprowadzenia po nim klienta, oszczędzisz czas i pieniądze, które w innym wypadku musiałbyś wydać na korzystanie z czyichś usług. Ponadto, zarówno Ty jak i Twój klient, możecie odwiedzić wszystkie Twoje nieruchomości bez wychodzenia z domu.',
            description6: 'Wirtualne prezentacje w Evryplace umożliwiają tworzenie scen ze zdjęć i filmów 360° oraz tworzenie przejść między nimi w celu stworzenia spaceru. Na stworzone w ten sposób prezentacje w szybki sposób naniesiesz tekst, zdjęcia, filmy, linki i dźwięk.',
            description7: 'Gotową prezentację opublikujesz w Internecie, udostępnisz na komputery, telefony i tablety przy pomocy jednego linku oraz przeprowadzisz wirtualny spacer z klientem w trybie Co-Viewing, czyli spotkaniu online w wirtualnym spacerze z pełnym wachlarzem narzędzi ułatwiającym spotkanie.',
            description8: 'W Evryplace stworzysz każdy wirtualny spacer, szybko i w prosty sposób - dostosowany do Twoich potrzeb. Kreator Evryplace został stworzony w taki sposób, żeby był intuicyjny dla Ciebie już od pierwszego użycia. Evryplace pomimo swojej prostoty i intuicyjności posiada wszystkie funkcje, których potrzebujesz, aby Twoje interaktywne prezentacje zachwyciły odbiorców. Zaprezentuj przestrzenie, miejsca, wycieczki, wydarzenia i szkolenia w VR przy użyciu gogli - wirtualna rzeczywistość nigdy nie była tak łatwo dostępna.',
          },
          explore: {
            picked: 'Wybrane',
            popular: 'Popularne',
            newest: 'Najnowsze',
            searchPlaceholder: 'Wpisz szukaną frazę',
            button: 'Zobacz prezentację',
            madeByMe: 'Moje prezentacje',
          },
          home: {
            accountDeleteInfo: "Konto Evryplace usunięto pomyślnie",
            headerTitle: "Miejsca\xa0i\xa0przestrzenie ożywają\xa0na\xa0nowo.",
            headerSubtitle: "Innowacyjna sprzedaż nieruchomości, której oczekują Twoi klienci.",
            ribbon: "Zwycięzca VR Awards 2016",
            ribbonsub: "w kategorii VR for Business",
            videoTitle: "Zobacz co potrafi EVRYPLACE!",
            videoDescription: "Poznaj intuicyjne narzędzie, dzięki któremu w szybki i łatwy sposób wyróżnisz się na rynku nieruchomości. Przekonaj się ile możesz zyskać korzystając z naszego edytora. Buduj wirtualne spacery, prezentuj wnętrza, zyskuj czas i umawiaj spotkania online.",
            videoUrl: 'https://www.youtube.com/embed/q9khqJNgsy0?rel=0&amp;controls=0&amp;showinfo=0',
            carouselItemsOne: "Przemysław Latus,\xa0RICOH THETA Sales\xa0Manager RICOH Polska",
            carouselContentOne: "Evryplace w bardzo interesujący sposób wykorzystuje potencjał technologii 360°. Dzięki tej platformie i bez konieczności wysokich nakładów użytkownik może przygotować profesjonalną prezentację stworzoną ze zdjęć sferycznych. Biorąc pod uwagę rosnące zainteresowanie kamerami i aparatami 360° Evryplace ma szansę na szybki rozwój i dotarcie do szerokiej rzeszy użytkowników.",
            carouselItemsTwo: "SIEMACHA Spot Magnolia Park",
            carouselContentTwo: "Jesteśmy bardzo zadowoleni ze współpracy z EVRYPLACE. Wirtualny spacer, niczym teleport, pozwala przenieść się osobom po drugiej stronie ekranu, do siemachowej rzeczywistości. Jednym kliknięciem, możemy zajrzeć do poszczególnych pracowni, a opis zajęć oddaje ich klimat. To świetny i szybki sposób na promocję placówki i zachęcenie potencjalnych wychowanków do odwiedzenia nas osobiście.",
            carouselItemsThree: "Michał Ostromęcki, MISHA PHOTO",
            carouselContentThree: "I've been using EVRYPLACE since 2016. Things which made them stand out from the competition, were high-end features like full support for stereoscopic imagery or straightforward multi-platform delivery by a smart link to a client. You get addicted to how fast one can scramble 3D-virtual tour, all drag&drop with easy customization. Evrplace has grown massively over a last year, it's flawless mature product which I don't hesitate to recommend to my clients.",
            virtualTourTitle: "Zbudujemy\xa0dla\xa0Ciebie </br> wirtualny\xa0spacer!",
            virtualTourText: "Pomożemy\xa0znaleźć Ci\xa0fotografa, pozyskać odpowiednie materiały albo\xa0zaaranżujemy sesję i\xa0wykonamy wirtualny spacer według Twoich\xa0oczekiwań.",
            virtualTourBtn: "SPRAWDŹ",
            testimoialsHead: "Referencje",
            testimonialsSubHead: "Nasi klienci o EVRYPLACE:",
            createAccountBtn: "Załóż konto",
            usedBy: "Nasi klienci:",
            tagline: "*rejestracja w 3 minuty, bez użycia karty kredytowej",
            productHeading: "Szybkie i intuicyjne narzędzie do\xa0prezentacji\xa0i\xa0sprzedaży przestrzeni online.",
            editorHeading: "Edytuj w 360°",
            editor: "Edytor",
            editorParagraph: "Intuicyjny, dostępny z poziomu przeglądarki edytor, dzięki któremu połączysz zdjęcia 360° i wizualizacje 3D, zamieniając je w spacer po mieszkaniu. Możesz też wzbogacić spacer o dodatkową warstwę interaktywną, dodając grafikę, zdjęcia 2D, czy plany aranżacji.",
            playerHeading: "Publikuj online",
            player: "Player",
            playerParagraph: "Platforma online to możliwość nieograniczonego przeglądania opublikowanych stworzonych prezentacji. Wirtualne spacery pod postacią generowanego linka obejrzysz zarówno na komputerze, urządzeniu mobilnym, jak i w goglach VR.",
            coviewHeading: "Pokazuj na żywo",
            coview: "Co-viewing",
            coviewParagraph: "To współdzielenie i wspólne przeglądanie treści zawartych w prezentacji. Klienci podczas samodzielnego oglądania prezentacji będą mogli połączyć się z Twoim działem sprzedaży i płynnie przejść do omawiania oferty online.",
            brandingHeading: "Dodaj branding",
            branding: "Branding",
            brandingParagraph: "Dodaj do prezentacji identyfikację twojej firmy lub logo inwestycji nieruchomościowej. Publikuj stworzone prezentacje na dedykowanej stronie organizacji lub we własnej aplikacji mobilnej.",
            benefits: "Korzyści",
            benefitsSubhead: "Rozwiązanie przystosowane do Twojego procesu sprzedaży.",
            benefitsBox1heading: "Odmień swój proces sprzedaży",
            benefitsBox1description: "Daj klientom możliwość obejrzenia prezentacji niezależnie od miejsca, lokacji mieszkania, czy pory dnia. Oszczędź czas i pieniądze - pomóż klientom dokonać właściwego wyboru na wcześniejszym etapie sprzedaży.",
            benefitsBox2heading: "Zdobądź uwagę i zaufanie klientów",
            benefitsBox2description: "Przedstaw klientom miejsca i przestrzenie w całej okazałości. Pokaż szczegóły i detale Twoich nieruchomości podczas wirtualnego spaceru, tak jakbyście naprawde tam byli.",
            benefitsBox3heading: "Oprowadź klientów po odległych lokacjach",
            benefitsBox3description: "Przenieś swojego odbiorcę w dowolne miejsce, niezależnie od tego gdzie się teraz znajdujecie. Przełam barierę czasu, dostępności i odległości od miejsca, które chcesz pokazać klientowi.",
            ctaHeading: "Wprowadź innowacyjną sprzedaż nieruchomości, której oczekują Twoi klienci.",
            functions: "Funkcje",
            featuresItem1Heading: "Prosty edytor wycieczek 360°",
            featuresItem1Content: "Połącz zdjęcia 360° tworząc w szybki sposób wirtualną, nowoczesną prezentację miejsca lub przestrzeni.",
            featuresItem2Heading: "Widżety interaktywne",
            featuresItem2Content: "Dodaj warstwę informacyjną i interaktywną. Zaznacz szczegóły i umieść w prezentacji niezbędne treści pod postacią widżetów.",
            featuresItem3Heading: "Branding prezentacji",
            featuresItem3Content: "Personalizuj prezentację, tak by odpowiadała wizerunkowi Twojej firmy. Dodaj logo, elementy identyfikacji, a także dane kontaktowe.",
            featuresItem4Heading: "Co-Viewing",
            featuresItem4Content: "Oglądaj prezentację swojej oferty razem z klientem, dzięki opcji współdzielenia prezentacji w czasie rzeczywistym.",
            featuresItem5Heading: "Tryb auto-prezentacji",
            featuresItem5Content: "Wykorzystaj tryb autoprezentacji - odtwórz prezentację tak, aby przejścia między scenami zmieniały się samodzielnie jedna po drugiej.",
            featuresItem6Heading: "Prosta integracja (MLS)",
            featuresItem6Content: "Dzięki prostemu API dodasz prezentacje 360° do Twoich obecnych kanałów sprzedaży.",
            howCanHelpHeading: "Jak nasz produkt może Ci pomóc…",
            howCanHelpBox1Title: "w pracy?",
            howCanHelpBox1ListItem1: "Dodaj proste rozwiązanie, pozwalające udoskonalić proces przygotowania idealnej oferty dla Twoich klientów.",
            howCanHelpBox1ListItem2: "Wprowadzaj szybkie zmiany w prezentacjach bez uzależnienia ich od zewnętrznych zespołów.",
            howCanHelpBox1ListItem3: "Przyłącz cały zespół do projektu, pozwalając na konsultację na wczesnych etapach prezentacji.",
            howCanHelpBox1ListItem4: "W pełni wykorzystaj komplet materiałów promocyjnych w jednym miejscu - dodaj do prezentacji zdjęcia 2D, filmy czy dokumenty.",
            howCanHelpBox2Title: "w firmie?",
            howCanHelpBox2ListItem1: "Dodaj zespół sprzedażowy jako dedykowany kontakt do przygotowanych prezentacji.",
            howCanHelpBox2ListItem2: "Wyjdź naprzeciw trendom rynkowym oferując klientom nowoczesną formę prezentacji mieszkań.",
            howCanHelpBox2ListItem3: "Pokaż swoją firmę jako lidera rynku, korzystającego z innowacyjnych rozwiązań.",
            howCanHelpBox2ListItem4: "Zlikwiduj ograniczenia geograficzne - omawiaj ofertę z klientem online, tak jakbyś oprowadzał go fizycznie po sprzedawanej przestrzeni.",
            howCanHelpBox3Title: "z klientami?",
            howCanHelpBox3ListItem1: "Dzięki rozwiązaniu EVRYPLACE Twoi klienci mają pełną swobodę w oglądaniu oferowanej przestrzeni, na każdym urządzeniu, o każdej porze i w każdym miejscu.",
            howCanHelpBox3ListItem2: "Przygotuj atrakcyjną prezentację VR i pozwól klientom wracać do niej i dzielić się nią ze znajomymi.",
            howCanHelpBox3ListItem3: "Zaoferuj klientom możliwość swobodnego skontaktowania się z działem sprzedaży, z poziomu oglądanej  prezentacji 360°.",
            howCanHelp30days: "Wypróbuj EVRYPLACE przez 30 dni za darmo.",
            howToStart: "Jak zacząć",
            howToStartBox1Text1: "1. Załóż konto w Evryplace",
            howToStartBox1Text2: "Zarejestruj się",
            howToStartBox1Text3: " i korzystaj ze wszystkich funkcji Evryplace za darmo przez cały miesiąc. Po zakończeniu darmowego okresu, ",
            howToStartBox1Text4: "wybierz plan subskrypcyjny",
            howToStartBox1Text5: " lub korzystaj bez opłat z ",
            howToStartBox1Text6: "planu FREE",
            howToStartBox1Text7: "",
            howToStartBox2Text1: "2. Wgraj zdjęcia 360°",
            howToStartBox2Text2: "Zdjęcia wykonaj samodzielnie aparatem 360° lub telefonem. Możesz też zlecić to fotografowi albo skorzystać z usług biur projektowych w przypadku wizualizacji przestrzeni. Gotowe pliki wgraj do edytora i twórz z nich sceny Twojej wirtualnej prezentacji.",
            howToStartBox3Text1: "3. Edytuj i udostępnij spacer",
            howToStartBox3Text2: "Umieść w swojej prezentacji tekst, grafiki, pliki audio, wideo czy też adresy www. Udostępnij spacer wysyłając link do niego, publikując go na swojej stronie lub wysyłając kod QR.",
            howToStartInfoText1: "Zachęcamy do zapoznania się z ",
            howToStartInfoText2: "Bazą Wiedzy Evryplace",
            howToStartInfoText3: ", gdzie znajdziesz wskazówki dotyczące edycji spaceru oraz innych aspektów edytora.",
            mediaHeader: "Twoje prezentacje dostępne na wszystkich urządzeniach.",
            mediaSubhead1: "Sprawdź EVRYPLACE na urządzeniach mobilnych.",
            mediaSubhead2: "Pobierz aplikację na Android lub iOS i zobacz prezentacje w VR",
            slogan1: "biznes",
            slogan2: "sprzedaż",
            slogan3: "firmę",
            slogan4: "marketing",
            sloganHeaderPRE: "Rozwijaj",
            sloganHeaderPOST: "z EVRYPLACE.",
            metaDescription: 'Evryplace - Zaprezentuj każde miejsce w wirtualnej rzeczywistości. Twórz interaktywne prezentacje VR online i dostarczaj je swoim klientom. W minutach.',
            metaKeywords: 'wirtualny spacer, Wirtualne spacery, kreator, interaktwyne prezentacje, vr',
            metaTitle: 'Wirtualny Spacer - Tworzenie Interaktywnych Prezentacji - Evryplace',
          },
          pricing: {
            benefits: {
              text1: 'Tworzenie i publikację prezentacji 360° za pomocą intuicyjnego edytora online',
              text2: 'Import zdjęć bezpośrednio z aparatów Ricoh Theta przy pomocy aplikacji mobilnej Evryplace',
              text3: 'Przeglądanie prezentacji w przeglądarce internetowej lub aplikacjach mobilnych (iOS, Android)',
              text4: 'Udostępnianie prezentacji bezpośrednio przez link lub email oraz publikację na Evryplace Explore',
              text5: 'Umieszczanie prezentacji na własnej stronie internetowej (bez widocznego logotypu Evryplace)',
              text6: 'Zabezpieczenie udostępnionych prezentacji kodem dostępu',
              text7: 'Publikowanie na mobilnym VR',
              text8: 'Dostęp do artykułów w bazie wiedzy Evryplace',
              monthly: 'Miesięczna',
              annual: 'Roczna',
              evryPricing: 'Cennik Evryplace',
              evryBenefits: 'Z Evryplace zyskasz:'
            },
            businessOffer: {
              text1: 'Jesteś zainteresowany czymś więcej?',
              text2: 'Chcesz zintegrować rozwiązanie 360° ​​z obecną organizacją pracy w Twojej firmie?',
              text3: 'Zarządzanie prywatną chmurą z prezentacjami, dedykowane szkolenia i priorytetowe wsparcie.',
              text4: 'Skontaktuj się, aby omówić indywidualną cenę pakietu dla Ciebie.',
              text5: 'Abonamenty dla dużych firm i korporacji już od ',
              text6: '625',
              text7: ' netto / mies.',
              text7_1: ' netto / rok',
              text8: 'Brak limitu',
              text9: ' opublikowanych jednocześnie prezentacji',
              text10: 'Liczba użytkowników ',
              text11: 'do indywidualnej negocjacji',
              text12: 'Rozmiar wirtualnej przestrzeni na Twoje prezentacje do negocjacji',
              text13: '8k',
              text14: ' - maksymalna rozdzielczość wyświetlanych zdjęć 360° w odtwarzaczu Evryplace',
              text15: '50 MB',
              text16: ' - maksymalny rozmiar zdjęcia lub renderingu 360° ',
              text17: 'Oglądanie prezentacji w goglach VR',
              text17_1: '(wymaga Oculus GO, Quest lub innych gogli z technologią WebXR)',
              text18: 'Coviewing',
              text18_1: ' - prezentacje współdzielone',
              text19: 'Automatyczne prostowanie',
              text19_1: 'zdjęć z aparatów Ricoh Theta',
              text20: 'Możliwość dodania wideo oraz wideo 360° do prezentacji',
              text21: '200 MB',
              text21_1: ' -  maksymalny rozmiar wideo',
              text22: 'Teams',
              text22_1: ' - zarządzanie uprawnieniami użytkowników w Twojej organizacji',
              text23: 'Branding',
              text23_1: ' - spersonalizowane logo do każdej prezentacji, umieszczenie elementów brandingu do prezentacji oraz zaproszeń email',
              text24: 'Dedykowany support i szkolenia',
              text25: 'Dedykowana umowa SLA',
              text26: 'Możliwość wdrożenia w prywatnej chmurze',
              text26_1: ' oraz integracji z systemami klienta',
              text27: 'Skontaktuj się z nami',
              text28: 'Dopasujemy ofertę do potrzeb Twojej firmy.',
              text30: 'Prezentacje ',
              text30_1: 'chronione hasłem'
            },
            offers: {
              currency: ' netto / mies.',
              currency_2: ' netto / rok',
              noLimit: 'Brak limitu ',
              publishedPresentation: 'opublikowanych jednocześnie prezentacji ',
              user: ' użytkownik ',
              users: ' użytkowników ',
              virtualStorage: 'miejsca w chmurze na Twoje pliki multimedialne i prezentacje ',
              sixK: '6k ',
              maxDimension: ' - maksymalna rozdzielczość wyświetlanych zdjęć 360° w odtwarzaczu Evryplace',
              maxFileSize: '-  maksymalny rozmiar zdjęcia lub renderingu 360°',
              vrPresentation: 'Oglądanie prezentacji w goglach VR',
              vrPresentation2: ' (wymaga Oculus GO, Quest lub innych gogli z technologią WebXR)',
              coviewing: 'Coviewing',
              coviewing2: ' - prowadzenie zdalnych prezentacji',
              autoResize: 'Automatyczne poziomowanie',
              autoResize2: ' zdjęć z aparatów Ricoh Theta',
              videoAllowed: ' zdjęć z aparatów Ricoh Theta',
              videoSize: '200 MB',
              videoSize2: ' - maksymalny rozmiar wideo',
              tryForFree: 'Wypróbuj za darmo',
              allowVideoUpload: 'Wideo 360° i płaskie w prezentacjach',
              imageCorrector: 'Korektor zdjęć',
              noEvryLogo: 'Prezentacje bez logo Evryplace',
              video360: 'Dodawanie wideo 360°',
              protectedPresentation1: 'Prezentacje',
              protectedPresentation2: ' chronione hasłem',
              currencyDropdown: 'Waluta',
              advancedWidgetsEnabled: 'Zaawansowana warstwa informacyjna',
              advancedWidgetsEnabled2: ' w prezentacjach - Widgety Galerii'
            },
            currencyInfo: 'Możesz tu przeglądać wszystkie waluty, ale twoja waluta rozliczeniowa będzie ustalona na podstawie twojej rezydencji podatkowej',
            selectPaymentType: 'Wybierz rodzaj płatności',
            heading: 'Prosta i przejrzysta wycena',
            subheading: 'Plany pasujące do zespołów i firm na każdym etapie ich projektów 360°',
            monthly: 'Monthly billing',
            annual: 'Annual billing',
            perMonth: 'miesięcznie',
            changePlan: 'Zmień plan',
            button: 'Start free trial',
            arrow: 'Save up to 20%',
            askForPlan: 'Zapytaj o pakiet',
            currentPlan: 'Twój plan',
            featuresHeader: 'Z EVRYPLACE zyskujesz:',
            column1: {
              heading: 'Basic',
              button: 'Wypróbuj za darmo',
              description: 'Dla szukających szybkiego i łatwego sposobu do prezentowania swojego portfolio w 360°.',
              destiny: '1 użytkownik<br>1 GB na Twoje prezentacje',
              feature1: 'tworzenie i publikacja prezentacji 360° za pomocą intuicyjnego edytora online',
              feature2: 'przeglądanie prezentacji w przeglądarce internetowej lub aplikacjach mobilnych (iOS, Android)',
              feature3: 'udostępnianie prezentacji bezpośrednio przez link lub email',
              feature4: 'zabezpieczenie udostępnionych prezentacji kodem dostępu',
              feature5: 'przeprowadzanie współdzielonych prezentacji online',
              feature6: 'dostęp do artykułów w bazie wiedzy EVRYPLACE',
              feature7: 'umieszczanie prezentacji na własnej stronie internetowej (bez widocznego logotypu EVRYPLACE)',
              feature8: 'import zdjęć bezpośrednio z aparatów Ricoh Theta przy pomocy aplikacji mobilnej EVRYPLACE',
            },
            column2: {
              heading: 'Business',
              button: 'Wypróbuj za darmo',
              description: 'Dla agentów i deweloperów mieszkaniowym, stawiających na interakcje i sprzedaż online.',
              destiny: '5 użytkowników<br>10 GB pamięci',
              feature1: 'tworzenie i publikacja prezentacji 360° za pomocą intuicyjnego edytora online',
              feature2: 'przeglądanie prezentacji w przeglądarce internetowej lub aplikacjach mobilnych (iOS, Android)',
              feature3: 'udostępnianie prezentacji bezpośrednio przez link lub email',
              feature4: 'zabezpieczenie udostępnionych prezentacji kodem dostępu',
              feature5: 'przeprowadzanie współdzielonych prezentacji online',
              feature6: 'dostęp do artykułów w bazie wiedzy EVRYPLACE',
              feature7: 'umieszczanie prezentacji na własnej stronie internetowej (bez widocznego logotypu EVRYPLACE)',
              feature8: 'import zdjęć bezpośrednio z aparatów Ricoh Theta przy pomocy aplikacji mobilnej EVRYPLACE',
              feature9: 'zarządzanie użytkownikami w Twojej organizacji',
            },
            column3: {
              heading: 'Premium',
              button: 'Wypróbuj za darmo',
              description: 'Dla deweloperów dużych inwestycji, szukających nowoczesnego narzędzia marketingowego i sprzedażowego.',
              destiny: '10 użytkowników<br>50 GB pamięci',
              feature1: 'tworzenie i publikacja prezentacji 360° za pomocą intuicyjnego edytora online',
              feature2: 'przeglądanie prezentacji w przeglądarce internetowej lub aplikacjach mobilnych (iOS, Android)',
              feature3: 'udostępnianie prezentacji bezpośrednio przez link lub email',
              feature4: 'zabezpieczenie udostępnionych prezentacji kodem dostępu',
              feature5: 'przeprowadzanie współdzielonych prezentacji online',
              feature6: 'dostęp do artykułów w bazie wiedzy EVRYPLACE',
              feature7: 'umieszczanie prezentacji na własnej stronie internetowej (bez widocznego logotypu EVRYPLACE)',
              feature8: 'import zdjęć bezpośrednio z aparatów Ricoh Theta przy pomocy aplikacji mobilnej EVRYPLACE',
              feature9: 'zarządzanie użytkownikami w Twojej organizacji',
              feature10: 'spersonalizowane logo do każdej prezentacji',
              feature11: 'dodanie scen wideo 360° oraz widgetów video do Twoich prezentacji',
              feature12: 'oglądanie prezentacji w goglach VR (wymaga Oculus GO lub Samsung Gear VR)',
              feature13: 'umieszczenie elementów brandingu do prezentacji oraz zaproszeń email',
              feature14: 'możliwość eksportu prezentacji w postaci statycznych stron HTML',
              feature15: 'dedykowane wsparcie telefoniczne oraz email',
              feature16: 'możliwość wdrożenia w prywatnej chmurze oraz integracji z systemami klienta',
            },
            businessModelHeading: 'Inny model biznesowy?',
            businessModelSubHeading: 'Czy chcesz zintegrować rozwiązanie 360° ​​z obecną organizacją pracy w Twojej firmie? Zarządzanie prywatną chmurą z prezentacjami, dedykowane szkolenia i priorytetowe wsparcie. Skontaktuj się, aby omówić indywidualną cenę pakietu dla Ciebie.',
            businessModelButton: 'Skontaktuj się z nami',
            includedIn: 'Dodatkowo w każdym pakiecie EVRYPLACE:',
            includedInBox1: 'Pełna możliwość konfigurowania i wprowadzania zmian do prezentacji',
            includedInBox2: 'Wbudowana synchronizacja z kamerami 360° RICOH Theta',
            includedInBox3: 'Samodzielna strona internetowa dla każdej prezentacji',
            includedInBox4: 'Wsparcie dla wszystkich materiałów 2D oraz 360° media (audio, video)',
            includedInBox5: 'Publikacja prezentacji na stronie Explore EVRYPLACE',
            includedInBox6: 'Publikowanie na mobilnym VR',
            functionalitiesHeading: 'Dlaczego EVRYPLACE:',
            functionalitiesBox1: 'Dwa lata doświadczenia i doskonalenia najlepszego edytora 360° na rynku',
            functionalitiesBox2: 'Zaprojektowane przy współpracy z największymi partnerami z branży nieruchomości',
            functionalitiesBox3: 'Dopasowane do wielu kanałów sprzedaży',
            faqHeading: 'Najczęściej zadawane pytania',
            faqQuestion1: 'Lorem ipsum dolor sit amet, consectetuer ?',
            faqAnswer1: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo ',
            faqQuestion2: 'Lorem ipsum dolor sit amet, consectetuer ?',
            faqAnswer2: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo ',
            faqQuestion3: 'Lorem ipsum dolor sit amet, consectetuer ?',
            faqAnswer3: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo ',
            faqQuestion4: 'Lorem ipsum dolor sit amet, consectetuer ?',
            faqAnswer4: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo ',
            faqQuestion5: 'Lorem ipsum dolor sit amet, consectetuer ?',
            faqAnswer5: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo ',
            faqQuestion6: 'Lorem ipsum dolor sit amet, consectetuer ?',
            faqAnswer6: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo ',
            moreQuestionsHeading: 'Masz jeszcze jakieś pytania?',
            moreQuestionsButton1top: 'Skontaktuj się z nami',
            moreQuestionsButton1bottom: 'Jak możemy Ci pomóc?',
            moreQuestionsButton2top: 'Przejdź do FAQ',
            moreQuestionsButton2bottom: 'Najczęstsze pytania',
            metaDescription: 'Prosta i przejrzysta wycena. Plany pasujące do zespołów i firm na każdym etapie ich projektów 360°',
            metaKeywords: 'Cennik',
            metaTitle: 'Cennik - Evryplace',
          },
          contactModal: {
            title: 'Skontaktuj się z działem sprzedaży',
            subheader: 'Jesteś zainteresowany dedykowaną ofertą lub indywidualnym rozwiązaniem? Porozmawiajmy o tym.',
            emailPlaceholder: 'E-mail',
            messagePlaceholder: 'Treść wiadomości',
            submit: 'Wyślij wiadomość',
            usedCharacters: 'Ilość znaków: ',
            minCharacters: '(min. 20)',
          },
          coronaVirusInfoModal: {
            title:'Wirtualne prezentowanie mieszkań odpowiedzią na kryzys rynku nieruchomości wywołany epidemią koronawirusa.',
            body1:'W odpowiedzi na rosnący niepokój, EVRYPLACE - platforma przeznaczona do prezentowania przestrzeni za pomocą zdjęć sferycznych - uruchomiła funkcję "Zdalnej prezentacji nieruchomości"',
            body2:' Dzięki niej, zarówno agent nieruchomości, jak i klienci mogą uczestniczyć w prezentacji nieruchomości bez potrzeby spotykania się osobiście.',
            body3:'Wychodząc naprzeciw obecnej sytuacji zdecydowaliśmy się na udostępnienie funnkcjonalności zdalnej prezentacji we wszystkich planach taryfowych - bez dodatkowych opłat',
            buttonText:'Dowiedz się więcej',
          },
          confirmationDialog: {
            title: 'Informacja',
            message: 'E-mail został wysłany.'
          },
          confirmation: {
            title: 'Dziękujemy za wybranie Evryplace!',
            p1: 'Dziękujemy za potwierdzenie adresu e-mail. Twoje konto jest już aktywne.',
            p2: 'Spotkaj się ze specjalistą ds. obsługi klienta na krótkie wprowadzenie. Poprowadzi Cię przez pierwsze kroki w Evryplace i odpowie na Twoje pytania.',
            p3: 'Lub odkrywaj sam:',
            h1: 'Zarezerwuj wstęp',
            b1: 'Stwórz własny spacer',
            b2: 'Zobacz tutorial',
          },
          terms: {
            title: 'Warunki użytkowania',
            block1: {
              header: 'Stosunki pomiędzy użytkownikiem a EVRYPLACE',
              li1: 'Korzystanie z witryny internetowej EVRYPLACE („Witryna\n' +
                'Internetowa”) oraz wszelkich produktów,\n' +
                'oprogramowania, wgranych danych i usług, w tym wbudowanego\n' +
                'odtwarzacza prezentacji EVRYPLACE(„Odtwarzacz\n' +
                'EVRYPLACE”) dostarczonych użytkownikowi przez\n' +
                'EVRYPLACE na Witrynie Internetowej lub z niej pochodzących\n' +
                'lub za jej pośrednictwem (w niniejszym dokumencie zwanych\n' +
                'łącznie „Usługami”) podlega warunkom umowy pomiędzy\n' +
                'użytkownikiem a właścicielem produktu EVRYPLACE.\n' +
                '„EVRYPLACE” oznacza spółkę Fream S.A.',
              p: 'Właścicielem EVRYPLACE jest FREAM S.A. Powstańców\n' +
                'Śląskich 28-30 50-333 Wrocław, KRS 000 0395554 REGON\n' +
                '021551235 NIP 8982192139',
              li2: 'Umowa pomiędzy użytkownikiem a EVRYPLACE składa się z (A)\n' +
                'warunków przedstawionych w niniejszym dokumencie, (B)\n' +
                'Zasad Ochrony Prywatności stosowanych przez EVRYPLACE.',
              li3: 'Warunki stanowią prawnie wiążącą umowę między\n' +
                'użytkownikiem a EVRYPLACE dotyczącą korzystania przez\n' +
                'użytkownika z Usług. Użytkownik powinien uważnie się z\n' +
                'nimi zapoznać.',
              li4: 'Warunki stosuje się do wszystkich użytkowników Usług, w\n' +
                'tym użytkowników, którzy również zamieszczają Treści przy\n' +
                'użyciu Usług. „Treści” obejmują materiały tekstowe,\n' +
                'oprogramowanie, skrypty, grafikę, zdjęcia, dźwięk, muzykę,\n' +
                'nagrania wideo, materiały audiowizualne, elementy\n' +
                'interaktywne i inne materiały, które użytkownik może\n' +
                'przeglądać, do których może uzyskać dostęp lub które może\n' +
                'zamieścić przy użyciu Usług.'
            },
            block2: {
              header: 'Przyjęcie Warunków',
              li1: 'Użytkownik powinien przyjąć Warunki przed rozpoczęciem\n' +
                'korzystania z Usług. W przypadku ich nieprzyjęcia\n' +
                'użytkownik nie będzie mógł korzystać z Usług.',
              li2: 'Użytkownik może przyjąć Warunki poprzez rozpoczęcie\n' +
                'korzystania z Usług. Użytkownik przyjmuje do wiadomości i\n' +
                'uznaje, że od chwili rozpoczęcia korzystania z Usług\n' +
                'EVRYPLACE będzie traktował korzystanie z nich jako\n' +
                'przyjęcie Warunków.',
              li3: 'Użytkownik nie może korzystać z Usług i nie może przyjąć\n' +
                'Warunków, jeżeli (a) nie jest w wieku, który umożliwia\n' +
                'zawarcie z EVRYPLACE wiążącej umowy lub (b) jest osobą,\n' +
                'wobec której został wydany wiążący zakaz otrzymywania\n' +
                'Usług lub korzystania z nich, na podstawie przepisów prawa\n' +
                'kraju, w którym ma stałe miejsce zamieszkania lub z\n' +
                'którego uzyskuje dostęp do Usług lub z nich korzysta.',
              li4: 'Użytkownik powinien wydrukować lub zachować obowiązujący\n' +
                'lokalnie egzemplarz Warunków.',
            },
            block3: {
              header: 'Zmiany Warunków',
              li1: 'EVRYPLACE zastrzega sobie prawo dokonania zmian Warunków,\n' +
                'np. w celu uwzględnienia zmian w przepisach prawa lub\n' +
                'regulacjach bądź zmian funkcji oferowanych za\n' +
                'pośrednictwem Usług. Z tego powodu użytkownik powinien\n' +
                'regularnie sprawdzać, czy Warunki nie uległy zmianie.\n' +
                'Zmieniona wersja Warunków („Warunki Zmienione”)\n' +
                'zostanie udostępniona w ramach Usług (w przypadku\n' +
                'zmienionych warunków dodatkowych). Użytkownik, który nie\n' +
                'przyjmuje Warunków Zmienionych, jest zobowiązany\n' +
                'zaprzestać korzystania z Usług. Korzystanie przez\n' +
                'użytkownika z Usług po dniu umieszczenia Warunków\n' +
                'Zmienionych zostanie potraktowane jako akceptacja Warunków\n' +
                'Zmienionych.',
            },
            block4: {
              header: 'Ogólne ograniczenia w korzystaniu',
              li1: 'EVRYPLACE niniejszym zezwala użytkownikowi na dostęp do\n' +
                'Usług oraz korzystanie z nich, z zastrzeżeniem poniższych\n' +
                'wyraźnych warunków, a użytkownik uznaje, że niespełnienie\n' +
                'któregokolwiek z tych warunków będzie stanowiło z jego\n' +
                'strony naruszenie niniejszych Warunków:',
              subLi1: 'użytkownik zobowiązuje się nie dystrybuować żadnych\n' +
                'części Witryny Internetowej ani Usług, w tym między\n' +
                'innymi jakichkolwiek Treści na jakichkolwiek nośnikach\n' +
                'bez uzyskania uprzedniej pisemnej zgody EVRYPLACE,\n' +
                'chyba że EVRYPLACE udostępni środki do realizacji\n' +
                'takiej dystrybucji za pośrednictwem funkcji\n' +
                'oferowanych w ramach Usług (np. Odtwarzacza\n' +
                'EVRYPLACE);',
              subLi2: 'użytkownik zobowiązuje się nie zmieniać żadnej części\n' +
                'Witryny Internetowej ani Usług (w tym między innymi\n' +
                'Odtwarzacza EVRYPLACE i technologii z nim związanych);',
              subLi3: 'użytkownik zobowiązuje się nie uzyskiwać dostępu do\n' +
                'Treści przy pomocy technologii lub środków innych niż\n' +
                'strony umożliwiające odtwarzanie prezentacji na\n' +
                'Witrynie Internetowej, Odtwarzacz EVRYPLACE lub inne\n' +
                'środki przeznaczone w sposób wyraźny do tego celu\n' +
                'przez EVRYPLACE;',
              subLi4: 'użytkownik zobowiązuje się nie omijać, nie blokować\n' +
                'ani w inny sposób nie zakłócać działania (oraz nie\n' +
                'podejmować takich prób) elementów zabezpieczenia Usług\n' +
                'lub elementów, które',
              subLi4a: 'uniemożliwiają lub ograniczają korzystanie z\n' +
                'Treści, bądź ich kopiowanie lub',
              subLi4b: 'wprowadzają w życie ograniczenia w zakresie\n' +
                'korzystania z Usług lub treści dostępnych za\n' +
                'pośrednictwem Usług;',
              subLi5: 'użytkownik zobowiązuje się nie korzystać z Usług (w\n' +
                'tym Odtwarzacza EVRYPLACE) w celach komercyjnych\n' +
                'opisanych poniżej, bez uprzedniego uzyskania pisemnego\n' +
                'upoważnienia ze strony EVRYPLACE:',
              subLi5a: 'odsprzedaż dostępu do Usług;',
              subLi5b: 'odsprzedaż materiałów reklamowych, sponsoringowych\n' +
                'lub promocyjnych umieszczonych w Usługach oraz\n' +
                'Treści;',
              subLi5c: 'odsprzedaży materiałów reklamowych,\n' +
                'sponsoringowych lub promocyjnych umieszczonych na\n' +
                'jakiejkolwiek stronie blogu lub witryny\n' +
                'internetowej uaktywnionej przez reklamę, która\n' +
                'zawiera Treści dostarczone za pomocą Usług, chyba\n' +
                'że inny materiał uzyskany ze źródła innego niż\n' +
                'EVRYPLACE pojawia się na tej samej stronie i ma\n' +
                'wystarczającą wartość, aby stać się podstawą\n' +
                'takiej odsprzedaży;',
              subLi6: 'użytkownik zobowiązuje się nie używać ani nie\n' +
                'wprowadzać żadnego zautomatyzowanego systemu (w tym\n' +
                'między innymi robotów, automatów służących do\n' +
                'pobierania plików graficznych (spider) lub narzędzi\n' +
                'służących do przeglądania zawartości w czasie braku\n' +
                'dostępu do internetu (offline reader), które uzyskują\n' +
                'dostęp do Usług w taki sposób, że wysyłają więcej\n' +
                'zapytań do serwerów EVRYPLACE w danym okresie niż w\n' +
                'tym samym czasie mógłby wysłać człowiek przy\n' +
                'wykorzystaniu publicznie dostępnej standardowej (tj.\n' +
                'niezmodyfikowanej) przeglądarki;',
              subLi7: 'użytkownik zobowiązuje się nie zbierać żadnych danych\n' +
                'osobowych użytkowników Witryny Internetowej lub Usług\n' +
                '(i akceptuje, że będzie to obejmowało nazwy kont\n' +
                'EVRYPLACE);',
              subLi8: 'użytkownik zobowiązuje się nie pozyskiwać w celach\n' +
                'komercyjnych użytkowników Witryny Internetowej w\n' +
                'związku z ich Treściami; oraz',
              subLi9: 'użytkownik zobowiązuje się uzyskiwać dostęp do Treści\n' +
                'jedynie zgodnie z przeznaczeniem wynikającym z\n' +
                'normalnego zakresu funkcji Usług.',
              subLi10: 'użytkownik zobowiązuje się, iż nie będzie kopiować,\n' +
                'powielać, dystrybuować, przekazywać, nadawać,\n' +
                'wyświetlać, sprzedawać, licencjonować ani\n' +
                'wykorzystywać Treści w żaden inny sposób bez uzyskania\n' +
                'uprzedniej pisemnej zgody EVRYPLACE lub odpowiednich\n' +
                'licencjodawców Treści.',
              li2: 'Użytkownik zobowiązuje się, że w każdym czasie podczas\n' +
                'korzystania z Usług będzie przestrzegał wszystkich\n' +
                'pozostałych postanowień Warunków oraz Regulaminu\n' +
                'Społeczności EVRYPLACE.',
              li3: 'EVRYPLACE zezwala operatorom publicznie dostępnych\n' +
                'wyszukiwarek na korzystanie z automatów służących do\n' +
                'pobierania plików graficznych (spider) w celu kopiowania\n' +
                'materiałów z witryny wyłącznie w celu tworzenia\n' +
                'ogólnodostępnych, możliwych do przeszukiwania spisów\n' +
                'materiałów, jednak nie kopii (cache) lub archiwów takich\n' +
                'materiałów. EVRYPLACE zastrzega sobie prawo cofnięcia\n' +
                'takiego zezwolenia w zakresie ogólnym lub w\n' +
                'poszczególnych przypadkach.',
              li4: 'EVRYPLACE stale wprowadza innowacje w celu zapewnienia\n' +
                'użytkownikom możliwie najlepszych wrażeń. Użytkownik\n' +
                'przyjmuje do wiadomości i uznaje, że forma i charakter\n' +
                'Usług świadczonych przez EVRYPLACE mogą zostać zmienione\n' +
                'bez uprzedniego zawiadamiania o tym użytkownika.',
              li5: 'Użytkownik przyjmuje do wiadomości, że w ramach stałego\n' +
                'wprowadzania innowacji EVRYPLACE może przerwać (na stałe\n' +
                'lub tymczasowo) świadczenie Usług (lub dowolnych\n' +
                'elementów w ramach Usług) na rzecz użytkownika lub ogółu\n' +
                'użytkowników wedle wyłącznego uznania EVRYPLACE, bez\n' +
                'uprzedniego zawiadamiania użytkownika. Użytkownik może\n' +
                'zaprzestać korzystania z Usług w dowolnym czasie.\n' +
                'Użytkownik nie musi zawiadamiać EVRYPLACE o zaprzestaniu\n' +
                'korzystania z Usług.',
              li6: 'Użytkownik uznaje, że ponosi wyłączną odpowiedzialność z\n' +
                'tytułu - oraz, że EVRYPLACE nie ponosi odpowiedzialności\n' +
                'wobec użytkownika ani osób trzecich z tytułu -\n' +
                'naruszenia zobowiązań wynikających z Warunków oraz z\n' +
                'tytułu skutków takiego naruszenia (w tym ewentualnych\n' +
                'strat lub szkód poniesionych przez EVRYPLACE).',
            },
            block5: {
              header: 'Treści',
              li1: 'Jako posiadacz konta EVRYPLACE użytkownik może przekazywać\n' +
                'Treści. Użytkownik przyjmuje do wiadomości, że niezależnie\n' +
                'od tego, czy Treści zostaną opublikowane, EVRYPLACE nie\n' +
                'gwarantuje zachowania poufności Treści.',
              li2: 'Zmiana statusu materiałów dokonana przez użytkownika może\n' +
                'trwać do 10 minut.',
              li3: 'Użytkownik zachowuje wszystkie prawa własności do własnych\n' +
                'Treści, jednak powinien przyznać ograniczone prawa\n' +
                'licencyjne EVRYPLACE i innym użytkownikom Usług. Zostały\n' +
                'one opisane w artykule 8 niniejszych Warunków (Prawa, na\n' +
                'które użytkownik udziela licencji).',
              li4: 'Użytkownik przyjmuje do wiadomości i uznaje, że ponosi\n' +
                'wyłączną odpowiedzialność za własne Treści oraz skutki ich\n' +
                'zamieszczenia lub opublikowania. EVRYPLACE nie firmuje\n' +
                'żadnych Treści ani żadnych wyrażonych tam opinii,\n' +
                'rekomendacji lub porad, jak również w sposób wyraźny\n' +
                'odmawia przyjęcia jakiejkolwiek odpowiedzialności\n' +
                'związanej z Treściami.',
              li5: 'Użytkownik oświadcza i zapewnia, że posiada (oraz, że\n' +
                'podczas korzystania z Usług będzie nadal posiadał)\n' +
                'wszystkie konieczne licencje, prawa, zgody i zezwolenia\n' +
                'wymagane w celu umożliwienia używania przez EVRYPLACE jego\n' +
                'Treści w celu świadczenia Usług przez EVRYPLACE, oraz w\n' +
                'celu używania jego Treści w sposób określony dla Usług\n' +
                'oraz w niniejszych Warunkach.',
              li6: 'Użytkownik zgadza się nie zamieszczać ani nie przesyłać\n' +
                'Treści, o ile te zawierałyby treści, których posiadanie\n' +
                'jest dla użytkownika nielegalne w kraju, którego jest\n' +
                'rezydentem, lub których wykorzystywanie bądź posiadanie\n' +
                'przez EVRYPLACE w związku ze świadczeniem Usług jest\n' +
                'nielegalne.',
              li7: 'Użytkownik zobowiązuje się, że Treści zgłaszane przez\n' +
                'niego do Usług nie będą zawierać żadnych materiałów\n' +
                'będących przedmiotem praw majątkowych osób trzecich (w tym\n' +
                'praw ochrony prywatności i praw do kontroli wykorzystania\n' +
                'wizerunku), chyba że użytkownik posiada oficjalną licencję\n' +
                'lub zgodę od prawowitego właściciela lub uprawnienie\n' +
                'przysługujące z innego tytułu na zamieszczenie\n' +
                'przedmiotowego materiału oraz na udzielenie EVRYPLACE\n' +
                'licencji, o której mowa w artykule 8.1 poniżej.',
              li8: 'W momencie powzięcia wiadomości o każdym potencjalnym\n' +
                'naruszeniu niniejszych Warunków EVRYPLACE zastrzega sobie\n' +
                'prawo (choć na EVRYPLACE nie będzie spoczywał obowiązek),\n' +
                'swobodnego i nie obwarowanego koniecznością uprzedniego\n' +
                'zawiadomienia, decydowania, czy Treści spełniają wymagania\n' +
                'stawiane w niniejszych Warunkach zgłaszanym treściom oraz\n' +
                'może usunąć Treści i/lub uniemożliwić użytkownikowi\n' +
                'zamieszczanie Treści naruszających niniejsze Warunki.',
              li9: 'Ponadto Użytkownik przyjmuje do wiadomości i uznaje, że\n' +
                'korzystając z Usług, może być narażony na Treści, które\n' +
                'będą nieścisłe, obraźliwe, nieprzyzwoite lub które\n' +
                'użytkownik uzna za niedopuszczalne z innych względów.\n' +
                'Użytkownik zgadza się zrzec i niniejszym zrzeka się praw i\n' +
                'środków prawnych przysługujących mu wobec EVRYPLACE na\n' +
                'mocy przepisów prawa i zasad słuszności w odniesieniu do\n' +
                'takich Treści.',
            },
            block6: {
              header: 'Konta EVRYPLACE',
              li1: 'W celu uzyskania dostępu do niektórych elementów Witryny\n' +
                'Internetowej lub innych elementów Usług, użytkownik\n' +
                'powinien założyć konto EVRYPLACE. Przy zakładaniu konta\n' +
                'użytkownik powinien podać dokładne i pełne informacje.\n' +
                'Ważne jest, aby użytkownik zabezpieczył hasło do konta\n' +
                'EVRYPLACE i utrzymywał je w poufności.',
              li2: 'Użytkownik powinien niezwłocznie zawiadamiać EVRYPLACE o\n' +
                'wszelkich naruszeniach bezpieczeństwa lub przypadkach\n' +
                'korzystania z jego konta EVRYPLACE przez osoby\n' +
                'nieupoważnione, o których powziął wiadomość.',
              li3: 'Użytkownik uznaje, że będzie jedyną osobą odpowiedzialną\n' +
                '(wobec EVRYPLACE i innych osób) za wszelkie działania\n' +
                'mające miejsce w ramach jego konta EVRYPLACE.',
            },
            block7: {
              header: 'Prawa, na które użytkownik udziela licencji',
              li1: 'Przesyłając do EVRYPLACE lub zamieszczając w jego\n' +
                'witrynach Treści, użytkownik udziela:',
              li2: 'EVRYPLACE nieograniczonej terytorialnie, niewyłącznej,\n' +
                'bezpłatnej, zbywalnej licencji (z prawem sublicencji)\n' +
                'na korzystanie z Treści, powielanie takich Treści, ich\n' +
                'rozpowszechnianie, opracowywanie na ich podstawie\n' +
                'utworów zależnych, ich wystawianie bądź wykonywanie w\n' +
                'związku ze świadczeniem Usług i prowadzeniem\n' +
                'działalności EVRYPLACE, w tym m.in. do promowania i\n' +
                'rozpowszechniania Usług w części lub całości (wraz z\n' +
                'utworami zależnymi) niezależnie od formatu nośnika i\n' +
                'sposobu przekazywania materiału;',
              li3: 'każdemu użytkownikowi Usług - nieograniczonej\n' +
                'terytorialnie, niewyłącznej, bezpłatnej licencji na\n' +
                'dostęp do jego Treści za pośrednictwem Usług oraz na\n' +
                'korzystanie z takich Treści, ich powielanie i\n' +
                'rozpowszechnianie, opracowywanie na ich podstawie\n' +
                'utworów zależnych, oraz ich wystawianie bądź\n' +
                'wykonywanie w ramach zespołu funkcji oferowanych przez\n' +
                'Usługi oraz w granicach dozwolonych na podstawie\n' +
                'niniejszych Warunków.',
              li4: 'Powyższe licencje udzielane przez użytkownika w\n' +
                'odniesieniu do Treści wygasają z chwilą, gdy użytkownik\n' +
                'usunie lub wykasuje je z Witryny Internetowej. Powyższe\n' +
                'licencje udzielone przez użytkownika w odniesieniu do\n' +
                'komentarzy tekstowych zgłoszonych jako Treść są\n' +
                'nieograniczone w czasie i nieodwołalne, jednakże pod\n' +
                'innymi względami pozostają bez znaczenia dla praw\n' +
                'własności użytkownika określonych w artykule 7.2 powyżej.',
              li5: 'Treści EVRYPLACE znajdujące się w Witrynie Internetowej',
              li6: 'Poza Treściami zamieszczanymi przez użytkownika w\n' +
                'Usługach, wszelkie inne Treści znajdujące się w Usługach\n' +
                'należą do lub są licencjonowane przez EVRYPLACE oraz są\n' +
                'przedmiotem praw autorskich, praw ochronnych na znaki\n' +
                'towarowe EVRYPLACE innych praw własności intelektualnej\n' +
                'EVRYPLACE lub licencjodawców EVRYPLACE. Znaki towarowe\n' +
                'bądź usługowe należące do osób trzecich figurujące w\n' +
                'Treściach, które nie zostały wgrane lub umieszczone przez\n' +
                'użytkownika, są znakami towarowymi lub usługowymi\n' +
                'odpowiednich osób uprawnionych z tych znaków. Takie Treści\n' +
                'nie mogą być pobierane, kopiowane, powielane,\n' +
                'rozpowszechniane, przekazywane, nadawane, wyświetlane,\n' +
                'sprzedawane, nie mogą być przedmiotem licencji ani innej\n' +
                'eksploatacji na jakiekolwiek potrzeby bez uprzedniej\n' +
                'pisemnej zgody EVRYPLACE lub, tam gdzie to właściwe,\n' +
                'licencjodawców EVRYPLACE. EVRYPLACE oraz licencjodawcy\n' +
                'EVRYPLACE zastrzegają wszystkie prawa, które nie zostały\n' +
                'przez nich udzielone w sposób wyraźny do ich Treści.',
            },
            block8: {
              header: 'Linki z EVRYPLACE',
              li1: 'Stworzone przy Pomocy EVRYPLACE prezentacje mogą posiadac\n' +
                'różne statusy, nadawane przez użytkownika: [xxxxxxxxxxxx1]\n' +
                '– dostęp do prezentacji ma tylko zalogowany użytkownik\n' +
                '[xxxxxxxxxxxx2] - dostęp do prezentacji ma nie tylko\n' +
                'zalogowany użytkownik, ale także osoba, która weszła w\n' +
                'posiadaniu linku do prezentacji; prezentacji nie da się\n' +
                'odnaleźć poprzez wyszukiwarkę EVRYPLACE [xxxxxxxxxxxx3] -\n' +
                'dostęp do prezentacji ma nie tylko zalogowany użytkownik,\n' +
                'ale także osoba, która weszła w posiadaniu linku do\n' +
                'prezentacji; poza tym prezentację da się odnaleźć poprzez\n' +
                'wyszukiwarkę EVRYPLACE lub poprzez wyszukiwarki innych\n' +
                'dostawców. Możliwe są zmiany statusu przez użytkownika\n' +
                'oraz zmiany linków do prezentacji. EVRYPLACE nie odpowiada\n' +
                'za umieszczenie prezentacji w wynikach wyszukiwania innych\n' +
                'dostawców, ale po zmianie statusu prezentacji uniemożliwi\n' +
                'dostęp do niej na swoich serwerach, z uwzględnieniem pkt\n' +
                '5.2.',
              li2: 'Usługi mogą zawierać hyperlinki do innych witryn nie\n' +
                'należących do EVRYPLACE lub nie podlegających kontroli\n' +
                'EVRYPLACE. EVRYPLACE nie posiada kontroli nad treściami,\n' +
                'zasadami ochrony prywatności bądź praktykami stosowanymi w\n' +
                'witrynach osób trzecich, nie przyjmuje za nie również\n' +
                'odpowiedzialności.',
              li3: 'Użytkownik przyjmuje do wiadomości i uznaje, że EVRYPLACE\n' +
                'nie ponosi odpowiedzialności za dostępność zewnętrznych\n' +
                'witryn lub zasobów oraz nie zatwierdza reklam, produktów\n' +
                'ani innych materiałów dostępnych poprzez takie witryny lub\n' +
                'w takich zasobach.',
              li4: 'Użytkownik przyjmuje do wiadomości i uznaje, że EVRYPLACE\n' +
                'nie ponosi odpowiedzialności za straty lub szkody, które\n' +
                'użytkownik może ponieść w związku z dostępnością\n' +
                'zewnętrznych witryn lub zasobów lub w związku z poleganiem\n' +
                'przez użytkownika na kompletności, dokładności bądź\n' +
                'istnieniu jakiejkolwiek reklamy, produktów lub innych\n' +
                'materiałów w takich witrynach lub zasobach.',
              li5: 'EVRYPLACE zachęca użytkownika do czynnego kontrolowania\n' +
                'momentu opuszczenia Usług oraz zapoznawania się z\n' +
                'warunkami i zasadami ochrony prywatności każdej z witryn\n' +
                'odwiedzanych przez użytkownika.',
            },
            block9: {
              header: 'Zakończenie relacji z EVRYPLACE',
              li1: 'Warunki będą obowiązywać do czasu ich wypowiedzenia przez\n' +
                'użytkownika lub przez EVRYPLACE w trybie przewidzianym\n' +
                'poniżej.',
              li2: 'Jeżeli użytkownik chce wypowiedzieć umowę z EVRYPLACE,\n' +
                'może tego dokonać',
              li3: 'w każdym czasie, poprzez zawiadomienie EVRYPLACE o\n' +
                'rozwiązaniu umowy oraz',
              li4: 'poprzez zamknięcie konta EVRYPLACE. Zawiadomienie\n' +
                'należy przesłać w formie pisemnej na adres EVRYPLACE\n' +
                'podany na wstępie niniejszych Warunków.',
              li5: 'EVRYPLACE może w dowolnym czasie wypowiedzieć ze skutkiem\n' +
                'natychmiastowym umowę wiążącą EVRYPLACE z użytkownikiem,\n' +
                'jeżeli:',
              li6: 'użytkownik naruszy jakiekolwiek postanowienia Warunków\n' +
                '(lub dopuści się działania, z którego jasno wynika, że\n' +
                'nie zamierza lub nie jest w stanie przestrzegać\n' +
                'Warunków); lub',
              li7: 'EVRYPLACE jest, zgodnie z obowiązującym prawem,\n' +
                'zobligowany wypowiedzieć umowę (na przykład, gdy\n' +
                'świadczenie Usług na rzecz użytkownika jest lub stanie\n' +
                'się bezprawne); lub',
              li8: 'EVRYPLACE może wypowiedzieć umowę wiążącą EVRYPLACE z\n' +
                'użytkownikiem, jeżeli:',
              li9: 'EVRYPLACE odchodzi od świadczenia Usługi na rzecz\n' +
                'rezydentów kraju użytkownika lub kraju, z którego\n' +
                'użytkownik korzysta z Usług; lub',
              li10: 'świadczenie Usług na rzecz użytkownika, zdaniem\n' +
                'EVRYPLACE przestało być opłacalne z handlowego punktu\n' +
                'widzenia, przy czym w obu przypadkach opisanych w pkt\n' +
                '1 i 2 niniejszego artykułu 9.4 EVRYPLACE przekaże\n' +
                'użytkownikowi pisemne zawiadomienie o wypowiedzeniu za\n' +
                '7 dniowym wyprzedzeniem.',
              li11: 'Po wygaśnięciu niniejszych Warunków, dla praw\n' +
                'przysługujących użytkownikowi i EVRYPLACE, obowiązków i\n' +
                'odpowiedzialności, którym użytkownik i EVRYPLACE podlegali\n' +
                '(lub które narosły w okresie obowiązywania Warunków) lub\n' +
                'co do których w sposób wyraźny stwierdzono, że pozostają\n' +
                'bezterminowo w mocy, wygaśnięcie Warunków będzie bez\n' +
                'znaczenia, a postanowienia artykułu 14.6 będą stosować się\n' +
                'do takich praw, obowiązków i odpowiedzialności\n' +
                'bezterminowo.',
            },
            block10: {
              header: 'Wyłączenie gwarancji',
              li1: 'Żaden z zapisów niniejszych Warunków nie rzutuje na\n' +
                'ustawowe uprawnienia użytkownika jako konsumenta, a\n' +
                'użytkownik nie może takich uprawnień w drodze umownej\n' +
                'zmieniać ani się ich zrzekać.',
              li2: 'Usługi są świadczone „w stanie w jakim są”, dlatego\n' +
                'też EVRYPLACE nie udziela użytkownikowi jakichkolwiek\n' +
                'gwarancji ani nie składa mu zapewnień w ich zakresie.',
              li3: 'W szczególności EVRYPLACE nie składa użytkownikowi\n' +
                'oświadczeń ani zapewnień, że:',
              li4: 'korzystanie przez użytkownika z Usług spełni\n' +
                'oczekiwania użytkownika,',
              li5: 'korzystanie przez użytkownika z Usług będzie\n' +
                'nieprzerwane, terminowe, bezpieczne czy też wolne od\n' +
                'usterek,',
              li6: 'informacje uzyskane przez użytkownika w ramach\n' +
                'korzystania przez niego z Usług będą dokładne lub\n' +
                'wiarygodne, oraz że',
              li7: 'wady działania lub funkcjonalności oprogramowania\n' +
                'udostępnianego użytkownikowi w ramach Usług zostaną\n' +
                'usunięte.',
              li8: 'Do Usług nie mają zastosowania warunki, zapewnienia ani\n' +
                'inne postanowienia (takie jak m.in. dorozumiane warunki,\n' +
                'co do zadowalającej jakości, zgodności z przeznaczeniem\n' +
                'bądź opisem), które nie zostały w sposób wyraźny zapisane\n' +
                'w niniejszych Warunkach.',
            },
            block11: {
              header: 'Ograniczenie odpowiedzialności',
              li1: 'Żaden z zapisów niniejszych Warunków nie skutkuje\n' +
                'wyłączeniem ani ograniczeniem odpowiedzialności EVRYPLACE\n' +
                'za straty, która zgodnie z prawem właściwym nie podlega\n' +
                'wyłączeniu bądź ograniczeniu.',
              li2: 'Z zastrzeżeniem ogólnego sformułowania zawartego w\n' +
                'powyższym artykule 11.1, EVRYPLACE nie ponosi wobec\n' +
                'użytkownika odpowiedzialności za:',
              li3: 'straty pośrednie lub wynikowe, jakie użytkownik może\n' +
                'ponieść. Przez straty takie należy rozumieć m.in.\n' +
                'utratę zysku (zarówno bezpośrednio jak i pośrednio),\n' +
                'utratę renomy bądź dobrej reputacji firmy, a także\n' +
                'utratę danych przez użytkownika;',
              li4: 'straty i szkody poniesione przez użytkownika w wyniku:',
              li5: 'polegania przez użytkownika na kompletności,\n' +
                'dokładności bądź istnieniu jakiejkolwiek reklamy,\n' +
                'bądź w wyniku wejścia przez użytkownika w\n' +
                'jakąkolwiek relację bądź zawarcia transakcji z\n' +
                'reklamodawcą lub sponsorem, którego reklamy\n' +
                'ukazują się w ramach Usług;',
              li6: 'zmian, jakie EVRYPLACE może wprowadzić do Usług\n' +
                'względnie czasowego lub trwałego zaprzestania\n' +
                'świadczenia Usług (lub rezygnacji z jakichkolwiek\n' +
                'elementów Usług);',
              li7: 'usunięcia, uszkodzenia lub niezachowania Treści i\n' +
                'innych danych komunikacyjnych utrzymywanych bądź\n' +
                'przesyłanych w ramach korzystania przez\n' +
                'użytkownika z Usług;',
              li8: 'nieprzekazania przez użytkownika EVRYPLACE\n' +
                'właściwych danych konta użytkownika;',
              li9: 'nieprzestrzegania przez użytkownika zasady\n' +
                'bezpieczeństwa i poufności hasła lub danych konta\n' +
                'EVRYPLACE;',
              li10: 'Ograniczenie odpowiedzialności EVRYPLACE wobec użytkownika\n' +
                'w powyższym artykule 11.3 stosuje się niezależnie od\n' +
                'uprzedzenia EVRYPLACE o możliwości powstania strat oraz\n' +
                'okoliczności, iż EVRYPLACE powinien był z takiej\n' +
                'możliwości zdawać sobie sprawę.',
            },
            block12: {
              header: 'Ogólne warunki stosunku prawnego',
              li1: 'Warunki zawierają całość umowy pomiędzy użytkownikiem a\n' +
                'EVRYPLACE i regulują zasady korzystania przez użytkownika\n' +
                'z Usług, zastępując w całości wcześniejsze umowy pomiędzy\n' +
                'stronami w przedmiocie Usług.',
              li2: 'Użytkownik przyjmuje do wiadomości, że EVRYPLACE może\n' +
                'kierować do niego zawiadomienia, w tym o zmianach\n' +
                'Warunków, listami elektronicznymi, drogą pocztową lub\n' +
                'zamieszczają zawiadomienia w Usługach.',
              li3: 'Użytkownik przyjmuje do wiadomości, że niewykonanie bądź\n' +
                'brak egzekucji ze strony EVRYPLACE prawa lub środka\n' +
                'prawnego przewidzianego w Warunkach (lub przysługującego\n' +
                'EVRYPLACE na podstawie prawa właściwego) nie będzie\n' +
                'stanowiło formalnego zrzeczenia się takich przysługujących\n' +
                'EVRYPLACE praw, a takie prawa i środki prawne pozostaną\n' +
                'dostępne dla EVRYPLACE.',
              li4: 'Jeżeli jakikolwiek sąd właściwy w sprawie orzeknie o\n' +
                'nieważności jakiegokolwiek postanowienia niniejszych\n' +
                'Warunków, postanowienie zostanie usunięte z Warunków w\n' +
                'sposób nienaruszający integralności pozostałych\n' +
                'postanowień. Pozostałe postanowienia Warunków pozostaną w\n' +
                'mocy i będą wykonalne.',
              li5: 'Warunki oraz relacja pomiędzy użytkownikiem a EVRYPLACE na\n' +
                'podstawie Warunków podlegają przepisom prawa polskiego.\n' +
                'Użytkownik i EVRYPLACE zgodnie poddają się wyłącznej\n' +
                'jurysdykcji sądów polskich we wszystkich kwestiach\n' +
                'prawnych wynikających z Warunków. Niezależnie od\n' +
                'powyższego użytkownik zgadza się, aby EVRYPLACE\n' +
                'przysługiwało prawo występowania o środki zabezpieczenia\n' +
                'tymczasowego (lub inne równoważne im doraźne środki\n' +
                'prawne) w każdej jurysdykcji.',
            },
          },
          notfound: {
            title: 'Strona nie znaleziona.',
            subheader: ''
          },
          ctablock: {
            header: 'Stwórz swoją pierwszą prezentację\xa0VR<br> – za darmo!',
            subheader: 'Nie masz własnych materiałów? <br>Nie szkodzi, skorzystaj z testowych zdjęć EVRYPLACE.',
            trybtn: 'WYPRÓBUJ'
          },
          faq: {
            metaDescription: 'FAQ - miejsce z odpowiedziami na wszystkie najważniejsze pytania naszych klientów',
            metaKeywords: 'FAQ',
            metaTitle: 'FAQ - Evryplace',
            title: 'FAQ',
            q1: 'Często pojawia się określenie “wirtualny spacer”. Czym on właściwie jest?',
            a1: 'Wirtualny spacer to nic innego jak cyfrowe, połączone w prezentację panoramiczne zdjęcia, odwzorowujące rzeczywistość w innym wirtualnym wymiarze – może dotyczyć konkretnych miejsc, przestrzeni, budynków albo pomieszczeń.',
            q2: 'Czy mogę przetestować EVRYPLACE zanim zdecyduję się na zakup?',
            a2: 'Oczywiście. Stworzenie pierwszej prezentacji jest całkowicie darmowe. A kiedy odkryjesz już wszystkie zalety, możesz wybrać pakiet dedykowany Twoim potrzebom.',
            q3: 'Czy potrzebuję gogli żeby móc oglądać spacery albo uczestniczyć w szkoleniach?',
            a3: 'Możesz oglądać spacery zarówno w goglach jak i bez nich. Możesz je oglądać na tablecie, komputerze lub telefonie. Od Ciebie zależy, którą opcję wybierzesz.',
            q4: 'Nie mam odpowiedniego aparatu. Jak mogę wykonać zdjęcia?',
            a4: 'Zdjęcia mogą być wykonywane za pomocą wielu różnych aparatów, jak na przykład Ricoh Theta, w którym funkcjonalność ta jest integralnym elementem urządzenia oraz z wykorzystaniem aparatów niektórych telefonów komórkowych. By możliwym było wgranie zdjęcia do platformy, musi ono jedynie spełniać wymagania dotyczące rodzaju pliku.',
            q5: 'Czy potrzebuję specjalistycznego oprogramowania i odpowiednich umiejętności, żeby zbudować swoją własną prezentację?',
            a5: 'Do stworzenia wirtualnego spaceru wystarczy tylko chwila wolnego czasu i edytor EVRYPLACE i połączenie z internetem. To niesamowicie proste i intuicyjne narzędzie, nie potrzebuje dodatkowego oprogramowania ani specjalistycznych umiejętności.',
            q6: 'Pierwszy raz mam do czynienia z czymś takim. Czy ktoś może wytłumaczyć mi jak używać edytora?',
            a6: 'W edytorze EVRYPLACE znajduje się samouczek, który pokaże Ci w jaki sposób najszybciej i najefektywniej stworzyć swoją własną prezentację VR. Możesz z niego korzystać zawsze, kiedy nie czujesz się pewny kolejnego kroku.',
            q7: 'Kto może widzieć stworzoną przeze mnie prezentację?',
            a7: 'Jeśli zdecydujesz się na tryb prywatny, prezentacja pozostaje jedynie do wglądu osób, którym udostępnisz link do Twojej prezentacji. Prezentacja w trybie publicznym pojawia się w galerii wirtualnych spacerów na stronie explore.evryplace.com i jest dostępna dla wszystkich odwiedzających stronę.',
            q8: 'Chcę obejrzeć prezentację w goglach. Jak umieścić telefon w cardboardzie lub w innym urządzeniu? Czy potrzebuję specjalnie kalibrować telefon aby obejrzeć prezentację EVRYPLACE?',
            a8: 'Aby Obejrzeć prezentacje w googlach trzeba kliknąć ikonę gogli w dolnym pasku, następnie telefon albo poprosi o obrócenie i włożenie go do gogli albo od razu pojawi się widok podzielony na 2 części. umieść telefon w goglach tak, aby kreska dzieląca ekran była pośrodku gogli. Jeżeli widok przez gogle jest niewyraźny lub nieostry, to znaczy że powinieneś zmienić tryb wyświetlania na inny dostosowany do twoich gogli, aby to zrobić kliknij na ikonę ustawień (koło zębate) pośrodku dolnej krawędzi ekranu, otworzy się wtedy okno z listą rodzajów gogli wybierz swój i zatwierdź klikając zapisz. Pamiętaj, aby wyłączyć tryb blokady obracania ekranu.',
            q9: 'Coś w edytorze nie chce działać... A może coś robię nie tak?',
            a9: 'Możliwe, że wystąpił jakiś błąd techniczny. Skontaktuj się z nami za pomocą formularza kontaktowego na stronie, opisz swój problem, a my spróbujemy pomóc Ci najszybciej jak to możliwe.',
            q10: 'Na jakim urządzeniu mogę oglądać gotowe spacery?',
            a10: 'Spacery tworzone w edytorze EVRYPLACE możesz oglądać zarówno na komputerze jak i urządzeniach mobilnych. Możesz też skorzystać z aplikacji EVRYPLACE.',
            q11: 'Do czego służy aplikacja EVRYPLACE przeznaczona na urządzenia mobline?',
            a11: 'Aplikacja pozwala na przeglądanie wszystkich dostępnych, publicznych prezentacji stworzonych w edytorze EVRYPLACE. Daje także możliwość uczestnictwa w online viewingi przeglądania spacerów w trybie offline.',
          },
          VRModal: {
            title: 'Oglądaj prezentacje w Wirtualnej Rzeczywistości (VR)',
            openOnVR: 'Wystarczy, że otworzysz link do prezentacji w przeglądarce internetowej w goglach VR Oculus Quest',
            more: 'Więcej o Evryplace VR',
          },
          privatePresentationModal: {
            title: "Prezentacja jest chroniona hasłem",
            label: "WPROWADŹ HASŁO",
            tip: "Wprowadź hasło, aby wyświetlić prezentację",
            error: "Wprowadzone hasło jest nieprawidłowe. Spróbuj ponownie",
          },
          viewing: {
            enterHashModal: {
              title: 'Witaj we wspólnej prezentacji 360°',
              titleBrand: 'Witaj w coviewingu ',
              titleBrand2: '',
              label: 'WPROWADŹ HASH',
              tip: 'Podaj identyfikator wspólnej prezentacji aby się połączyć.',
              validation: 'Identyfikator wspólnej prezentacji musi posiadać 6 znaków.',
            },
            enterNameModal: {
              label: 'Wprowadź imię',
              placeholder: 'TWOJE IMIĘ',
            },
            welcomeModal: {
              title: 'Witaj we wspólnej prezentacji 360°',
              titleBrand: 'Witaj w coviewingu ',
              titleBrand2: '',
              content1: 'Jesteś połączony.',
              content2: 'Kliknij “START” by rozpocząć wspólną prezentację.',
              cta: 'START',
            },
            errorModal: {
              title: 'Niewłaściwy url / ID prezentacji.',
              content1: 'Ten identyfikator wspólnej prezentacji jest nieprawidłowy lub wygasł.',
              content2: 'Sprawdź zaproszenie i spróbuj ponownie.'
            },
            waitingModal: {
              title: 'Witaj we wspólnej prezentacji 360°',
              titleBrand: 'Witaj w coviewingu ',
              titleBrand2: '',
              content1: 'Jesteś połączony z prezentacją.',
              content2: 'Poczekaj chwilę na dołączenie prowadzącego.',
            },
            connectionStatusModal: {
              connectedAwaiting: 'Połączony. Poczekaj na dołączenie prowadzącego.',
              hostConnected: 'Prowadzący prezentację jest podłączony.',
              voiceConnectionUnaviable: 'Połączenie głosowe nie jest dostępne.',
              contactWithHost: 'Prowadzący się spóźnia? Skontaktuj się z nim używając powyższych danych.',
              hostCanStartVoiceConnection: 'Prowadzący może rozpocząć połączenie głosowe.',
              voiceConnectionOn1: 'Połączenie głosowe jest włączone.',
              voiceConnectionOn2: 'Użyj przycisków na belce aby wyciszyć mikrofon lub głośnik.',
            },
            messages: {
              youAreInControl: 'Ty kontrolujesz prezentację',
              hostIsInControl: 'Prezentacja kontrolowana przez prowadzącego',
              voiceCallStarted: 'Rozpoczęto połączenie głosowe',
              voiceCallCompleted: 'Zakończono połączenie głosowe',
              clientDisconnected: ' opuścił co-viewing',
              hostDisconnected: 'Prezentujący opuścił co-viewing',
              micAccessFailed: 'Wygląda na to, że zablokowałeś uprawnienia dostępu do mikrofonu. Sprawdź ustawienia przeglądarki',
              updateRequired: 'Musisz wykupić wyższy pakiet aby używać tej funkcji'
            }
          },
          viewingHost: {
            anonymousClient: 'Anonimowy Klient',
            participants: 'Uczestnicy',
            notes: 'Notatki',
            presentation: 'Prezentacja',
            presenting: 'Prezentowanie',
            audio: 'Audio',
            follow: 'Podążaj',
            guide: 'Prezentuj',
            waiting: 'oczekiwanie...',
            showOtherPresentation: 'Pokaż inną prezentację',
            cannotLoad: 'Nie można załadować prezentacji',
            myVirtualTours: 'Moje wirtualne spacery',
            empty: 'Nie opublikowano innych prezentacji'
          },
          rodoModal: {
            title: 'Drodzy klienci,',
            copy1: 'W dniu 25 maja 2018 r. wchodzi w życie rozporządzenie Parlamentu Europejskiego i Rady (UE) nr 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), zwane w skrócie „RODO” lub „GDPR”. Dokument ten ujednolica przetwarzanie danych osobowych w całej Unii Europejskiej, a także zobowiązuje podmioty przetwarzające dane osobowe do zmiany sposobu informowania o tym, jak te dane są przetwarzane. Z tego powodu z dniem 25 maja 2018 r. zaktualizowana zostaje Polityka prywatności EVRYPLACE, która dostępna jest na stronie internetowej <a href="/static/home/privacy_en.pdf">https://evryplace.com/privacy</a>.',
            copy2: 'Oświadczam, że zapoznałem się i zgadzam się z ',
            copy3: 'Warunkami użytkowania',
            copy4: 'Nie musisz podejmować żadnych kroków, aby zaakceptować zmiany, ponieważ wchodzą one w życie automatycznie.',
            cta: 'Rozumiem',
          },
          validation: {
            emailTooLong: 'Przekroczono maksymalną liczbę znaków (128 znaków)',
            emailNotValid: 'E-mail jest nieprawidłowy.',
            passwordTooShort: 'Hasło musi zawierać co najmniej 8 znaków',
            passwordsNotEqual: 'Hasła różnią się.',
            displayNameTooShort: 'Nazwa jest za krótka.',
            messageTooShort: 'Wprowadzona wiadomość jest za krótka.',
            contactModalMessage: 'Powyższa wiadomość jest zbyt krótka. Podaj więcej szczegółów na temat swojego projektu.',
          },
          embed: {
            splashPageText: 'Kliknij, aby rozpocząć spacer 360°',
            dragAndDropAction: 'Przeciągnij, aby się rozejrzeć',
            loading: 'Wczytywanie...'
          },
          lgExplore: {
            filters: {
              title: 'Filtrowanie',
              shopAttributes: 'Atrybuty sklepu',
              pdsShop: 'PSD Sklepu',
              nameShop: 'Nazwa sklepu',
              idAgent: 'ID Ajenta',
              nameAgent: 'Imie i nazwisko Ajenta',
              addressShop: 'Adres sklepu',
              area: 'Region',
              manager: 'Kierownik regionalny',
              rks: 'RKS',
              flyingCoach: 'Flying Coach',
              city: 'Miasto',
              citySearchPlaceholder: 'Znajdź miasto',
              brand: 'Brand',
              location: 'Lokalizacja',
              prformat: 'Preformat',
              presentationAttributes: 'Atrybuty prezentacji',
              dateOfAddition: 'Data dodania',
              startDay: 'Od dnia',
              endDay: 'Do dnia',
            },
            searchPlaceholder: 'Wyszukaj prezentację',
            newPresentation: 'Nowa prezentacja',
            createDate: 'Data utworzenia',
            addPhotosphere: 'Dodaj fotosfery',
            onDrag: 'lub przeciągnij i upuść fotosfery na to okno aby je wgrać',
            addPsd: 'Wybierz PSD i Datę',
            others: 'Inne',
            fileUploadError: 'Błąd przesyłania pliku',
            retry: 'Ponów',
          },
          shopPresentation: {
            author: 'Autor',
            day: 'Data dodania',
            psd: 'PSD',
            locationName: 'Lokalizacja',
            rksName: 'Kierownik regionalny',
            fcName: 'Flygin coach',
            prFormat: 'Preformat',
            rksId: 'RKS',
            address: 'Adres',
            city: 'Miasto',
            rmId: 'ID Ajenta',
            rmName: 'Ajent',
            presentationHistory: 'Historia prezentacji',
            newPsd: 'Podaj nowy PSD sklepu'
          },
          tourism: {
            heroHeading: 'Pozwól odbiorcom poczuć<br>się jak goście Twojego hotelu',
            heroSubHeading: 'Ugość oglądających w wirtualnych wnętrzach swojego hotelu<br>i spraw, że przez chwilę poczują się jak na wakacjach.',
            whyEpHeading: 'Dlaczego EVRYPLACE?',
            item1Heading: 'Udoskonalisz proces rezerwacji.',
            item1Desc: 'Spraw, że rezerwacja przebiegnie szybciej i przyjemniej. Pokaż przyszłym gościom Twojego hotelu, że prezentowane przestrzenie, wyglądają dokładnie tak samo poza wirtualnym spacerem. Ułatw im proces decyzyjny uwzględniając niezbędne detale.',
            item2Heading: 'Zaprezentujesz atuty swojej oferty… i jej okolicy.',
            item2Desc: 'Lokalizacja hotelu jest tak samo ważna jak jego standardy, a informację na ich temat zawrzesz w jednej prezentacji. Opiszesz szczegóły wyposażenia pokoju, ale też pokażesz widok z okna czy dołączysz dźwięki z pobliskiego parku.',
            item3Heading: 'Dostarczysz wrażenia jeszcze przed przybyciem gości.',
            item3Desc: 'Uczyń z doświadczania w 360° swoje kluczowe narzędzie marketingowe. Usprawnij kontakt z odbiorcami, pozwól im oglądać wnętrza Twojego hotelu bez względu na czas i odległość. Zdobądź ich zaufanie zanim pojawią się na miejscu.',
            carousel1: {
              heading1: 'EVRYPLACE dla Hotelarstwa',
              desc1: 'Spraw, że rezerwacja przebiegnie szybciej i przyjemniej. Pokaż przyszłym gościom Twojego hotelu, że prezentowane przestrzenie, wyglądają dokładnie tak samo poza wirtualnym spacerem. Ułatw im proces decyzyjny uwzględniając niezbędne detale.',
              heading2: 'EVRYPLACE dla Hotelarstwa',
              desc2: 'Spraw, że rezerwacja przebiegnie szybciej i przyjemniej. Pokaż przyszłym gościom Twojego hotelu, że prezentowane przestrzenie, wyglądają dokładnie tak samo poza wirtualnym spacerem. Ułatw im proces decyzyjny uwzględniając niezbędne detale.',
              heading3: 'EVRYPLACE dla Hotelarstwa',
              desc3: 'Spraw, że rezerwacja przebiegnie szybciej i przyjemniej. Pokaż przyszłym gościom Twojego hotelu, że prezentowane przestrzenie, wyglądają dokładnie tak samo poza wirtualnym spacerem. Ułatw im proces decyzyjny uwzględniając niezbędne detale.',
            },
            carousel2: {
              heading1: 'EVRYPLACE dla Hotelarstwa',
              desc1: 'Spraw, że rezerwacja przebiegnie szybciej i przyjemniej. Pokaż przyszłym gościom Twojego hotelu, że prezentowane przestrzenie, wyglądają dokładnie tak samo poza wirtualnym spacerem. Ułatw im proces decyzyjny uwzględniając niezbędne detale.',
              heading2: 'EVRYPLACE dla Hotelarstwa',
              desc2: 'Spraw, że rezerwacja przebiegnie szybciej i przyjemniej. Pokaż przyszłym gościom Twojego hotelu, że prezentowane przestrzenie, wyglądają dokładnie tak samo poza wirtualnym spacerem. Ułatw im proces decyzyjny uwzględniając niezbędne detale.',
              heading3: 'EVRYPLACE dla Hotelarstwa',
              desc3: 'Spraw, że rezerwacja przebiegnie szybciej i przyjemniej. Pokaż przyszłym gościom Twojego hotelu, że prezentowane przestrzenie, wyglądają dokładnie tak samo poza wirtualnym spacerem. Ułatw im proces decyzyjny uwzględniając niezbędne detale.',
            },
            info: 'Find out more about the pricing plans for Hotels and Tourism',
            exampleHeading: 'Przykładowa prezentacja',
            exampleTitle: 'Title 01',
            exampleDescription: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate',
          },
          realEstate: {
            heading: 'Wykorzystaj najlepsze narzędzie marketingowe<br>dla branży nieruchomości',
            subheading: 'Zdobądź klientów i wzmocnij swoją pozycję, oszczędzając przy tym czas i pieniądze.',
            whyUse: 'Dlaczego EVRYPLACE?',
            whyUseSubhead1: 'Dotrzesz do nowych odbiorców',
            whyUseDesc1: 'Zdobądź tylko najbardziej zainteresowanych klientów. Oszczędź czas podczas wstępnego prezentowania nieruchomości. Pozwól klientowi samodzielnie obejrzeć mieszkanie, którego prezentacja jest zawsze dostępna i gotowa do oglądania.',
            whyUseSubhead2: 'Zaprezentujesz swoje oferty w nowy, lepszy sposób',
            whyUseDesc2: 'Spraw, że Twoje oferty ożyją na nowo. Uczyń z doświadczania w 360° swoje kluczowe narzędzie marketingowe. Usprawnij kontakt z odbiorcami i zdobądź zaufanie klientów.',
            whyUseSubhead3: 'Sprzedasz szybciej',
            whyUseDesc3: 'Daj swoim odbiorcom możliwość na szybki i bezpośredni kontakt z Twoim działem sprzedaży. Użyj funkcji co-viewingu i zamień spotkania na zdalne prezentowanie nieruchomości za pomocą jednego kliknięcia.',
            forWhoHeading1: 'EVRYPLACE dla Agencji Nieruchomości',
            forWhoHeading2: 'EVRYPLACE dla Deweloperów Mieszkaniowych',
            forWhoHeading3: 'EVRYPLACE dla Nieruchomości Komercyjnych',
            forWhoBox1heading1: 'Prosto i intuicyjnie',
            forWhoBox1description1: 'Generuj automatyczny pokaz slajdów ze zdjęć 360° lub przesyłaj plany pięter i mapuj połączenia między pomieszczeniami tak, aby stworzyć poczucie prawdziwego przebywania w prezentowanej przestrzeni.',
            forWhoBox1heading2: 'Odtwórz spacer w 360°',
            forWhoBox1description2: 'Po przygotowaniu wycieczki w 360°, możesz naśladować oprowadzanie po przestrzeni, oraz przejścia przez różne obszary nieruchomości. Pozwól swoim klientom ponownie przebyć trasę, tak jakby uczestniczyli w prezentacji wnętrza osobiście.',
            forWhoBox1heading3: 'Zaprojektowane z myślą o wielu kanałach sprzedaży',
            forWhoBox1description3: 'Umieść swoją wycieczkę na stronie internetowej oraz innych witrynach sprzedażowych. Możesz użyć także funkcji osadzania, aby utworzyć niebrandowane wersje swoich wycieczek, którą możesz w szybki sposób dodać do swoich aukcji MLS.',
            forWhoBox2heading1: 'Zacznij sprzedawać wcześniej',
            forWhoBox2description1: 'Użyj wysokiej jakości wizualizacji 360°, aby zaprezentować swoją nieruchomość kupującym przed rozpoczęciem budowy. Zaproś klientów do swoich biur sprzedaży na wycieczkę VR lub dodaj prezentacje 360° do swoich kampanii sprzedaży online.',
            forWhoBox2heading2: 'Zbuduj swój brand',
            forWhoBox2description2: 'Zdobądź przewagę technologiczną nad konkurentami dzięki wycieczkom w VR. Pomóż kupującym połączyć ich wrażenia wizualne z Twoją marką - dostosuj branding wycieczki do Twojego wizerunku lub marki inwestycyjnej.',
            forWhoBox2heading3: 'Uruchom zdalne wycieczki po nieruchomościach',
            forWhoBox2description3: 'Pozwól klientom zarezerwować prezentację nieruchomości 360° / VR na żywo. Nawiguj klientów po trasie i rozglądaj się po prezentowanym wnętrzu - wszyscy uczestnicy będą mogli się nawzajem słyszeć. Pokaż cały obiekt bez opuszczania Twojego biura.',
            forWhoBox3heading1: 'Zaproś branding do swojej prezentacji',
            forWhoBox3description1: 'Przedstaw swoim klientom, w jaki sposób przestrzeń komercyjna może dla nich działać. Szybkie zmiany brandingu pozwolą zwiększyć atrakcyjność Twojej oferty w oczach potencjalnych klientów.',
            forWhoBox3heading2: 'Pokaż różnorodność dopasowania',
            forWhoBox3description2: 'Daj swoim klientom możliwość podglądu przestrzeni w różnych wariantach wyposażenia. Zredukuj ich niepewność, pozwalając im wybrać opcję, którą najbardziej lubią.',
            forWhoBox3heading3: 'Informacje intuicyjne bardziej niż papierowe foldery ofertowe',
            forWhoBox3description3: 'Korzystaj z prezentacji 360°, aby pokazać zarówno funkcjonalność, jak i atmosferę przestrzeni biurowej. Zaproś swoich klientów na wycieczkę VR, aby pomóc im wizualizować to co wcześniej było jedynie warstwą informacyjną. Podkreślaj i przedstawiaj najbardziej funkcyjne obszary.',
            cta1Info: 'Dowiedź się więcej o\xa0produkcie\xa0i\xa0pakiecie \n dla Agentów Nieruchomości',
            cta1Button: 'Zarejestruj się i sprawdź!',
            cta2Info: 'Dowiedz się jak SKANSKA używa EVRYPLACE  \n dla swoich inwestycji mieszkaniowych',
            cta2Button: 'Dołącz już teraz',
            cta3Info: 'Potrzebujesz indywidualnych rozwiązań \n dla swojego przedsięwzięcia?',
            cta3Button: 'Dołącz już teraz',
            useCase: 'Use case',
            useCaseHeader1: 'More Intuitive Technical information',
            useCaseDescription1: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magnion ullamcorper suscipit lobortis nisl ut aliquip ex ea lor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis ',
            useCaseHeader2: 'More Intuitive Technical information',
            useCaseDescription2: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magnion ullamcorper suscipit lobortis nisl ut aliquip ex ea lor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis ',
            useCaseHeader3: 'More Intuitive Technical information',
            useCaseDescription3: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magnion ullamcorper suscipit lobortis nisl ut aliquip ex ea lor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis ',
            tryItHeading: 'Przekonany? Wypróbuj już teraz!',
            investmentHeading1: 'Inwestycja',
            investmentDesc1: '<li>Zaprezentuj pełny przegląd inwestycji za\xa0pomocą wizualizacji\xa0i\xa0fotografii 360°</li><li>Oprowadzaj zdalnie po\xa0odległych lokacjach</li><li>Dodawaj warstwę informacyjną aby\xa0zawrzeć w\xa0prezentacji wszystkie szczegóły na\xa0temat inwestycji.</li>',
            investmentHeading2: 'Wstępna budowa',
            investmentDesc2: '<li>Zredukuj liczbę iteracji\xa0i\xa0uzyskaj lepszą informację zwrotną na\xa0temat projektów dzięki konferencjom 360° prowadzonym na\xa0żywo</li><li>Używaj adnotacji na\xa0żywo\xa0i\xa0komentarzy,\xa0aby wyróżnić obszary wymagające poprawek</li><li>Używaj renderingów 360°, aby lepiej zwizualizować funkcję projektowanej nieruchomości</li>',
            investmentHeading3: 'Budowa',
            investmentDesc3: '<li>Wyeliminuj luki w\xa0komunikacji,\xa0dzieląc\xa0się dokumentacją 360° z\xa0wieloma osobami w\xa0Twoim zespole</li><li>Dodaj powiązane pliki - archiwalne zdjęcia 2D czy plany architektoniczne, aby zachować wszystko w jednym miejscu</li><li>Wykonuj szybko\xa0i\xa0bezpieczne prezentacje BHP,\xa0bez konieczności przebywania bezpośrednio\xa0w\xa0miejscu budowy</li>',
            investmentHeading4: 'Sprzedaż i marketing',
            investmentDesc4: '<li>Twórz markowe aplikacje VR\xa0/\xa0360°. Dodaj 360° do\xa0istniejących kanałów marketingowych,\xa0aby zwiększyć atrakcyjność\xa0i\xa0zaangażowanie</li><li>Synchronizuj gogle VR z dużymi ekranami i ściągnij całą uwagę na swoją ofertę podczas targów</li><li>Korzystaj ze zdalnego viewingu 360°,\xa0aby pokazywać nieruchomości online i\xa0zapraszaj zdecydowanych kupujących do\xa0Twojego salonu sprzedaży</li>',
            investmentHeading5: 'Odbiór',
            investmentDesc5: '<li>Użyj fotografii 360°,\xa0aby skompletować protokoły dostawy i\xa0odbioru</li>',
            investmentHeading: 'Zaprojektowany tak, by wspierać każdy etap Twojej inwestycji',
            asari: {
              title: 'Korzystaj z Evryplace w ASARI CRM',
              subtitle: 'Wykorzystaj dodatkową integrację, by usprawnić swoją pracę',
              description: 'Evryplace jest w pełni zintegrowany z ASARI CRM, oprogramowaniem dla rynku nieruchomości. Dzięki temu w ASARI w ramach jednego narzędzia możesz zarządzać firmą, nieruchomościami, relacjami z klientami oraz tworzyć prezentacje 360° i realizować wirtualne spotkania w ramach modułu Co-Viewing. To nie jedyne korzyści:',
              listItemHeader1: 'Publikacja na portalach',
              listItemHeader2: 'Eksport na stronę WWW',
              listItemHeader3: 'Proste zarządzanie',
              listItemHeader4: 'Przeglądarka spacerów',
              listItemDesc1: 'Dzięki ASARI CRM stworzone przez Ciebie spacery od razu trafią na wszystkie portale, z którymi współpracujesz.',
              listItemDesc2: 'Wyślij wirtualny spacer na stworzoną w ASARI stronę WWW i zyskaj więcej klientów zainteresowanych Twoją ofertą.',
              listItemDesc3: 'Integracja z ASARI CRM daje szybki dostęp do tworzenia i edycji wirtualnych spacerów w Evryplace z poziomu każdej nieruchomości.',
              listItemDesc4: 'W ASARI CRM zdjęcia, plany, filmy i wirtualne spacery możesz przeglądać z jednego miejsca przy pomocy wbudowanej przeglądarki.',
              linkText: 'Kliknij i dowiedz się więcej!',
            },
            metaDescription: 'Zdobądź tylko najbardziej zainteresowanych klientów. Oszczędź czas podczas wstępnego prezentowania nieruchomości. Pozwól klientowi samodzielnie obejrzeć mieszkanie',
            metaKeywords: 'Sprzedaż nieruchomości',
            metaTitle: 'Sprzedaż nieruchomości - Evryplace',
          },
          trainings: {
            metaTitle: 'Szkolenia',
            heading: 'Szkolenia w wirtualnej rzeczywistości (VR) - Evryplace',
            subheading1: 'Szkolenia Evryplace rozwiną umiejętności pracowników przy użyciu technologii wirtualnej rzeczywistości.',
            subheading2: 'Skontaktuj się z nami i sprawdź, czy technologia VR jest odpowiednia dla szkoleń w Twojej firmie',
            corporateUse: 'Technologia wirtualnej rzeczywistości (VR) w szkoleniach to przyszłość branży',
            corporateUseDesc1: 'Aby umożliwić firmom stworzenie <b>bezpiecznego i angażującego</b> środowiska uczenia się, stworzyliśmy <b>Evryplace</b> - intuicyjne oprogramowanie do wirtualnego szkolenia do użytku korporacyjnego i przemysłowego.',
            corporateUseDesc2: 'Szkolenia stworzone w Evryplace, mogą być <b>dostosowane do potrzeb każdego pracodawcy</b>. Technologia 360° oraz VR doskonale sprawdzają się w przestrzeniach przemysłowych, umożliwiając pracownikom uczyć się znacznie <b>szybciej i wydajniej</b> niż jakikolwiek inny rodzaj szkolenia.',
            corporateUseDesc3: 'Wykorzystaj VR do tworzenia <b>skalowalnych</b>, angażujących szkoleń w Twojej firmie. Szkolenia w VR pozwalają odbiorcy na świetną <b>orientację w prezentowanej</b> w ramach szkolenia <b>przestrzeni</b> - więc tak, program treningowy VR to rozwiązanie dla Twoich rzeczywistych potrzeb.',
            forWhoHeading1: 'Jak stworzyć szkolenie VR?',
            forWhoBox1heading1: 'Stwórz etapy szkolenia z materiałów 360°',
            forWhoBox1heading2: 'Udostępnij kurs pracownikom',
            forWhoBox1description1: 'Zrób zdjęcia 360° lub filmy 360° w swoim miejscu pracy. Następnie prześlij je do kreatora szkoleń online i ułóż je w <b>etapy szkolenia</b> oraz dodaj informacje z zakresu <b>wiedzy i umiejętności</b>, które chcesz przekazać Twoim pracownikom.',
            forWhoBox1description2: 'Opublikuj gotowe szkolenie w <b>publicznej chmurze</b> z ochroną hasłem lub <b>własnym serwerze</b> (możliwość wdrożenia Evryplace on-premise). Udostępniaj je uczestnikom w dowolny sposób: przez gogle <b>VR Oculus Quest 2, HTC Vive</b>, komputer stacjonarny lub dowolne urządzenie mobilne.*',
            forWhoBox1description3: '*Lub dowolny headset VR korzystający z WebXR — Oculus Quest to tylko jeden z wielu przykładów.',
            howMuch: 'Ile kosztuje wirtualna rzeczywistość?',
            howMuchSubhead1: 'Tańsza niż kiedykolwiek',
            howMuchDesc1: 'Symulacja wykonana za pomocą multimediów 360° w Evryplace, takich jak zdjęcia i filmy, <b>kosztuje ułamek sumy szkolenia wykonanego przez zewnętrzną firmę</b>. Dokładny cennik Evryplace znajduje się na <a href="/pricing" style="color: #666666; text-decoration: underline">tej stronie</a>',
            howMuchSubhead2: 'Tylko dwie rzeczy',
            howMuchDesc2: 'Z Evryplace wszystko czego potrzebujesz, aby zacząć, to <b>kamera 360° lub wynajęty fotograf</b>, oraz subskrypcja w <b>Evryplace</b>.',
            pricing: 'Zobacz cennik',
            benefitsHeading: 'Czy warto wykorzystać VR w szkoleniach?',
            benefitsBox3description1: '<b>40%</b> uczących się <b>zauważa poprawę pewności siebie</b> w porównaniu z uczącymi się w tradycyjnej sali, a 35% poprawę w wiedzy zdobytej w VR w stosunku do uczących się e-learningiem.',
            benefitsBox3description2: 'Uczący się w VR <b>ukończyli szkolenie 4 razy szybciej</b> niż szkolenie stacjonarne.',
            benefitsBox3description3: 'Uczący się w VR <b>czuli się 3,75 razy bardziej emocjonalnie związani</b> z treścią niż uczniowie w klasie i 2,3 razy bardziej niż e-uczniowie.',
            benefitsBox3description4: 'Uczący się w VR są <b>4 razy bardziej skoncentrowani</b> podczas szkolenia niż ich współuczący się e-learningiem i 1,5 raza bardziej skoncentrowani niż ich koledzy z tradycyjnej sali szkoleniowej.*',
            benefitsBox3description5: '*Raport PWC - Seeing is believing: How virtual reality and augmented reality will transform business and the economy',
            findOutHeading: 'Dowiedz się, jak szkolenia 360° mogą pomóc Twojej firmie',
            investmentHeading: 'Jakie szkolenia można wykonać w VR?',
            investmentHeading1: 'Szkolenie w miejscu pracy',
            investmentHeading2: 'Wprowadzenie',
            investmentHeading3: 'Obsługa taśmy',
            investmentHeading4: 'Przygotowanie do pracy',
            investmentHeading5: 'Szkolenia BHP',
            investmentHeading6: 'Poradniki',
            investmentDesc1: 'Pokaż swoim pracownikom, jak prawidłowo wykonywać ich zadania i zminimalizuj liczbę błędów poprzez angażującą wirtualną podróż. <b>VR to doskonałe medium do nauczania zarówno twardych, jak i miękkich umiejętności</b>.',
            investmentDesc12: 'Evryplace zapewnia spełnienie wszystkich potrzeb szkoleniowych Twoich pracowników. <b>Kluczem do sukcesu firmy są ludzie</b>, a nauka i rozwój powinny być stałym elementem, aby zapewnić najwyższy poziom usług i profesjonalizm w Twoim zespole.',
            investmentDesc2: 'Wdrażaj nowych pracowników do firmy, jej kultury i przekazuj im <b>narzędzia oraz informacje</b> niezbędne do stania się <b>częścią nowego zespołu</b>.',
            investmentDesc22: 'Szczególnie skuteczne w <b>dużych firmach</b>, gdzie raz stworzone szkolenie VR, można wykorzystać wielokrotnie <b>bez dodatkowych kosztów</b>.',
            investmentDesc3: 'Żywa, szczegółowa ilustracja linii montażowej, <b>eksponująca detale, kluczowe punkty</b> i instruktaż obsługi i z wyraźną narracją przekazaną za pomocą gogli VR.',
            investmentDesc32: 'Wykorzystaj fakt, że wirtualne szkolenie może zarejestrować i ukazywać każdy aspekt i stan linii montażowej bez konieczności zatrzymywania pracy innych. <b>Orientacja przestrzenna</b> w środowisku przemysłowym osiągnięta w VR jest niezwykle <b>szybka, łatwa i wydajna</b> dzięki Evryplace.',
            investmentDesc4: 'Skutecznie naucz swoich pracowników, <b>umiejętności w obszarach, które Cię interesują</b>. W wirtualnym, bezpiecznym dla nich środowisku, zanim zdobędą praktyczne doświadczenie w Twojej firmie.',
            investmentDesc42: 'Wykorzystaj technologię, aby <b>zmniejszyć wydatki na przygotowanie pracowników</b> do pracy w porównaniu z tradycyjnymi metodami szkoleniowymi.',
            investmentDesc5: 'Zapewnij swoim pracownikom szeroki zakres informacji o tym, jak <b>unikać zagrożeń</b>, które mogłyby wpłynąć na ich stan zdrowia i Twoją produkcję. ',
            investmentDesc52: 'Coraz więcej firm korzysta z wirtualnych rozwiązań szkoleniowych, odchodząc od indywidualnego nauczania BHP. <b>Zagrożenia bezpieczeństwa wiążą się z dodatkowymi kosztami</b>, a Evryplace pozwoli Ci ich uniknąć.',
            investmentDesc6: 'Ucz swoich pracowników w interaktywny i przyswajalny dla różnych grup wiekowych sposób, przeprowadzając ich przez cały proces w realistycznym odwzorowaniu ich środowiska pracy uwzględniając czas, którego indywidualnie potrzebują. Doświadczenie VR to niewątpliwie <b>przyszłość wszelkiego rodzaju tutoriali zawodowych</b>.',
            investmentDesc62: 'Tradycyjne metody szkoleniowe stają się przestarzałe, ponieważ nie angażują pracowników na wysokim poziomie i nie przyczyniają się do utożsamiania <b>wizerunku pracodawcy z nowoczesnością</b>.',

          },
          presentation: {
            shareCoviewingNotification: 'Mail z zaproszeniem został wysłany.',
            viewingModal: {
              title: 'Witaj we wspólnej prezentacji 360°',
              titleBrand: 'Witaj w coviewingu ',
              titleBrand2: '',
              hash: 'HASH PREZENTACJI',
              start: 'START',
              link: 'Link prezentacji',
              emailPlaceholder: 'wprowadź email',
              copyLink: 'KOPIUJ LINK',
              sendInvite: 'WYŚLIJ ZAPROSZENIE EMAIL',
              email: 'E-mail',
            },
            connectionStatusModal: {
              audioAviable: 'Połączenie głosowe jest dostępne.',
              audioUnaviable: 'Połączenie głosowe jest niedostępne.',
              audioOff: 'Połączenie głosowe włączone.',
              audioOn: 'Połączenie głosowe włączone.',
              connectAudio: 'Połącz audio',
              connectedAwaiting: 'Połączony. Czekaj na dołączenie klienta.',
              conectedInfo1: 'Klient',
              conectedInfo2: 'połączony.',
              connecting: 'Łączenie...',
              connectingInfo1: 'Jeśli widzisz ten komunikat zbyt długo, sprawdź ustawienia firewall na swoim komputerze. ',
              connectingInfo2: 'Twój firewall musi zezwalać na wychodzące połączenia na porcie 3000.',
              hash: 'HASH PREZENTACJI',
              refresh: 'Odśwież prezentację',
              start: 'Rozpocznij',
              title: 'Informacje o prowadzonej prezentacji',
              startVRMode: 'Rozpocznij w trybie VR',
              stayInBrowser: 'Pozostań w trybie przeglądarkowym',
              skip: 'Pomiń',
              welcome: 'Witaj w EVRYPLACE VR',
            },
          },
          privacy: {
            title: "Polityka prywatności",
            block1: "FREAM S.A. jest Administratorem danych osobowych (Administrator) gromadzonych i\n" +
            "przetworzonych w ramach korzystania z usług Serwisu Everyplace.com Administrator\n" +
            "przetwarza gromadzone dane osobowe z zachowaniem zasad bezpieczeństwa wynikających z\n" +
            "przepisów ustawy z dnia 29 sierpnia 1997 r. o ochronie danych osobowych (tekst jednolity:\n" +
            "Dz. U. 2002 r. Nr 101 poz. 926, z późn. zm; dalej u.ś.u.d.e.). Administrator jest świadom\n" +
            "zagrożeń wynikających z przetwarzania danych osobowych w sieci Internet, dlatego też\n" +
            "oświadcza, iż dokłada starań, aby zapewnić odpowiedni poziom ochrony prywatności i\n" +
            "bezpieczeństwa Użytkowników. Stosowane narzędzia zostały dobrane tak, by zapewnić\n" +
            "należytą ochronę przetwarzania danych osobowych zgodnie z wymogami prawa. Zbiór\n" +
            "danych osobowych Użytkowników zgłoszono do rejestracji Generalnemu Inspektorowi\n" +
            "Ochrony Danych Osobowych i wpisano do ogólnokrajowego rejestru zbiorów danych osobowych.",
            block2: {
              header: "Zakres i zastosowanie",
              li1: "Ta Polityka Prywatności („Polityka Prywatności”) ma zastosowanie do wszystkich\n" +
              "podmiotów które korzystają z wszelkich produktów, oprogramowania, wgranych\n" +
              "danych i usług, w tym wbudowanego odtwarzacza prezentacji\n" +
              "eVRyPlace(„Odtwarzacz eVRyPlace”) dostarczonych użytkownikowi przez\n" +
              "eVRyPlace na Witrynie Internetowej lub z niej pochodzących lub za jej\n" +
              "pośrednictwem (w niniejszym dokumencie zwanych łącznie „Usługami”)."
            },
            block3: {
              header: "Zbieranie informacji",
              li1: "Informacje dostarczone przez użytkowników:",
              li2: "Everyplace zbiera informacje które są dostarczone bezpośrednio przez\n" +
              "użytkowników w trakcie między innymi zakładania konta, czy wprowadzania w\n" +
              "nim zmian, kontaktów z obsługą klienta lub w przypadku innych form\n" +
              "komunikacji z Everyplace. Informacje te mogą objąć imię i nazwisko, adres e-\n" +
              "mail, numer telefonu, wiek, adres zamieszkania/miejsa pracy/miejsca\n" +
              "prowadzanie działalności gospodarczej, zdjęcia przesłane do serwisu,\n" +
              "specyfikacja obiektów udostępnionych w serwisie czy sposób płatności.",
              li3: "Evryplace zbiera informacje o transakcjach związanych z korzystaniem z jej\n" +
              "Usług, w tym między innymi: rodzaj zleconej usługi, data oraz godzina realizacji\n" +
              "usługi, data oraz godzina zlecenia usługi, wysokość pobranej opłaty.",
              li4: "Gdy korzystasz z Usługi, Everyplace pobiera informacje z logów serwera,\n" +
              "które mogą zawierać adres IP urządzenia, datę i godzinę wejścia na witrynę,\n" +
              "wykorzystywane funkcje aplikacji lub przeglądane strony, awarie aplikacji i inne\n" +
              "aktywności systemu, typ przeglądarki oraz informacje o używanych przez Ciebie\n" +
              "witrynach lub usługach osób trzecich przed korzystaniem z naszej Usługi.",
              li5: "Informacje uzyskane z innych źródeł",
              li6: "Everyplace może uzyskać informacje od pracodawcy korzystającego z usług na\n" +
              "temat pracowników oddelegowanych do współpracy z Everyplace",
            },
            block4: {
              header: "Everyplace wykorzystuje informacje:",
              li1: "W celu świadczenia, utrzymywania oraz ulepszania Usług, w tym na przykład w celu\n" +
              "ułatwiania dokonywania płatności, przekazywania rachunków, dostarczania\n" +
              "produktów oraz usług. opracowania nowych funkcji, świadczenia usług obsługi\n" +
              "klienta dla Użytkowników, opracowania zabezpieczeń, poświadczania tożsamości\n" +
              "użytkowników a także wysyłania aktualizacji produktów i wiadomości\n" +
              "administracyjnych.",
              li2: "W celu przeprowadzania wewnętrznych działań, w tym na przykład mających na celu\n" +
              "przeciwdziałanie oszustwom i nadużyciom Usługi; wykrywania i usuwania błędów w\n" +
              "oprogramowaniu i rozwiązywania problemów operacyjnych; przeprowadzania\n" +
              "analizy danych, testowania, badania; a także do celów monitorowania i analizowania\n" +
              "tendencji w zakresie wykorzystania Internetu i ruchu na witrynach;",
              li3: "W celu przekazywania informacji, które według Evryplace mogą użytkownika\n" +
              "zainteresować, w tym informacji o produktach, usługach, promocjach,\n" +
              "wiadomościach i wydarzeniach firmy Everyplace oraz innych spółek tam, gdzie jest\n" +
              "to dopuszczalne i zgodne z obowiązującymi przepisami prawa; przetwarzania\n" +
              "zgłoszeń do konkursów, zakładów pieniężnych lub innych promocji oraz\n" +
              "przekazywania związanych z nimi nagród;",
              li4: "W celu personalizacji i ulepszania Usługi, w tym dostarczania lub rekomendowania\n" +
              "funkcji, treści, kontaktów społecznościowych, poleceń i reklam.",
            },
            block5: {
              header: "Wykorzystanie plików Cookies",
              li1: "Everyplace może wykorzystywać pliki cookies (tzw. ciasteczka) - niewielkie pliki z\n" +
              "informacją zapisywane przez serwer Operatora na komputerze Użytkownika.\n" +
              "Stosowanie plików cookie umożliwia podnoszenie jakości naszych usług dzięki\n" +
              "przechowywaniu preferencji Użytkowników. Służy również do opracowywania\n" +
              "ogólnych statystyk dotyczących korzystania przez Użytkowników z naszego serwisu. " +
              "Korzystanie z technik „cookies” nie pozwala na pobieranie jakichkolwiek danych\n" +
              "osobowych i adresowych Użytkownika ani żadnych informacji poufnych z jego\n" +
              "komputera. Stosowanie „ciasteczek” nie jest możliwe w sytuacji, gdy w przeglądarce\n" +
              "internetowej wyłączono opcję pozwalającą na zapisywanie plików „cookie”.\n" +
              "Zasadniczo nie uniemożliwia to korzystania z serwisu, może jednak spowodować\n" +
              "pewne utrudnienia.",
            },
            block6: {
              header: "Cele i sposoby przetwarzania danych osobowych",
              li1: "Wykonania zobowiązań wynikających z zawartej umowy o świadczenie usług drogą\n" +
              "elektroniczną w tym celów księgowych,",
              li2: "Wysyłania biuletynu informacyjnego,",
              li3: "Umożliwienia udostępniania prezentacji wobec których użytkownik wyraził zgodę na\n" +
              "publikację.",
              li4: "W odpowiedzi na żądanie właściwych władz o przekazanie informacji, jeśli\n" +
              "Evryplace zasadnie uzna, że ich ujawnienie jest zgodne z obowiązującymi\n" +
              "przepisami, regulacjami czy procedurą prawną lub przez nie wymagane;",
              li5: "Udostępnianie funkcjonariuszom organów ścigania, władz państwowych lub innym\n" +
              "osobom trzecim, jeśli Evryplace uzna, że działania użytkowników są niezgodne z\n" +
              "postanowieniami naszej umowy z Użytkownikiem, Warunkami Świadczenia Usług,\n" +
              "naszą polityką lub w celu ochrony praw, własności lub bezpieczeństwa firmy Fream i\n" +
              "innych osób;",
              li6: "w związku z lub podczas negocjacji dotyczących fuzji, sprzedaży aktywów spółki,\n" +
              "konsolidacji lub restrukturyzacji, finansowania albo przejęcia lub włączenia części\n" +
              "albo całości Evryplace przez inną spółkę;",
              li7: "gdy w innych przypadkach Evryplace powiadomi użytkownika i użytkownik wyrazi\n" +
              "zgodę na dzielenie się tymi informacjami oraz",
              li8: "w postaci łącznych lub anonimowych danych, których nie można w sposób\n" +
              "racjonalny wykorzystać do zidentyfikowania użytkownika.",
            },
            block7: {
              header: "Funkcje dzielenia się informacjami w sieciach społecznościowych",
              li1: "Usługa może współpracować z funkcją dzielenia się informacjami w sieciach\n" +
              "społecznościowych i innymi powiązanymi narzędziami, które pozwolą\n" +
              "użytkownikowi dzielić się informacjami o działaniach podejmowanych w Usłudze\n" +
              "Evryplace z innymi aplikacjami, witrynami lub mediami i odwrotnie.\n" +
              "Wykorzystywanie przez użytkownika tych funkcji umożliwia dzielenie się\n" +
              "informacjami z przyjaciółmi lub ogółem społeczeństwa, w zależności od " +
              "zastosowanych przez użytkownika ustawień w portalu społecznościowym. W celu\n" +
              "uzyskania informacji na temat wykorzystywania danych, które przekazujesz lub\n" +
              "którymi się dzielisz za ich pośrednictwem zapoznaj się z polityką prywatności tych\n" +
              "portali społecznościowych.",
              li2: "Podając dane do rejestracji i utworzenia Profilu Użytkownik wyraża zgodę na\n" +
              "tworzenie na ich podstawie wyniku wyszukiwania o określonych parametrach. Są one\n" +
              "wykorzystywane do analizy zachowań Użytkowników, zbierania danych o\n" +
              "Użytkownikach celem personalizacji zawartości strony internetowej. W tym zakresie\n" +
              "są one wykorzystywane także do personalizacji reklam i pokrewnych linków, treści\n" +
              "wiadomości i innych informacji związanych z korzystaniem z usług Evryplace. Dane\n" +
              "te zbierane są automatycznie. Stanowią one podstawę do badania i analiz mających\n" +
              "na celu zachowanie i podniesienie poziomu ochrony, udoskonalanie usług\n" +
              "świadczonych dotychczas oraz opracowywanie nowych usług Evryplace.",
              li3: "Podane dane kontaktowe wykorzystujemy do wysyłania Użytkownikom\n" +
              "niezapowiedzianych informacji. Informacje mogą także przybrać formę wiadomości\n" +
              "wysyłanych wewnętrzną pocztą Serwisu. Przez pojęcie informacje należy rozumieć\n" +
              "treści o charakterze zarówno komercyjnym (np. newsletter, itp.) jak i\n" +
              "niekomercyjnym (np. zmiany w funkcjonowaniu, o usługach, komunikaty systemu,\n" +
              "itp.). Wszelkie treści reklamowe związane z działalnością komercyjną Operatora oraz\n" +
              "kontrahentów mogą być przesyłane Użytkownikom wyłącznie za ich zgodą, zgodnie\n" +
              "z treścią art. 10 ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą\n" +
              "elektroniczną (Dz. U. 2002, Nr 144, poz. 1204, z późn. zm.).",
              li4: "Użytkownik może w każdej chwili zrezygnować z otrzymywania informacji o\n" +
              "charakterze komercyjnym, wysyłając wiadomość na adres email: optout@evryplace.com.\n" +
              "Żądanie wyłączenia przetwarzania danych osobowych zostanie niezwłocznie\n" +
              "uwzględnione.",
            },
            block8: {
              header: "Zmiana danych osobowych",
              li1: "Użytkownik ma prawo dostępu do swoich danych osobowych, ich aktualizowania,\n" +
              "poprawiania błędnych informacji oraz usuwania na żądanie, z wyjątkiem sytuacji,\n" +
              "gdy ich zachowanie wymagane jest w związku z wymogami prawa lub gdy chodzi o\n" +
              "dane zbierane automatycznie – w takich przypadkach danych nie da się zmienić lub\n" +
              "usunąć.",
              li2: "Zmiana lub usunięcie danych możliwe są po zalogowaniu się do Serwisu lub na\n" +
              "piśmie.",
              li3: "W przypadku niedokonania aktualizacji danych osobowych w sytuacji, gdy uległy\n" +
              "one zmianie, Operator może wstrzymać świadczenie usług/zawiesić Konto\n" +
              "Użytkownika/ zablokować Konto Użytkownika do czasu aktualizacji danych.",
              li4: "Operator może odmówić usunięcia danych Użytkownika, gdy ten nie uregulował\n" +
              "swoich należności wobec Operatora, a także w sytuacji, gdy istnieje podejrzenie, że\n" +
              "swoim zachowaniem naruszył Regulamin lub przepisy obowiązującego prawa, a\n" +
              "posiadane informacje mogą przyczynić się do ustalenia odpowiedzialności\n" +
              "Użytkownika.",
            },
            block9: {
              header: "Wyłączenie odpowiedzialności",
              li1: "Polityka prywatności nie ma zastosowania do przedsiębiorców oraz osób, których\n" +
              "dane są ogólnie dostępne na stronach serwisu.",
              li2: "Operator nie ponosi również odpowiedzialności za dane udostępnione przez\n" +
              "Użytkowników.",
            },
          },
          server: {
            'ACCOUNT_NOT_ACTIVE': 'Konto czeka na aktywację. Mail aktywacyjny został wysłany na twój adres email. Proszę sprawdź skrzynkę email i aktywuj konto za pomocą adresu url.',
            'RESET_PASSWORD_ERROR': 'Link aktywacyjny wygasł lub został już użyty.',
            'DEFAULT': 'Error. Please try again.',
            'RESET_PASSWORD_EXPIRED': 'Upłynął czas. Linki są ważne przez 24h.',
            'WRONG_EMAIL_OR_PASSWORD': 'Błędny login lub hasło',
            'SHOPPRESENTATION_ALREADY_EXISTS': 'Prezentacja o podanym PSD i dacie już istnieje'
          },
          supportModal: {
            title: 'Jak możemy Ci pomóc?'
          },
          presentationOverlay: {
            tooltip: 'Tryb VR dostępny po opublikowniu prezentacji.'
          },
          madeByMe: {
            page: {
              categories: 'Kategorie',
              categoriesUngrouped: 'Kategorie bez grupy',
              title: 'Moje wirtualne spacery',
              search: 'Szukaj...',
              sortBy: 'Sortuj po',
              noSearchResults: 'Nie znaleźliśmy spacerów pasujących do tego wyszukiwania',
            },
            emailModal: {
              messageSend: 'Wiadomość wysłano',
              messageError: 'Wystąpił błąd',
              sendPresentation: 'Wyślij prezentację',
              shareOnFacebook: 'Udostępnij na facebooku',
              addressEmail: 'Adres email',
              content: 'Treść',
              send: 'Wyślij',
              invite: 'Zaproszenie do obejrzenia prezentacji',
              mail1: 'z firmy',
              mail2: 'udostępnił Ci prezentację: "',
              mail3: '" w Evryplace.',
            },
            embedModal: {
              sharePresentation: 'Udostępnij prezentację',
              text1: 'Współczesne portale nieruchomości pozwalają na dodanie prezentacji 360° w formie linku. Skopiuj powyższy link i umieść go w polu oferty, poświęconym prezentacjom 360°.',
              text2: 'Jeśli posiadasz dostęp do kodu strony, skopiuj ten fragment i wklej w kodzie HTML w odpowiednim miejscu.',
              copyLink: 'Skopiuj link',
              width: 'Szerokość',
              height: 'Wysokość',
              noStartScreen: 'Uruchom po załadowaniu strony',
            },
            filter: {
              publicationDateAsc: 'Najstarsze',
              publicationDateDesc: 'Najnowsze',
              nameAsc: 'Nazwa rosnąco',
              nameDesc: 'Nazwa malejąco',
              viewCountDesc: 'Liczba wyświetleń malejąco',
              viewCountAsc: 'Liczba wyświetleń rosnąco',
            },
            presentation: {
              edit: 'Edytuj prezentację',
              protected: 'Link',
              password: 'Hasło',
              public: 'Publiczna',
              sendEmail: 'Wyślij email',
              share: 'Udostępnij',
              coviewing: 'Coviewing',
            },
            emptyState: {
              empty: 'Brak spacerów na koncie',
              create: 'Utwórz pierwszy spacer'
            }
          }
        },
      },
    },
    fallbackLng: 'en',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: '.', // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },

    react: {
      wait: true
    }
  });

export default i18n;
