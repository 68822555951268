import Config from 'config';

const cdn = (Config.build && Config.build.cdn) || '';

export default {
  // returns path cdn + /api/v1/files/.. + hash
  imagePath: (hash, variant = 'TILE') => {
    return cdn + `/api/v1/files/stream/${variant}/${hash}`;
  },
  staticPath: (path) => {
    return cdn + path;
  },
};

export const ImageVariants = {
  TILE: 'TILE',
  THUMBNAIL: 'THUMBNAIL',
  STANDARD: 'STANDARD',
  NORMALIZED: 'NORMALIZED',
  LOGOTYPE: 'LOGOTYPE',
  WIDE: 'WIDE',
};
