function fillString(str, details) {
  const replaceRegExp = /(\${([^}]*)})/i;
  let match;
  if(details){
    while(!!(match = replaceRegExp.exec(str))){
      var desc = match[2].split('.'),
        val = details;
      while(desc.length){
        val = val[desc.shift()];
      }
      str = str.replace(match[1], val);
    }
  }
  return str;
}

export default fillString;
