import React, { Component } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import classNames from 'classnames';

export default class FreamScroll extends Component {
  static posTop() {
    if (typeof window.pageYOffset !== 'undefined') {
      return window.pageYOffset;
    } else if (document.documentElement.scrollTop) {
      return document.documentElement.scrollTop;
    } else if (document.body.scrollTop) {
      return document.body.scrollTop;
    }
    return 0;
  }

  constructor() {
    super();
    this.state = {
      animated: false
    };
    this.handleScrollThrottle = throttle(this.handleScroll.bind(this), 200);

    if (window && window.addEventListener) {
      window.addEventListener('scroll', this.handleScrollThrottle);
    }
  }

  componentDidMount() {
    this.handleScroll();
  }

  componentWillUnmount() {
    if (window && window.addEventListener) {
      window.removeEventListener('scroll', this.handleScrollThrottle);
    }
  }

  // This is for the callback function to work.
  singleAnimate() {
    this.setState({
      animated: true
    });
    /* callback */
    setTimeout(() => {
      this.props.callback();
    }, this.props.duration * 1000);
  }

  // This is for the queueClass to work.
  queueAnimate() {
    const element = this.node;
    if (element) {
      const checkClass = el => {

        return (" " + el.className + " " ).indexOf( " "+this.props.queueClass+" " ) > -1;
      };
      let number = 0;
      const setClass = (el) => {
        const element1 = el;
        element1.style.visibility = 'hidden';
        setTimeout(() => {
          element1.style.visibility = 'visible';
          element1.className = `${element1.className} animated ${this.props.animate}`;
        }, number * (this.props.queueDuration * 1000));
        number += 1;
      };
      const findClass = (element2) => {
        Array.prototype.forEach.call(element2.childNodes, (child) => {

          findClass(child);
          if (checkClass(child)) {
            setClass(child);
          }
        });
      };

      // Find queue classes
      findClass(element);

      // Callback
      setTimeout(() => {
        this.props.callback();
      }, this.props.duration * 1000 * number);
    }
  }

  handleScroll() {
    if (!this.state.animated) {
      const element = this.node;
      const top = FreamScroll.posTop();
      const elementPositionY = element.getBoundingClientRect().top;
      const scrollPositionY = window.scrollY ? window.scrollY : window.pageYOffset;
      const windowHeight = window.innerHeight;
      if ((scrollPositionY >= elementPositionY + (this.props.offset * 1)) || windowHeight/2 > elementPositionY) {
        this.setState({
          animated: true
        });
        if (this.props.queueClass) {
          this.queueAnimate();
        }
      }
    }
  }
  makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }
  render() {
    const { props, state } = this;
    const {
      animate,
      queueClass,
      className,
      component: Komponent,
      duration,
      delay,
      id
    } = this.props;




    let classes = classNames({
      animated: true,
      [animate]: state.animated && queueClass === ""
    });
    classes += ` ${className}`;

    const style = state.animated ? {} : { visibility: "hidden" };

    if (duration !== "") {
      style.WebkitAnimationDuration = `${duration}s`;
      style.animationDuration = `${duration}s`;
    }
    if (delay !== "") {
      style.WebKitAnimationDelay = `${delay}s`;
      style.animationDelay = `${delay}s`;
    }

    return (
        <Komponent
            className={classes}
            style={{ style, ...style }}
            ref={node => {
              this.node = node;
            }}
            id={id ? id : `animated-${this.makeid()}`}
          >
          {props.children}
        </Komponent>
    );
  }
}

FreamScroll.defaultProps = {
  animate: "fadeInUp",
  offset: 100,
  className: "",
  duration: 1,
  queueDuration: 1,
  queueClass: "",
  component: 'div',
  delay: 1,
  callback: () => {
  }
};

FreamScroll.propTypes = {
  animate: PropTypes.string,
  callback: PropTypes.func,
  duration: PropTypes.number,
  offset: PropTypes.number,
  queueClass: PropTypes.string,
  component: PropTypes.string,
  delay: PropTypes.number,
  queueDuration: PropTypes.number
};
