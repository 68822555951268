function closeJivoChat() {
  var intervalId = null;
  var closeJivoIfLoaded = function(){
    const jivo = document.getElementsByTagName('jdiv');
    if (jivo.length) {
      window.jivo_destroy();
      clearInterval(intervalId);
    }
  };
  intervalId = setInterval(closeJivoIfLoaded, 10);
}

export default closeJivoChat;
