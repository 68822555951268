import i18n from './i18n';

export const Validation = {
  email: value => {
    let matchResult = value && value.match(/^([\s\w.%+-]+)@([\w-]+\.)+([\s\w]{2,})$/i),
      error = null,
      emailValid = matchResult && matchResult.length > 0;

    if (!emailValid) {
      error = i18n.t('validation.emailNotValid');
    } else if ( value.length > 128) {
      error = i18n.t('validation.emailTooLong');
    }

    return { error };
  },
  password: value => {
    let error = (!value || value.length < 8) ? i18n.t('validation.passwordTooShort') : null;

    return { error };
  },
  passwordsEqual: (password1, password2) => {
    let passwordsEqual = password1 && password1 === password2,
      error = !passwordsEqual ? i18n.t('validation.passwordsNotEqual') : Validation.password(password2).error;

    return { error };
  },
  displayName: value => {
    let error = (!value || value.length < 3) ? i18n.t('validation.displayNameTooShort') : null;

    return { error };
  },
  message: value => {
    let error = (!value || value.length <= 20) ? i18n.t('validation.messageTooShort') : null;

    return { error };
  },
  contactModalMessage: value => {
    let error = (!value || value.length < 20) ? i18n.t('validation.contactModalMessage') : null;

    return { error };
  }
};
