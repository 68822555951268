import React from "react";

const DependencyContext = React.createContext({
  navBar: null,
  player: null,
  notifications: null,
});

const WithDependency = (Component) => {
  let firstLoad = true;
  
  return (props) => {
    return <DependencyContext.Consumer>
      {({navBar, player, notifications}) => {
        if (!firstLoad && (!player || !navBar || !notifications)) {
          console.log('player, navBar or notifications undefined');
        }
        
        firstLoad = false;
        return (navBar && player && notifications) ? <Component
          {...props}
          player={player}
          navBar={navBar}
          notifications={notifications}
        /> : <div></div>
      }}
    </DependencyContext.Consumer>;
  };
};

export { DependencyContext, WithDependency };
