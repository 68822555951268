export const Api = {
  auth: {
    currentUser: '/api/v1/users/current',
    register: '/api/v1/users/register',
    login: '/api/v1/login',
    google: '/api/v1/login/google',
    facebook: '/api/v1/login/facebook',
    microsoft: '/api/v1/login/entraid',
    logout: '/api/v1/logout',
    resetPassword: '/api/v1/users/resetPassword',
    verifyResetPassword: '/api/v1/users/verifyResetPassword',
    acceptRodo: '/api/v1/users/agreements',
  },
  presentations: {
    categories: '/api/v1/categories',
    getEdited: '/api/v1/users/getEditedPresentations',
    getShop: '/api/v1/shops?hash=',
    get: '/api/v1/presentations/',
    increaseViewCount: '/api/v1/presentations/:hash/increaseViewCount',
    search: '/api/v1/presentations/search',
    shareByEmail: '/api/v1/presentations/:hash/shareByEmail',
    profile: '/api/v1/presentations/profile?ommitAccess=PRIVATE',
  },
  viewings: {
    get: '/api/v1/viewings/',
    getViewingHost: '/api/v1/viewings/:hash/host',
    create: '/api/v1/viewings',
    invite: '/api/v1/viewings/:hash/invite',
    update: '/api/v1/viewings/:id',
    changePresentation: '/api/v1/viewings/:hash/presentation',
  },
  mails: {
    contact: '/api/v1/mails/contact',
  },
  cms: 'https://evryplacecom.cdn.prismic.io/api/v2',
  offers: {
    get: '/api/v1/offers',
  }
};
